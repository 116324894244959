<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">copy_all</mat-icon>
  <span>Dupliquer les jours pour une autre année</span>
</h1>
<div mat-dialog-content>
  <ng-container>
    <mat-form-field>
      <mat-label>Choisissez une année</mat-label>
      <mat-select
        (selectionChange)="handleYearChange($event)"
        [(value)]="yearSelected"
      >
        <mat-option *ngFor="let year of yearsAvailables" [value]="year">{{
          year
        }}</mat-option>
      </mat-select>
    </mat-form-field>
    <form
      fxLayout="column"
      fxLayoutAlign="center"
      *ngIf="!!yearSelected && yearSelected != dialogData.year"
    >
      <div
        *ngFor="let line of lines; let i = index"
        fxLayout="row"
        fxLayoutAlign="space-around"
      >
        <app-custom-select
          [initialValue]="line.id"
          [label]="'Jour'"
          [required]="true"
          [fetching]="fetching"
          [items]="dialogData.holidays"
          (value$)="handleIdChange(i, $event)"
        >
        </app-custom-select>
        <app-custom-input
          [initialValue]="line.date"
          [label]="'Date'"
          [type]="'date'"
          [required]="true"
          [fetching]="fetching"
          [error]="errors.get(i) ?? ''"
          (value$)="handleDateChange(i, $event)"
        ></app-custom-input>
      </div>
    </form>
  </ng-container>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar mode="indeterminate" *ngIf="fetching"></mat-progress-bar>
  <button *ngIf="!fetching" mat-raised-button color="warn" (click)="onClose()">
    Annuler
  </button>
  <button *ngIf="!fetching" mat-raised-button color="accent" (click)="onSave()">
    Créer
  </button>
</div>
