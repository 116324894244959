import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IdentifiedModel, NamedModel} from 'src/app/model/base-model.model';

export interface ItemsDragged<T> {
  available: T[];
  assigned: T[];
}

@Component({
  selector: 'app-drag-drop-two-tables',
  templateUrl: './drag-drop-two-tables.component.html',
  styleUrls: ['./drag-drop-two-tables.component.scss']
})
export class DragDropTwoTablesComponent<T extends IdentifiedModel & NamedModel> implements OnInit {

  @Input()
  availableItems: T[] = [];

  @Input()
  assignedItems: T[] = [];

  @Input()
  disabledDeassigned: boolean = false;

  @Output()
  itemDragged$: EventEmitter<ItemsDragged<T>> = new EventEmitter<ItemsDragged<T>>();

  constructor() { }

  ngOnInit(): void {
  }

  drop(event: CdkDragDrop<T[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
    this.itemDragged$.next({
      assigned: this.assignedItems, available: this.availableItems
    });
  }

}
