import {Component, EventEmitter, HostBinding, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import * as moment from "moment";
import {Moment} from "moment";

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY',
  },
  display: {
    dateInput: 'YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-year-datepicker',
  templateUrl: './year-datepicker.component.html',
  styleUrls: ['./year-datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class YearDatepickerComponent {
  @HostBinding('class') class = "year-datepicker";

  @Input() set currentYear(year: number) {
    this.formControl.patchValue(moment([year]));
  }

  @Output() yearChanged: EventEmitter<number> = new EventEmitter<number>();

  formControl: FormControl = new FormControl();

  chosenYearHandler(normalizedYear: Moment,
                    dp: any) {
    const ctrlValue = this.formControl.value;
    const newYear = normalizedYear.year();
    ctrlValue.year(newYear);
    this.formControl.setValue(ctrlValue);
    this.yearChanged.emit(newYear)
    dp.close();
  }
}
