import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from "@angular/forms";
import {currentWeek, getWeeksInYear} from "../../util/date.util";

@Component({
  selector: 'app-week-select',
  templateUrl: './week-select.component.html',
  styleUrls: ['./week-select.component.scss']
})
export class WeekSelectComponent {

  readonly initialWeekValue = currentWeek();

  WeeksArray: number[] = [];

  @Input() set currentWeek(week: number) {
    this.formControl.patchValue(week);
  }

  @Input() set currentYear(year: number) {
    const numberOfWeeks = getWeeksInYear(year)
    this.WeeksArray = Array.from({length: numberOfWeeks}, (v, k) => k + 1);
  }

  @Output() weekChanged: EventEmitter<number> = new EventEmitter<number>();

  formControl: FormControl = new FormControl(this.initialWeekValue);


  onWeekChanges() {
    this.weekChanged.emit(this.formControl.value);
  }
}
