<mat-card>
  <mat-card-title>Paramètres de l'email</mat-card-title>
  <mat-card-content>
    <form (ngSubmit)="onSave()" #emailSettingsForm="ngForm" class="email-settings-form">
      <div class="form-grid">
        
        <!-- Left Column -->
        <div class="form-left">
          
          <!-- SMTP Host -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Hôte SMTP</mat-label>
            <input matInput [(ngModel)]="emailSettings.smtp_host" name="smtp_host" required>
            <mat-error *ngIf="emailSettingsForm.controls['smtp_host']?.invalid && (emailSettingsForm.controls['smtp_host']?.dirty || emailSettingsForm.controls['smtp_host']?.touched)">
              L'hôte SMTP est requis.
            </mat-error>
          </mat-form-field>
          
          <!-- SMTP Username -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Nom d'utilisateur SMTP</mat-label>
            <input matInput [(ngModel)]="emailSettings.smtp_username" name="smtp_username" required>
          </mat-form-field>

          <!-- SMTP Password -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Mot de passe SMTP</mat-label>
            <input matInput type="password" [(ngModel)]="emailSettings.smtp_password" name="smtp_password" required>
          </mat-form-field>

          <!-- SMTP Port -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Port SMTP</mat-label>
            <input matInput type="number" [(ngModel)]="emailSettings.smtp_port" name="smtp_port" required>
          </mat-form-field>
          
        </div>
        
        <!-- Right Column -->
        <div class="form-right">
          
          <!-- From Address -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Adresse de l'expéditeur</mat-label>
            <input matInput [(ngModel)]="emailSettings.smtp_from_address" name="smtp_from_address" required email>
            <mat-error *ngIf="emailSettingsForm.controls['smtp_from_address']?.invalid && (emailSettingsForm.controls['smtp_from_address']?.dirty || emailSettingsForm.controls['smtp_from_address']?.touched)">
              Une adresse email valide est requise.
            </mat-error>
          </mat-form-field>

          <!-- From Name -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Nom de l'expéditeur</mat-label>
            <input matInput [(ngModel)]="emailSettings.smtp_from_name" name="smtp_from_name" required>
          </mat-form-field>

          <!-- SMTP Encryption -->
          <mat-form-field appearance="fill" class="full-width">
            <mat-label>Chiffrement SMTP</mat-label>
            <mat-select [(ngModel)]="emailSettings.smtp_encryption" name="smtp_encryption" required>
              <mat-option value="none">Aucun</mat-option>
              <mat-option value="ssl">SSL</mat-option>
              <mat-option value="tls">TLS</mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
      </div>

      <!-- Submit Button -->
      <div class="form-actions">
        <button mat-raised-button color="primary" type="submit" [disabled]="emailSettingsForm.invalid">Enregistrer</button>
      </div>
    </form>
  </mat-card-content>
</mat-card>
