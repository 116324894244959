import {Component, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSelectionList} from '@angular/material/list';
import {Subscription} from 'rxjs';
import {Company} from 'src/app/model/company.model';
import {Service} from 'src/app/model/service.model';
import {ServiceService} from 'src/app/service/admin/service.service';

@Component({
  selector: 'app-company-service-dialog',
  templateUrl: './company-service-dialog.component.html',
  styleUrls: ['./company-service-dialog.component.scss']
})
export class CompanyServiceDialogComponent implements OnInit, OnDestroy {
  private _fetchingSubscription: Subscription | null = null;
  private _changed: boolean = false;

  fetching = false;
  errors: { [any: string]: string } = {};

  inputValues: { [any: string]: any } = {};

  services: Service[] = [];

  @ViewChild(MatSelectionList) serviceOptions: MatSelectionList;

  constructor(
    public readonly dialogRef: MatDialogRef<CompanyServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: Company,
    private readonly _serviceService: ServiceService
  ) { }

  ngOnInit(): void {
    this.services = this.data.services ?? [];
    this._fetchingSubscription = this._serviceService.getFetching$().subscribe((isFetching: boolean) => this.fetching = isFetching);
  }

  ngOnDestroy(): void {
    if (!!this._fetchingSubscription) {
      this._fetchingSubscription.unsubscribe();
    }
  }

  public handleChange(
    attribute: string,
    value: any
  ): void {
    this.inputValues[attribute] = value;
  }

  public handleDelete(): void {
    const options: number[] = this.serviceOptions?.selectedOptions.selected.map(({ value }) => value.id);
    // TODO call api delete
    this.services = this.services.filter(({ id }) => !options.includes(id));
    this.serviceOptions?.deselectAll();
    this._changed = true;
  }

  public handleCreateService(): void {
    const data: Partial<Service> = {
      name: this.inputValues['name'],
      company_id: this.data.id
    };

    this._serviceService.post$(data)
      .then((data: Service) => {
        this.services.push(data);
        this._changed = true;
      })
      .catch((err) => {
        if (!!err.errors) {
          Object.keys(err.errors).forEach(
            (attribute: string) =>
              (this.errors[attribute] = err.errors[attribute][0])
          );
        }
      });
  }

  public onClose(): void {
    this.dialogRef.close(this._changed ? this.data : null);
  }

}
