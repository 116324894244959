import {BankHoliday} from './bank-holiday.model';
import {IdentifiedModel, NamedModel} from './base-model.model';
import {Day} from './day.model';

export class BankHolidayPlanning implements NamedModel, IdentifiedModel {
  constructor(
    public id: number,
    public date: string,
    public day: Day,
    public bank_holiday: BankHoliday,
    public bank_holiday_id?: number,
    public day_id?: number
  ) {
    this.day = new Day(day.id, day.day, day.code, day.is_constant);
    this.bank_holiday = new BankHoliday(
      bank_holiday.id,
      bank_holiday.name,
      bank_holiday.type,
      bank_holiday.is_constant
    );
  }

  public getName(): string {
    return this.bank_holiday.getName();
  }
}
