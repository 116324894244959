<div fxLayout="row" fxLayoutAlign="center center" class="form-wrapper">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <mat-select
      [formControl]="control"
      [multiple]="multiple"
      [errorStateMatcher]="matcher"
    >
      <mat-option
        *ngFor="let item of items | customItemOption"
        [value]="item.value"
        >{{ item.name }}</mat-option
      >
    </mat-select>
    <mat-error *ngIf="isError">{{ error }}</mat-error>
  </mat-form-field>
</div>
