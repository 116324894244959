import {Pipe, PipeTransform} from '@angular/core';
import {Event} from 'src/app/model/event.model';
import {formatHoursToDisplay} from 'src/app/util/date.util';

@Pipe({
  name: 'agentTooltip',
})
export class AgentTooltipPipe implements PipeTransform {
  transform(event: Event): string {
    return `Début : ${formatHoursToDisplay(event.starting_date)}
            Fin : ${formatHoursToDisplay(event.ending_date)}
            ${
              !!event.comment
                ? `Commentaire : ${event.comment}`
                : 'Pas de commentaire'
            }`;
  }
}
