import {Pipe, PipeTransform} from '@angular/core';
import {HourPayed} from 'src/app/model/hour-payed.model';

@Pipe({
  name: 'TotalHours',
})
export class TotalHoursPipe implements PipeTransform {
  transform(values: HourPayed[]): number {
    return values.reduce((acc, {total}: HourPayed) => acc + total, 0.0);
  }
}
