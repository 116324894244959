import {ConstantModel, IdentifiedModel, NamedModel} from "./base-model.model";

export class Skill implements IdentifiedModel, NamedModel, ConstantModel {

  constructor(
    public id: number,
    public name: string,
    public is_constant: boolean
  ) {

  }

  getName(): string {
    return this.name;
  }
}
