import {Pipe, PipeTransform} from "@angular/core";
import {SiteStatus} from "../../model/site-status.model";

@Pipe({
  name: 'siteStatusPlanningNameDisplay',
})
export class SiteStatusPlanningNameDisplayPipe implements PipeTransform {
  transform(siteStatus: SiteStatus | undefined): string {
    return !siteStatus || siteStatus.name === "CSG" ? "" : siteStatus.name;
  }
}
