<h1 mat-dialog-title>
  {{ data?.title ?? "Voulez-vous supprimer cette ressource ?" }}
</h1>
<div mat-dialog-content>
  <p>{{ data?.description ?? "Attention, cette action est définitive." }}</p>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar mode="indeterminate" *ngIf="fetching"></mat-progress-bar>
  <button
    *ngIf="!fetching"
    mat-raised-button
    color="primary"
    (click)="onClose()"
  >
    {{ data?.cancelButton ?? "Annuler" }}
  </button>
  <button *ngIf="!fetching" mat-raised-button color="warn" (click)="onSave()">
    {{ data?.validButton ?? "Supprimer" }}
  </button>
</div>
