import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';

export enum DayPeriod {
  MORNING = '0',
  AFTERNOON = '1',
  NONE = '2',
}

export class Day implements IdentifiedModel, NamedModel, ConstantModel {
  public constructor(
    public id: number,
    public day: number,
    public code: string,
    public is_constant: boolean
  ) {}
  getName(): string {
    return this.code;
  }
}
