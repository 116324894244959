<mat-toolbar
  color="primary"
  class="expanded-toolbar mat-elevation-z6"
  fxFlex
  fxLayout="row"
  fxLayoutAlign="space-between center"
>
  <span>{{ title }}</span>

  <div>
    <ng-container *ngIf="!!this.user">
      <button mat-button routerLink="/view/planning-consult">Planning </button>
    </ng-container>

    <ng-container *ngIf="!!this.user && user.is_admin">
      
      <button mat-button routerLink="/view/tools/planificator"> 
        <span>Outil de planification </span>
      </button>

      <button mat-button [mat-menu-trigger-for]="administration">
        <span>Administration</span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #administration="matMenu" x-position="after">
        <button mat-menu-item [mat-menu-trigger-for]="agent">
          <mat-icon class="header-icon">manage_accounts</mat-icon>
          <span>Gestion du personnel</span>
        </button>

        <button mat-menu-item [mat-menu-trigger-for]="company">
          <mat-icon class="header-icon">business</mat-icon>
          <span>Gestion des entreprises</span>
        </button>

        <button mat-menu-item [mat-menu-trigger-for]="site">
          <mat-icon class="header-icon">map</mat-icon>
          <span>Gestion des lieux</span>
        </button>
      </mat-menu>

      <mat-menu #agent="matMenu" x-position="after">
        <button mat-menu-item routerLink="/admin/staff/agent">
          <mat-icon class="header-icon">perm_identity</mat-icon>
          <span>Agents</span>
        </button>

        <button mat-menu-item routerLink="/admin/staff/job">
          <mat-icon class="header-icon">work</mat-icon>
          <span>Fonctions</span>
        </button>

        <button mat-menu-item routerLink="/admin/staff/skill">
          <mat-icon class="header-icon">attribution</mat-icon>
          <span>Compétences</span>
        </button>
      </mat-menu>

      <mat-menu #company="matMenu">
        <button mat-menu-item routerLink="/admin/company">
          <mat-icon class="header-icon">apartment</mat-icon>
          <span>Entreprises</span>
        </button>

        <button mat-menu-item routerLink="/admin/company/service">
          <mat-icon class="header-icon">groups</mat-icon>
          <span>Services</span>
        </button>
      </mat-menu>

      <mat-menu #site="matMenu">
        <button mat-menu-item routerLink="/admin/site/operating-location">
          <mat-icon class="header-icon">layers</mat-icon>
          <span>Lieux d'exploitation</span>
        </button>

        <button mat-menu-item routerLink="/admin/site/operating-site">
          <mat-icon class="header-icon">room</mat-icon>
          <span>Sites d'exploitation</span>
        </button>
      </mat-menu>

      <button mat-button [mat-menu-trigger-for]="views">
        <span>Vues</span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #views="matMenu">
        <button mat-menu-item routerLink="/view/tools/site-status">
          <mat-icon class="header-icon">settings_input_composite</mat-icon>
          <span>États sites</span>
        </button>

        <!-- VUE ABSCENCE -->
        <button mat-menu-item routerLink="/view/abscence">
          <mat-icon class="header-icon">event_busy</mat-icon>
          <span>Abscences</span>
        </button>

        <!-- VUE ASTREINTE -->
        <button mat-menu-item routerLink="/view/astreinte">
          <mat-icon class="header-icon">nightlight_round</mat-icon>
          <span>Astreintes</span>
        </button>

        <button mat-menu-item routerLink="/view/bank-holiday">
          <mat-icon class="header-icon">celebration</mat-icon>
          <span>Jour fériés</span>
        </button>
      </mat-menu>

      <button mat-button [mat-menu-trigger-for]="impressions">
        <span>Impressions</span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #impressions="matMenu">
        <button mat-menu-item (click)="handleExportSelected('0')">
          <mat-icon class="header-icon">access_time_filled</mat-icon>
          <span>Cumul des heures majorées</span>
        </button>
        <button mat-menu-item (click)="handleExportSelected('1')">
          <mat-icon class="header-icon">calendar_view_week</mat-icon>
          <span>État des jours postés</span>
        </button>
        <button mat-menu-item (click)="handleExportSelected('2')">
          <mat-icon class="header-icon">group</mat-icon>
          <span>Équité intérimaires par entreprise</span>
        </button>
      </mat-menu>

      <!--<button mat-button routerLink="admin/email">
        <span>Gestion des Emails</span>
      </button>-->

        <!-- Nouveau Menu Paramètres -->
        <button mat-button [mat-menu-trigger-for]="settings">
          <span>Paramètres</span>
          <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #settings="matMenu">
          <button mat-menu-item routerLink="admin/email">
            <mat-icon class="header-icon">manage_accounts</mat-icon>
            <span>Gestion des Emails</span>
          </button>
          <button mat-menu-item routerLink="admin/sms-management">
            <mat-icon class="header-icon">sms</mat-icon>
            <span>SMS</span>
          </button>
          <button mat-menu-item routerLink="admin/email-management">
            <mat-icon class="header-icon">email</mat-icon>
            <span>SMTP</span>
          </button>
        </mat-menu>

    </ng-container>

    <ng-container *ngIf="!!this.user && user.is_planificateur && !user.is_admin">
      
      <button mat-button routerLink="/view/tools/planificator"> 
        <span>Outil de planification </span>
      </button>

      <button mat-button [mat-menu-trigger-for]="impressions">
        <span>Impressions</span>
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #impressions="matMenu">
        <button mat-menu-item (click)="handleExportSelected('0')">
          <mat-icon class="header-icon">access_time_filled</mat-icon>
          <span>Cumul des heures majorées</span>
        </button>
        <button mat-menu-item (click)="handleExportSelected('1')">
          <mat-icon class="header-icon">calendar_view_week</mat-icon>
          <span>État des jours postés</span>
        </button>
        <button mat-menu-item (click)="handleExportSelected('2')">
          <mat-icon class="header-icon">group</mat-icon>
          <span>Équité intérimaires par entreprise</span>
        </button>
      </mat-menu>

      <!--<button mat-button routerLink="admin/email">
        <span>Gestion des Emails</span>
      </button>-->

        <!-- Nouveau Menu Paramètres -->
        

    </ng-container>


   
    

    

    <ng-container *ngIf="!this.user; else loggedOut">
      <button mat-button routerLink="/auth/login">
        <mat-icon class="header-icon">login</mat-icon>
        <span>Se connecter</span>
      </button>
    </ng-container>

    <ng-template #loggedOut>
      <button mat-button [mat-menu-trigger-for]="userMenu">
        <mat-icon class="header-icon">account_circle</mat-icon>
      </button>

      <mat-menu #userMenu="matMenu">
        <p>{{ user!.firstName }} {{ user!.lastName }}</p>
        <mat-divider></mat-divider>
        <button mat-menu-item routerLink="/user/change-password">
          <mat-icon class="header-icon">password</mat-icon>
          <span>Changer de mot de passe</span>
        </button>

        <button mat-menu-item (click)="logout()">
          <mat-icon class="header-icon">logout</mat-icon>
          <span>Se déconnecter</span>
        </button>
      </mat-menu>
    </ng-template>
  </div>
</mat-toolbar>
