import {Component} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from 'src/app/service/auth.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent {
  passwordForm = new FormGroup({
    password: new FormControl('', Validators.required),
    password_confirmation: new FormControl('', Validators.required),
  });

  hidePassword = true;
  hidePasswordConfirmation = true;
  fetching = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _snackBar: MatSnackBar
  ) {}

  public changePassword() {
    this.fetching = true;

    const { password, password_confirmation } = this.passwordForm.value;

    this._authService
      .changePassword(password, password_confirmation)
      .then((_) => {
        this._snackBar.open(
          `Le mot de passe a été modifié avec succès !`,
          'Fermer',
          {
            duration: SNACKBAR_DEFAULT_DURATION,
          }
        );
        this.passwordForm.reset({ password: '', password_confirmation: '' });
      })
      .catch((err) => {
        if (!!err.errors) {
          Object.keys(err.errors).forEach((attribute) =>
            this.passwordForm
              .get(attribute)
              ?.setErrors({ [attribute]: err.errors[attribute][0] })
          );
        }
      })
      .finally(() => (this.fetching = false));
  }
}
