import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {SkillCreationDialogComponent} from 'src/app/dialog/skill-creation-dialog/skill-creation-dialog.component';
import {Skill} from 'src/app/model/skill.model';
import {SkillService} from 'src/app/service/admin/skill.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';

@Component({
  selector: 'app-skill',
  templateUrl: './skill.component.html',
  styleUrls: ['./skill.component.scss'],
})
export class SkillComponent extends AbstractAdministrationComponent<Skill> {
  headersAndValues: HeaderAndValue<Skill>[] = [
    {
      displayName: 'Nom',
      propertyName: 'name',
      value: (skill: Skill) => skill.name,
    },
  ];

  contextMenusAndActions: ContextMenuAndAction<Skill>[] = [
    {
      name: (_: Skill) => 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: Skill) => this.handleUpdate(item),
      canBeActivated: (item: Skill) => !item.is_constant,
    },
    {
      name: (_: Skill) => 'Supprimer',
      icon: 'delete',
      color: 'warn',
      callback: (item: Skill) => this.handleDelete(item),
      canBeActivated: (item: Skill) => !item.is_constant,
    },
  ];

  constructor(
    protected readonly _service: SkillService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_service, _dialog, _snackBar);
  }

  public handleUpdate(skill: Skill | null = null): void {
    const dialogRef = this._dialog.open(SkillCreationDialogComponent, {
      data: skill,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { name }: Skill = result as Skill;
          this._snackBar.open(
            `La compétence ${name} a été ${
              !!skill ? 'modifiée' : 'créée'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }

  public handleDelete(skill: Skill): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (doDeletion: boolean) => {
        if (doDeletion) {
          await this._service.delete$(skill);
          this._snackBar.open(
            `La compétence ${skill.name} a supprimée avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }
}
