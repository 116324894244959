import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {CustomDate} from 'src/app/model/custom-date.model';
import {Day} from 'src/app/model/day.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {SiteStatus} from 'src/app/model/site-status.model';
import {SiteStatusPlanningFacadeService} from 'src/app/service/facade/site-status-planning-facade.service';
import {getDayNumberFromDate, getWeekNumber, getWeeksInYear,} from 'src/app/util/date.util';

@Component({
  selector: 'app-site-status-planning',
  templateUrl: './site-status-planning.component.html',
  styleUrls: ['./site-status-planning.component.scss'],
})
export class SiteStatusPlanningComponent implements OnInit {
  days$: Observable<Day[]>;
  operatingSites$: Observable<OperatingSite[]>;
  fetching$: Observable<boolean>;
  statuses$: Observable<SiteStatus[]>;

  datesByWeekNumber: Map<number, CustomDate[]>;
  monthByWeekNumber: Map<number, string>;

  year: string;

  working = true;

  asIsOrder(a: any, b: any) {
    return 1;
  }

  get valid$(): Observable<boolean> {
    return of(!!this.days$ &&
      !!this.operatingSites$ &&
      !!this.statuses$ &&
      !!this.datesByWeekNumber &&
      !!this.monthByWeekNumber
    );
  }

  get customDates(): CustomDate[] {
    return [...(this.datesByWeekNumber.values() ?? [])].reduce(
      (acc, next) => [...acc, ...next],
      []
    );
  }

  constructor(private readonly _siteStatusPlanningFacade: SiteStatusPlanningFacadeService) {
    this.days$ = this._siteStatusPlanningFacade.days$;
    this.operatingSites$ = this._siteStatusPlanningFacade.operatingSites$;
    this.fetching$ = this._siteStatusPlanningFacade.fetching$;
    this.statuses$ = this._siteStatusPlanningFacade.siteStatuses$;
  }

  ngOnInit(): void {
    this._siteStatusPlanningFacade.initialize();

    combineLatest([
      this.days$,
      this._siteStatusPlanningFacade.weekSelected$
    ]).subscribe(([days]) => {
      this.working = true;
      this.datesByWeekNumber = new Map();
      this.monthByWeekNumber = new Map();
      const dates: Date[] = this._siteStatusPlanningFacade.dates;

      dates.forEach((date: Date) => {
        let weekNumbers: number = getWeekNumber(date);
        const dayNumber = getDayNumberFromDate(date);
        const customDate: CustomDate = new CustomDate(
          date,
          days.filter(({day}) => dayNumber == day)[0]
        );
        if (weekNumbers === -1) {
          weekNumbers = getWeeksInYear(date.getFullYear());
        }
        if (this.datesByWeekNumber.has(weekNumbers)) {
          this.datesByWeekNumber.get(weekNumbers)!.push(customDate);
        } else {
          this.datesByWeekNumber.set(weekNumbers, [customDate]);
        }

      });

      for (let [weekNumber, dates] of this.datesByWeekNumber) {
        this.monthByWeekNumber.set(
          weekNumber,
          dates.reduce((acc, next, index) => {
            const monthName = next.monthName;

            if (!acc.includes(monthName)) {
              if (index != 0) {
                return `${acc} - ${monthName}`;
              }

              return monthName;
            }

            return acc;
          }, '')
        );
      }

      this.year = dates.reduce((acc, next, index) => {
        const year = `${next.getFullYear()}`;

        if (!acc.includes(`${year}`)) {
          if (index != 0) {
            return `${acc} - ${year}`;
          }

          return year;
        }

        return acc;
      }, '');
      this.working = false;
    });
  }

  handlePreviousWeek(): void {
    this._siteStatusPlanningFacade.previousWeek();
  }

  handleNextWeek(): void {
    this._siteStatusPlanningFacade.nextWeek();
  }
}
