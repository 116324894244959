<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">date_range</mat-icon>
  <span>{{ title }}</span>
</h1>
<div mat-dialog-content>
  <mat-form-field>
    <mat-label>Année désirée</mat-label>
    <input matInput [(ngModel)]="year" step="1" required />
  </mat-form-field>
  <mat-form-field *ngIf="dialogData.type == '0'">
    <mat-label>Mois désiré</mat-label>
    <input matInput [(ngModel)]="month" step="1" required />
  </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar
    mode="indeterminate"
    *ngIf="working$ | async"
  ></mat-progress-bar>
  <button
    *ngIf="!(working$ | async)"
    mat-raised-button
    color="warn"
    (click)="onClose()"
  >
    Annuler
  </button>
  <button
    *ngIf="!(working$ | async)"
    mat-raised-button
    (click)="handleExport()"
    color="primary"
  >
    <span>Télécharger</span>
    <mat-icon>file_download</mat-icon>
  </button>
</div>
