import {HttpClient, HttpResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {FetchingService} from './fetching.service';
import {currentMonth, currentYear,} from '../../util/date.util';
import * as FileSaver from 'file-saver';
import {getFileName} from 'src/app/util/excel-file.util';
import {Observable} from 'rxjs';

const EXPORT_URL = '/api/excel-export';

@Injectable({
  providedIn: 'root',
})
export class ExcelExportService {
  constructor(
    @Inject(FetchingService) private readonly _fetchingService: FetchingService,
    private readonly _httpClient: HttpClient
  ) {}

  public getPostedHours$(year: number = currentYear()): Promise<void> {
    this._fetchingService.fetching = true;
    const result = this._httpClient.get(
      `${EXPORT_URL}/posted-hours?year=${year}`,
      { responseType: 'blob', observe: 'response' }
    );

    return this._handleResponse(result);
  }

  public getIncreasedHoursAccumulation$(
    year: number = currentYear(),
    month: number = currentMonth()
  ): Promise<void> {
    const result = this._httpClient.get(
      `${EXPORT_URL}/increased-hours-accumulation?year=${year}&month=${month}`,
      { responseType: 'blob', observe: 'response' }
    );

    return this._handleResponse(result);
  }

  public getTempWorkerEquity$(year: number = currentYear()): Promise<void> {
    const result = this._httpClient.get(
      `${EXPORT_URL}/temp-worker-equity?year=${year}`,
      { responseType: 'blob', observe: 'response' }
    );

    return this._handleResponse(result);
  }

  private _handleResponse(
    result: Observable<HttpResponse<Blob>>
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next: (d: HttpResponse<Blob>) => {
          const fileName: string = getFileName(d);
          FileSaver.saveAs(d.body!, fileName);
          this._fetchingService.fetching = false;
          resolve();
        },
        error: (error: any) => {
          reject(error);
        },
      });
    });
  }
}
