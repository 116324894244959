<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">event_busy</mat-icon>
    <span>Saisie des absences</span>
  </mat-card-title>
  <div class="horizontal-delimiter"></div>
  <mat-card-content *ngIf="state$ | async as state">
    <ng-container *ngIf="state.fetching; else notLoading">
      <mat-progress-spinner diameter="30"
                            color="accent"
                            mode="indeterminate">
      </mat-progress-spinner>
    </ng-container>
    <ng-template #notLoading>
      <div fxLayout="row"
           fxLayoutAlign="start center">
        <app-custom-search-input [search$]="search$"></app-custom-search-input>
      </div>
      <table class="mat-elevation-z8 mat-table">
        <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell" colspan="100">
            <button (click)="handlePreviousYear()" mat-button>
              <mat-icon>navigate_before</mat-icon>
            </button>
            {{ state.yearSelected }}
            <button (click)="handleNextYear()" mat-button>
              <mat-icon>navigate_next</mat-icon>
            </button>
          </th>
          <th *ngFor="let month of state.weeksByMonth | keyvalue: keepOrder"
              [attr.colspan]="month.value * 10"
              class="mat-header-cell">
            {{ month.key }}
          </th>
        </tr>
        <tr class="mat-header-row">
          <th class="mat-header-cell" colspan="100">Semaine</th>
          <th *ngFor="let week of state.weekNumbers"
              class="mat-header-cell"
              colspan="10">
            {{ week }}
          </th>
        </tr>

        <tr *ngFor="let agent of state.agents"
            app-indisponibilties-agent-row
            [activityTypes]="state.activityTypes"
            [agent]="agent"
            [selectedYear]="state.yearSelected"
            [weeks]="state.weekNumbers"
            [indisponibilitiesByAgent]="state.indisponibilitiesByAgent.get(agent.id) ?? []"
            class="mat-row">
        </tr>
        </thead>
      </table>
    </ng-template>


  </mat-card-content>
</mat-card>
