import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from 'src/app/model/company.model';
import {Service} from 'src/app/model/service.model';
import {CompanyService} from 'src/app/service/admin/company.service';
import {ServiceService} from 'src/app/service/admin/service.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-service-creation-dialog',
  templateUrl: './service-creation-dialog.component.html',
  styleUrls: ['./service-creation-dialog.component.scss'],
})
export class ServiceCreationDialogComponent
  extends AbstractDialogComponent<Service>
  implements OnInit
{
  companies: Company[] = [];

  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Service>>,
    protected readonly _serviceService: ServiceService,
    private readonly _companyService: CompanyService,
    @Inject(MAT_DIALOG_DATA) data: Service | null
  ) {
    super(dialogRef, _serviceService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._companyService
      .findAllWithRelations$()
      .subscribe((companies: Company[]) => {
        this.companies = companies;
        this._initializeDataWhenNeeded();
      });
  }

  public getDataToSend(): Partial<Service> {
    const service: Partial<Service> = {
      name: this.inputValues.name,
      company: this.inputValues.company,
    };

    if (!!this.data?.id) {
      service.id = this.data!.id;
    }

    return service;
  }

  protected override _initializeData({ name, company }: Service): void {
    this.inputValues['name'] = name;
    this.inputValues['company'] = company?.id;
  }
}
