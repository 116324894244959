import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'yearsSelectedDisplay',
})
export class YearsSelectedDisplayPipe implements PipeTransform {
  transform(years: number[]): string {
    return years.reduce((acc, next, index: number) => {
      if (index == 0) {
        return `${next}`;
      }

      if (acc.includes(`${next}`)) {
        return acc;
      }

      return `${acc} - ${next}`;
    }, '');
  }
}
