import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {
  AlertEntityPresentDialogComponent
} from 'src/app/dialog/alert-entity-present-dialog/alert-entity-present-dialog.component';
import {ServiceCreationDialogComponent} from 'src/app/dialog/service-creation-dialog/service-creation-dialog.component';
import {Service} from 'src/app/model/service.model';
import {ServiceService} from 'src/app/service/admin/service.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss'],
})
export class ServiceComponent extends AbstractAdministrationComponent<Service> {
  headersAndValues: HeaderAndValue<Service>[] = [
    {
      displayName: '#',
      propertyName: 'id',
      value: (service: Service) => service.id,
    },
    {
      displayName: 'Nom',
      propertyName: 'name',
      value: (service: Service) => service.name,
    },
    {
      displayName: 'Entreprise',
      propertyName: 'company.name',
      value: (service: Service) => service.company?.name,
    },
    {
      displayName: "Nombre d'agents",
      propertyName: 'users',
      value: (service: Service) => service.users?.length,
    },
  ];

  contextMenusAndActions: ContextMenuAndAction<Service>[] = [
    {
      name: (_: Service) => 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: Service) => this.handleUpdate(item),
      canBeActivated: (item: Service) => !item.is_constant,
    },
    {
      name: (_: Service) => 'Supprimer',
      icon: 'delete',
      color: 'warn',
      callback: (item: Service) => this._handleDelete(item),
      canBeActivated: (item: Service) => !item.is_constant,
    },
  ];

  constructor(
    protected readonly _service: ServiceService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_service, _dialog, _snackBar);
  }

  public handleUpdate(service: Service | null = null): void {
    const dialogRef = this._dialog.open(ServiceCreationDialogComponent, {
      data: service,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { name }: Service = result as Service;
          this._snackBar.open(
            `Le service ${name} a été ${
              !!service ? 'modifié' : 'créé'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }

  private _handleDelete(service: Service): void {
    if (!!service.users && service.users.length > 0) {
      this._showUsersAssignedAlert();
    } else {
      this.handleDelete(service);
    }
  }

  private _showUsersAssignedAlert(): void {
    this._dialog.open(AlertEntityPresentDialogComponent, {
      data: {
        title: 'Des agents vont se retrouver sans service !',
        message:
          'Veuillez associer tout les agents à un autre service avant de supprimer ce service.',
      },
    });
  }
}
