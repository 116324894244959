import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {ActivityType} from 'src/app/model/activity-type.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const ACTIVITY_TYPE_URL = '/api/activity_type';

@Injectable({
  providedIn: 'root',
})
export class ActivityTypeService extends AbstractService<ActivityType> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, ACTIVITY_TYPE_URL);
  }

  protected _convertData({
    id,
    name,
    code,
    favorite_work_schedule_code,
    is_constant,
  }: ActivityType): ActivityType {
    return new ActivityType(
      id,
      name,
      code,
      favorite_work_schedule_code,
      is_constant
    );
  }
}
