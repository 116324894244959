import {HttpErrorResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';

export function httpObservableToPromise<T>(
  observable: Observable<T>
): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    return observable.pipe(take(1)).subscribe({
      next(entities: T) {
        return resolve(entities);
      },

      error(error: HttpErrorResponse) {
        return reject(error.error);
      },
    });
  });
}
