import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface Legend {
  color: string;
  name: string;
}

@Component({
  selector: 'app-recap-table-etat-site-legend',
  templateUrl: './recap-table-etat-site-legend.component.html',
  styleUrls: ['./recap-table-etat-site-legend.component.scss'],
})
export class RecapTableEtatSiteLegendComponent {

  @Input()
  public boundaryLimit: string;

  @Input()
  sitesStatusesPlanningRecap: Map<string, number>;

  @Output()
  public closeWanted$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public handleClose(): void {
    this.closeWanted$.emit(true);
  }
}
