import {Pipe, PipeTransform} from '@angular/core';
import {Event} from 'src/app/model/event.model';
import {createTextFromEvent} from 'src/app/util/planning.util';

@Pipe({
  name: 'planningConsultCell',
})
export class PlanningConsultCellPipe implements PipeTransform {
  transform(event: Event): string {
    return createTextFromEvent(event);
  }
}
