<div class="legend-box"
     cdkDrag
     [cdkDragBoundary]="boundaryLimit">
  <div fxLayout="column"
       fxLayoutAlign="start center"
       class="list-container">
    <table id="customers">
      <tr>
        <td></td>
        <th scope="col">Mode I</th>
        <th scope="col">Mode II</th>
        <th scope="col">Mode III</th>
      </tr>
      <tr>
        <th scope="row">A</th>
        <td>{{ sitesStatusesPlanningRecap.get('AI') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('AII') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('AIII') }}</td>
      </tr>
      <tr>
        <th scope="row">B</th>
        <td>{{ sitesStatusesPlanningRecap.get('BI') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('BII') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('BIII') }}</td>
      </tr>
      <tr>
        <th scope="row">C</th>
        <td>{{ sitesStatusesPlanningRecap.get('CI') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('CII') }}</td>
        <td>{{ sitesStatusesPlanningRecap.get('CIII') }}</td>
      </tr>
    </table>
  </div>

  <button color="warn"
          mat-button
          class="close-legends-button"
          (click)="handleClose()">
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>
