import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Agent} from 'src/app/model/agent.model';
import {Service} from 'src/app/model/service.model';

import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const SERVICE_URL = '/api/service';

@Injectable({
  providedIn: 'root',
})
export class ServiceService extends AbstractService<Service> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, SERVICE_URL);
  }

  public findServices(): Observable<Service[]> {
    this.setFetching(true);

    return this._httpClient
      .get<Service[]>(`${SERVICE_URL}?relations[]=users&relations[]=company`)
      .pipe(
        catchError(catchErrorOnArray()),
        map((services: Service[]) => {
          return services.map(this._convertData);
        }),
        take(1),
        finalize(() => this.setFetching(false))
      );
  }

  public updateUsers(service: Service, agent: Agent[]): Promise<Service> {
    const userIds: number[] = agent.map(({ id }: Agent) => id);
    this.setFetching(true);

    const result = this._httpClient
      .post<Service>(`${SERVICE_URL}/${service.id}/users`, {
        users: userIds,
      })
      .pipe(
        catchError(this._onError(service)),
        take(1),
        finalize(() => this.setFetching(false))
      );

    return new Promise<Service>((resolve, reject) => {
      return result.subscribe({
        next(data: Service) {
          return resolve(data);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  protected override _convertData({
    id,
    name,
    company,
    company_id,
    users,
    is_constant,
  }: Service): Service {
    return new Service(id, name, is_constant, company_id, company, users);
  }
}
