import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Agent} from 'src/app/model/agent.model';
import {Astreinte} from 'src/app/model/astreinte.model';
import {AstreinteFacadeService} from 'src/app/service/facade/astreinte-facade.service';

@Component({
  selector: '[app-astreinte-agent-row]',
  templateUrl: './astreinte-agent-row.component.html',
  styleUrls: ['./astreinte-agent-row.component.scss'],
})
export class AstreinteAgentRowComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;
  public astreinteByWeek: Map<number, Astreinte> = new Map<number, Astreinte>();

  public selected: number[] = [];

  @Input()
  public agent: Agent;

  @Input()
  public set astreintes(data: Astreinte[]) {
    if (!!data) {
      this.astreinteByWeek.clear();
      data.forEach((astreinte: Astreinte) =>
        this.astreinteByWeek.set(astreinte.week, astreinte)
      );
    }
  }

  @Input()
  public weekNumbers: number[];

  constructor(private readonly _facade: AstreinteFacadeService) {}

  ngOnInit(): void {
    this._subscription = this._facade
      .getSelectionForGivenAgent$(this.agent)
      .subscribe((d) => (this.selected = d));
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public handleTdClicked(week: number): void {
    if (this.selected.includes(week)) {
      this.selected = this.selected.filter((value) => value != week);
    } else {
      this.selected.push(week);
    }

    this._facade.setSelectionForGivenAgent(this.agent, this.selected);
  }
}
