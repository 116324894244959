import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

const NOTIFICATION_API_URL = '/api/notification'; 

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  sendSms(to: string ,  week:number): Observable<any> {
    let message = `Changement de vos horaires pour la semaine ${week}. Merci de consulter le planning en ligne pour prendre connaissance des modifications.`;
    const payload = { to , message};
    return this.http.post(`${NOTIFICATION_API_URL}/send-sms`, payload);
  }

  sendEmail(to: string, week:number): Observable<any> {
    let subject = "Docker EPCU";
    let message = `Changement de vos horaires pour la semaine ${week}. Merci de consulter le planning en ligne pour prendre connaissance des modifications.`;
    const payload = { to,subject, message};
    return this.http.post(`${NOTIFICATION_API_URL}/send-email`, payload);
  }
}
