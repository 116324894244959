import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {BankHoliday} from 'src/app/model/bank-holiday.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

const BANK_HOLIDAY_URL = '/api/bank-holiday';

@Injectable({
  providedIn: 'root',
})
export class BankHolidayService extends AbstractService<BankHoliday> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, BANK_HOLIDAY_URL);
  }

  protected _convertData({
    id,
    name,
    type,
    is_constant,
  }: BankHoliday): BankHoliday {
    return new BankHoliday(id, name, type, is_constant);
  }
}
