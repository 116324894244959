import {KeyValue} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Agent} from 'src/app/model/agent.model';
import {WeekAndMonth,} from 'src/app/service/facade/astreinte-facade.service';
import {getWeeksByMonth} from 'src/app/util/astreinte.util';
import {IndisponibilityFacadeService} from "../../../service/facade/indisponibility-facade.service";

@Component({
  selector: 'app-indisponibilties',
  templateUrl: './indisponibilties.component.html',
  styleUrls: ['./indisponibilties.component.scss'],
})
export class IndisponibiltiesComponent implements OnInit {
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  state$: Observable<any>;

  constructor(private readonly _indisponibilityFacadeService: IndisponibilityFacadeService) {
  }

  readonly keepOrder = (_: KeyValue<string, number>, __: KeyValue<string, number>) => 0;

  ngOnInit(): void {
    this.state$ = combineLatest([
      this._indisponibilityFacadeService.year$,
      this._indisponibilityFacadeService.weekAndMonth$.pipe(map((weekAndMonth: WeekAndMonth[]) => getWeeksByMonth(weekAndMonth))),
      combineLatest([
        this._indisponibilityFacadeService.agents$,
        this.search$
      ]).pipe(
        map(([agents, search]) =>
          agents.filter((agent: Agent) =>
            agent
              .getName()
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        )
      ),
      this._indisponibilityFacadeService.weekNumbers$,
      this._indisponibilityFacadeService.activityTypes$,
      this._indisponibilityFacadeService.indisponibilitiesByAgent$,
      this._indisponibilityFacadeService.fetching$
    ]).pipe(
      map(
        ([
           yearSelected,
           weeksByMonth,
           agents,
           weekNumbers,
           activityTypes,
           indisponibilitiesByAgent,
           fetching
         ]) => ({
          yearSelected,
          weeksByMonth,
          agents,
          weekNumbers,
          activityTypes,
          indisponibilitiesByAgent,
          fetching
        })
      ),
    );
  }

  handlePreviousYear(): void {
    this._indisponibilityFacadeService.previousYear();
  }

  handleNextYear(): void {
    this._indisponibilityFacadeService.nextYear();
  }

}
