<ng-container *ngIf="!etatSiteDependant">
  <tr class="mat-row">
    <td colspan="140" class="mat-cell">
      <div fxLayout="row"
           fxLayoutAlign="space-between center"
           class="header-container">
        <ng-container *ngIf="!!fetching$ && !(fetching$ | async)">
          <button mat-button (click)="expanded = !expanded">
            <mat-icon *ngIf="!expanded">expand_more</mat-icon>
            <mat-icon *ngIf="expanded">expand_less</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!fetching$ || (fetching$ | async)">
          <mat-progress-spinner
            diameter="30"
            color="accent"
            mode="indeterminate">
          </mat-progress-spinner>
        </ng-container>
        <p>{{ job.getName() }}</p>
        <button *ngIf="isLatestEventVersionSelected$ | async"
                mat-button
                (click)="handleAddResources()">
          <mat-icon>person_add</mat-icon>
        </button>
        <div *ngIf="!(isLatestEventVersionSelected$ | async)"></div>
      </div>
    </td>

    <td [attr.colspan]="140 * sites.length"
        class="mat-cell"></td>
  </tr>
</ng-container>

<ng-container *ngIf="hoursPayed$ | async as hoursPayed">
  <tr *ngIf="etatSiteDependant"
      class="mat-row"
      app-site-status-header-row
      [hoursPayed]="hoursPayed"
      [sites]="sites"
      [dates]="dates"
      [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite">
    <td colspan="140"
        class="mat-cell">
      <div fxLayout="row"
           fxLayoutAlign="space-between center"
           class="header-container">
        <ng-container *ngIf="!!fetching$ && !(fetching$ | async)">
          <button mat-button (click)="expanded = !expanded">
            <mat-icon *ngIf="!expanded">expand_more</mat-icon>
            <mat-icon *ngIf="expanded">expand_less</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="!fetching$ || (fetching$ | async)">
          <mat-progress-spinner diameter="30"
                                color="accent"
                                mode="indeterminate">
          </mat-progress-spinner>
        </ng-container>
        <p>{{ job.getName() }}</p>
        <button *ngIf="isLatestEventVersionSelected$ | async"
                mat-button
                (click)="handleAddResources()">
          <mat-icon>person_add</mat-icon>
        </button>
        <div *ngIf="!(isLatestEventVersionSelected$ | async)"></div>
      </div>
    </td>
  </tr>

  <ng-container *ngIf="expanded && !!fetching$ && !(fetching$ | async)">
    <tr app-agent-row
        *ngFor="let agent of agentsAdded | keyvalue"
        class="mat-row"
        [workSchedules]="workSchedules"
        [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite"
        [sitesStatus]="sitesStatus"
        [activityTypes]="types"
        [agent]="agent.value"
        [sites]="sites"
        [dates]="dates"
        [jobSelected]="job">
    </tr>
  </ng-container>
</ng-container>
