<div mat-dialog-content fxLayout="row" fxLayoutAlign="center">
  <div
    class="service-action-container"
    fxLayout="column"
    fxLayoutAlign="center center"
    fxFlex="50%"
  >
    <h2>Supprimer des services</h2>

    <mat-selection-list #serviceOptions>
      <mat-list-option *ngFor="let service of services" [value]="service">
        {{ service.name }}
      </mat-list-option>
    </mat-selection-list>

    <button
      mat-fab
      color="warn"
      [disabled]="serviceOptions?.selectedOptions?.isEmpty()"
      (click)="handleDelete()"
    >
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <div class="vertical-delimiter"></div>

  <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50%">
    <h2>Ajouter un service</h2>

    <form fxLayout="column" fxLayoutAlign="center center">
      <app-custom-input
        [initialValue]="inputValues['name']"
        [label]="'Nom'"
        [required]="true"
        [fetching]="fetching"
        [error]="errors['name']"
        (value$)="handleChange('name', $event)"
      >
      </app-custom-input>
    </form>

    <button mat-fab color="primary" (click)="handleCreateService()">
      <mat-icon>add_circle_outline</mat-icon>
    </button>
  </div>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar mode="indeterminate" *ngIf="fetching"></mat-progress-bar>
  <button *ngIf="!fetching" mat-button color="primary" (click)="onClose()">
    Fermer
  </button>
</div>
