import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name: 'operatingLocationNameDisplay',
})
export class OperatingLocationNameDisplayPipe implements PipeTransform {
  transform(operatingLocationName: string): string {
    return operatingLocationName === "EPCU" ? "Par personnel" : `Par Service (${operatingLocationName})`;
  }
}
