import {Directive} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {User} from '../model/user.model';

@Directive()
export abstract class AbstractBankHolidayDialogDirective {
  constructor(
    protected readonly _dialogRef: MatDialogRef<any>,
    protected readonly _snackBar: MatSnackBar
  ) {}

  protected _handleUserWithEventResponse(users: User[]): void {
    this._dialogRef.close();

    if (users.length > 0) {
      const userList: string = users.reduce(
        (acc, next: User, index: number) => {
          if (index == 0) {
            return `- ${next.getName()}`;
          }

          return `${acc}\n- ${next.getName()}`;
        },
        ''
      );
      this._snackBar.open(
        `Ce${users.length > 1 ? 's' : 't'} utilisateur${
          users.length > 1 ? 's' : ''
        } ${
          users.length > 1 ? 'ont' : 'a'
        } déjà une activité pendant vos jours ajoutés : \n\n${userList}`,
        'Fermer',
        {
          panelClass: ['snackbar-white-space'],
        }
      );
    }
  }
}
