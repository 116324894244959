import {IdentifiedModel} from './base-model.model';
import {Job} from "./job.model";

export class Email implements IdentifiedModel {
  constructor(
    public id: number,
    public email: string,
    public description: string,
    public subject: string,
    public to: string,
    public key: string,
    public send_to_superadmin: number,
    public template: string,
    public status: number,
    public sms_content: string,
    public send_method: number,
    public sms_response: number,
    public phone_verification: number,
    public sms_type: number,
  ) {}


}
