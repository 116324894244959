import {Legend} from '../common/legend/draggable-legend/draggable-legend.component';
import {ActivityType} from "../model/activity-type.model";


export const generateActivityTypes = (activityTypes: ActivityType[]): Legend[] => activityTypes.map(({code, name}) => {
  return {
    name: `${!!code ? code : '(vide)'} : ${name}`,
    color: 'white',
  };
});

