import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {JobCreationDialogComponent} from 'src/app/dialog/job-creation-dialog/job-creation-dialog.component';
import {Job} from 'src/app/model/job.model';
import {JobService} from 'src/app/service/admin/job.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';

@Component({
  selector: 'app-job',
  templateUrl: './job.component.html',
  styleUrls: ['./job.component.scss'],
})
export class JobComponent extends AbstractAdministrationComponent<Job> {
  headersAndValues: HeaderAndValue<Job>[] = [
    {
      displayName: 'Nom',
      propertyName: 'name',
      value: (job: Job) => job.name,
    },
  ];

  contextMenusAndActions: ContextMenuAndAction<Job>[] = [
    {
      name: (_: Job) => 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: Job) => this.handleUpdate(item),
      canBeActivated: (item: Job) => !item.is_constant,
    },
    {
      name: (_: Job) => 'Supprimer',
      icon: 'delete',
      color: 'warn',
      callback: (item: Job) => this.handleDelete(item),
      canBeActivated: (item: Job) => !item.is_constant,
    },
  ];

  constructor(
    protected readonly _jobService: JobService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_jobService, _dialog, _snackBar);
  }

  public handleUpdate(job: Job | null = null): void {
    const dialogRef = this._dialog.open(JobCreationDialogComponent, {
      data: job,
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { name }: Job = result as Job;
          this._snackBar.open(
            `La fonction ${name} a été ${
              !!job ? 'modifiée' : 'créée'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }
}
