import {ActivityType} from './activity-type.model';
import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';

export class Job implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public name: string,
    public code: string,
    public activity_type_id: number,
    public column_order: number | null,
    public is_constant: boolean,
    public activity_type?: ActivityType
  ) {
    if (!!this.activity_type) {
      this.activity_type = new ActivityType(
        activity_type!.id,
        activity_type!.name,
        activity_type!.code,
        activity_type!.favorite_work_schedule_code,
        activity_type!.is_constant
      );
    }
  }

  getName(): string {
    return this.name;
  }
}

export const sortJob = (a: Job, b: Job) => {
  if (!a.column_order) {
    if (!b.column_order) {
      return 0;
    }

    return 1;
  }

  if (!b.column_order) {
    return -1;
  }

  if (a.column_order == b.column_order) {
    return 0;
  }

  return a.column_order < b.column_order ? -1 : 1;
};
