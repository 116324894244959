export const HOME_TITLE = 'Bienvenue sur Docker-EPCU';
export const CREDENTIALS_ERROR = 'Adresse mail et/ou mot de passe invalide(s).';
export const TOKEN_STORAGE_KEY = 'token';
export const USER_STORAGE_KEY = 'user';
export const INPUT_ERROR_KEY = 'custom_error';
export const WORK_SCHEDULE_SPECIAL_CODE = 'Hs';
export const WORK_SCHEDULE_POSTE_CONTINU_CODE = 'PC';
export const ACTIVITY_TYPE_UNAVAILABILITY_CODES = ['R', 'C', 'Ma', 'AP'];
export const UNAVAILABLE_DATE_COLOR = '#FFFF00';
export const SUNDAY_DAY_NUMBER = 7;
export const DATE_DELIMITATOR = '-';
export const CSG_SITE_STATUS_NAME = 'CSG';
export const SHIFT_CODES = ['A', 'M'];
