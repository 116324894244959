<div [matMenuTriggerFor]="contextMenu"
     [style.left]="contextMenuPosition?.x"
     [style.top]="contextMenuPosition?.y"
     style="visibility: hidden; position: fixed">
</div>

<mat-menu #contextMenu="matMenu">
  <ng-container *ngIf="!indisponibilitiy">
    <button (click)="handleAddLine(activityType)"
            *ngFor="let activityType of activityTypes"
            mat-menu-item>
      {{ activityType.getName() }}
    </button>
  </ng-container>

  <ng-container *ngIf="!!indisponibilitiy">
    <div fxLayout="column" fxLayoutAlign="center start">
      <button mat-menu-item
              [matMenuTriggerFor]="modifySubMenu">
        <mat-icon>mode_edit</mat-icon>
        <span>Modifier</span>
      </button>
      <button (click)="onDeleteEvent()"
              color="warn"
              mat-button>
        <mat-icon>delete</mat-icon>
        <span>Supprimer</span>
      </button>
    </div>
  </ng-container>

  <mat-menu #modifySubMenu="matMenu">
    <button *ngFor="let activityType of activityTypes"
            (click)="onModifyEvent(activityType)"
            mat-menu-item>
      {{ activityType.getName() }}
    </button>
  </mat-menu>
</mat-menu>

