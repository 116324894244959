<td class="mat-cell"
    colspan="100"
    (contextmenu)="onActionMenu($event)">
  {{ agent.getName() }}
</td>
<ng-container *ngFor="let week of weeks">
  <td (contextmenu)="onContextMenu($event)"
      (pointerdown)="onPointerDown($event, week)"
      (pointerover)="onPointerOver($event, week)"
      [class.selected]="selectedWeeks.has(week)"
      [style.background-color]="indisponibilitiesByWeek.has(week) ? UNAVAILABLE_DATE_COLOR : ''"
      class="mat-cell"
      colspan="10">
    {{ getActivityTypeCode(week) }}
  </td>
</ng-container>

<app-indisponibilties-menu (addLine$)="handleContextAddLine($event)"
                           (modifyEvent$)="handleActionOnEvent($event)"
                           [activityTypes]="activityTypes"
                           [indisponibilitiy]="selectedIndisponibility"
                           [contextMenuPosition]="contextMenuPosition">
</app-indisponibilties-menu>

<div style="visibility: hidden; position: fixed"
     [style.left]="contextMenuPosition?.x"
     [style.top]="contextMenuPosition?.y"
     [matMenuTriggerFor]="agentActionsMenu">
</div>
<mat-menu #agentActionsMenu="matMenu">
  <div fxLayout="column"
       fxLayoutAlign="center start">
    <button (click)="handleDeleteEvents()"
            color="warn"
            mat-button>
      <mat-icon>delete_outline</mat-icon>
      <span>Supprimer les indisponibilités</span>
    </button>
  </div>
</mat-menu>
