import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MatSelectChange} from '@angular/material/select';
import {ActivityType} from 'src/app/model/activity-type.model';
import {DayPeriod} from 'src/app/model/day.model';
import {EventActivityLine} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {formatHoursToDisplay} from 'src/app/util/date.util';
import {PlannificationUtil} from 'src/app/util/plannification.util';
import {
  ActivityContextMenuComponent
} from 'src/app/view/specific-view/planning/activity-context-menu/activity-context-menu.component';
import {WORK_SCHEDULE_SPECIAL_CODE} from 'src/constant/string.constant';
import {SiteStatus} from "../../../model/site-status.model";

@Component({
  selector: 'app-activity-table',
  templateUrl: './activity-table.component.html',
  styleUrls: ['./activity-table.component.scss'],
})
export class ActivityTableComponent implements OnInit {
  @Input()
  lines: EventActivityLine[];

  @Input()
  workSchedules: WorkSchedule[];

  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;

  @Input()
  sitesStatus: SiteStatus[];

  @Input()
  activityTypes: ActivityType[];

  @Input()
  job: Job;

  @Output()
  lines$: EventEmitter<EventActivityLine[]> = new EventEmitter<EventActivityLine[]>();

  @ViewChild(ActivityContextMenuComponent)
  activityContextMenuComponent: ActivityContextMenuComponent;

  @ViewChild('contextMenuButton')
  contextMenuButton: any;

  contextMenuPosition = {x: '0', y: '0'};

  dataSource: EventActivityLine[];
  headers: string[] = [
    'activityType',
    'workSchedule',
    'startingDate',
    'endingDate',
    'action',
  ];

  get distinctPeriod(): number {
    return new Set(this.lines.map(({period}) => period)).size;
  }

  constructor(private readonly _planificator: PlannificationUtil) {
  }

  ngOnInit(): void {
    this.dataSource = this.lines;
    this.lines$.emit(this.convertLines());
  }

  showContextMenu(): void {
    const {x, y} =
      this.contextMenuButton._elementRef.nativeElement.getBoundingClientRect();
    this.contextMenuPosition.x = x + 'px';
    this.contextMenuPosition.y = y + 'px';
    this.activityContextMenuComponent.contextMenu.openMenu();
  }

  onStartChange(value: any, line: Partial<EventActivityLine>) {
    if (value.target.value != line.workSchedule?.starting_date) {
      line.workSchedule = this.workSchedules.filter(({code, day_period}) => {
        if (!!line.startingDate && line.startingDate > '12:00') {
          return (
            code == WORK_SCHEDULE_SPECIAL_CODE &&
            day_period == DayPeriod.AFTERNOON
          );
        }

        if (!!line.endingDate && line.endingDate <= '12:00') {
          return (
            code == WORK_SCHEDULE_SPECIAL_CODE &&
            day_period == DayPeriod.MORNING
          );
        }

        return (
          code == WORK_SCHEDULE_SPECIAL_CODE && day_period == DayPeriod.NONE
        );
      })[0];
    }

    this.lines$.emit(this.convertLines());
  }

  onEndChange(value: any, line: EventActivityLine) {
    if (value.target.value != line.workSchedule?.ending_date) {
      line.workSchedule = this.workSchedules.filter(({code, day_period}) => {
        if (!!line.startingDate && line.startingDate > '12:00') {
          return (
            code == WORK_SCHEDULE_SPECIAL_CODE &&
            day_period == DayPeriod.AFTERNOON
          );
        }

        if (!!line.endingDate && line.endingDate <= '12:00') {
          return (
            code == WORK_SCHEDULE_SPECIAL_CODE &&
            day_period == DayPeriod.MORNING
          );
        }

        return (
          code == WORK_SCHEDULE_SPECIAL_CODE && day_period == DayPeriod.NONE
        );
      })[0];
    }

    this.lines$.emit(this.convertLines());
  }

  handleWorkScheduleChange(
    line: EventActivityLine,
    workScheduleChange: MatSelectChange
  ): void {
    const workSchedule: WorkSchedule = workScheduleChange.value;
    line.startingDate = workSchedule.starting_date;
    line.endingDate = workSchedule.ending_date;

    this.lines$.emit(this.convertLines());
  }

  handleActivityTypeChange(
    line: EventActivityLine,
    activityTypeChange: MatSelectChange
  ): void {
    const activityType: ActivityType = activityTypeChange.value;
    const {workSchedule, id, startingDate, endingDate, period} =
      this._planificator.createLineFromActivityTypeAndDayPeriod(
        activityType,
        line.period
      );
    line.activityType = activityType;
    line.workSchedule = workSchedule;
    line.id = id;
    line.startingDate = startingDate;
    line.endingDate = endingDate;
    line.period = period;
    this.lines$.emit(this.convertLines());
  }

  compareWorkSchedule(w1: WorkSchedule, w2: WorkSchedule): boolean {
    return w1.id === w2.id;
  }

  compareActivityType(a1: ActivityType, a2: ActivityType): boolean {
    return a1.id === a2.id;
  }

  handleAddLine(
    morningActivity: ActivityType | null,
    afternoonActivity: ActivityType | null
  ) {
    const data: EventActivityLine[] = [];

    if (!!morningActivity) {
      data.push(
        this._planificator.createLineFromActivityTypeAndDayPeriod(
          morningActivity,
          DayPeriod.MORNING
        )
      );
    }

    if (!!afternoonActivity) {
      data.push(
        this._planificator.createLineFromActivityTypeAndDayPeriod(
          afternoonActivity,
          DayPeriod.AFTERNOON
        )
      );
    }

    this.dataSource = [...this.dataSource, ...data];

    this.lines$.emit(this.convertLines());
  }

  handleAddJCLine(activity: ActivityType) {
    this.dataSource = [
      ...this.dataSource,
      this._planificator.createLineForJC(activity),
    ];

    this.lines$.emit(this.convertLines());
  }

  handleDelete(i: number) {
    this.dataSource = this.dataSource.filter((_, index) => i != index);
    this.lines$.emit(this.convertLines());
  }

  handleContextAddLine(data: {
    morning: any | null;
    afternoon: any | null;
  }): void {
    const lines: EventActivityLine[] =
      this._planificator.createLinesFromSelectedMorningAndAfternoon(
        data.morning,
        data.afternoon,
        this.job.activity_type
      );

    this.dataSource = [...this.dataSource, ...lines];
    this.lines$.emit(this.convertLines());
  }

  private convertLines(): EventActivityLine[] {
    return this.dataSource.map(
      ({
         id,
         activityType,
         workSchedule,
         period,
         startingDate,
         endingDate,
       }) => {
        return {
          id: id,
          activityType: activityType!,
          period: period!,
          workSchedule: workSchedule!,
          startingDate: formatHoursToDisplay(startingDate),
          endingDate: formatHoursToDisplay(endingDate),
        };
      }
    );
  }
}
