<ng-content></ng-content>

<ng-container *ngFor="let site of sites; first as isFirstSite; last as isLastSite">
  <ng-container *ngFor="let date of dates; first as isFirstDate; last as isLastDate">
    <td colspan="20"
        class="mat-cell site-status-valid"
        [class.left-border]="!isFirstSite && isFirstDate"
        [class.right-border]="!isLastSite && isLastDate">
      {{ sitesStatusPlanningByOperatingSite.get(site.id)?.get(date) | siteStatusPlanningNameDisplay }}
    </td>
  </ng-container>
</ng-container>
<ng-container *ngIf="!!hoursPayed">
  <td colspan="20" class="mat-cell hours-td">
    {{ hoursPayed | hsHours | numberPrecision }}
  </td>
  <td colspan="20" class="mat-cell hours-td">
    {{ hoursPayed | hnnHours | numberPrecision }}
  </td>
  <td colspan="20" class="mat-cell hours-td">
    {{ hoursPayed | hddHours | numberPrecision }}
  </td>
  <td colspan="20" class="mat-cell hours-td">
    {{ hoursPayed | TotalHours | numberPrecision }}
  </td>
</ng-container>

<ng-container *ngIf="!hoursPayed">
  <td colspan="60"></td>
</ng-container>
