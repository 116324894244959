import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {User} from 'src/app/model/user.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

export interface BankHolidayPlanningResponse {
  users_with_event_same_day: User[];
  planning: BankHolidayPlanning;
}

const BANK_HOLIDAY_PLANNING_URL = '/api/bank-holiday-planning';

@Injectable({
  providedIn: 'root',
})
export class BankHolidayPlanningService extends AbstractService<BankHolidayPlanning> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, BANK_HOLIDAY_PLANNING_URL);
  }

  public getOnYear$(year: number): Observable<BankHolidayPlanning[]> {
    this.setFetching(true);

    return this._httpClient
      .get<BankHolidayPlanning[]>(`${BANK_HOLIDAY_PLANNING_URL}/${year}`)
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
        map((data: BankHolidayPlanning[]) => data.map(this._convertData)),
        finalize(() => this.setFetching(false))
      );
  }

  public getOnYearGlobal$(year: number) {
    this.getOnYear$(year).subscribe((data) => this._globalData$.next(data));
  }

  public postOrUpdatePlanning$(
    entity: Partial<BankHolidayPlanning>
  ): Promise<BankHolidayPlanningResponse> {
    this.setFetching(true);

    const promise = !!entity.id
      ? this._httpClient.put<BankHolidayPlanningResponse>(
          `${BANK_HOLIDAY_PLANNING_URL}/${entity.id}`,
          entity
        )
      : this._httpClient
          .post<BankHolidayPlanningResponse>(BANK_HOLIDAY_PLANNING_URL, entity)
          .pipe(
            take(1),
            map((d) => this._convertBankHolidayPlanningResponseData(d)),
            finalize(() => this.setFetching(false))
          );

    return new Promise<BankHolidayPlanningResponse>((resolve, reject) => {
      return promise.subscribe({
        next(entity: BankHolidayPlanningResponse) {
          return resolve(entity);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public saveMultiple$(
    data: Partial<BankHolidayPlanning>[]
  ): Promise<BankHolidayPlanningResponse[]> {
    this.setFetching(true);

    const result = this._httpClient
      .post<BankHolidayPlanningResponse[]>(
        `${BANK_HOLIDAY_PLANNING_URL}/multiple`,
        {
          data: data,
        }
      )
      .pipe(
        take(1),
        map((data: BankHolidayPlanningResponse[]) =>
          data.map((d) => this._convertBankHolidayPlanningResponseData(d))
        ),
        finalize(() => this.setFetching(false))
      );

    return new Promise<BankHolidayPlanningResponse[]>((resolve, reject) => {
      return result.subscribe({
        next(entities: BankHolidayPlanningResponse[]) {
          return resolve(entities);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  protected _convertData({
    id,
    date,
    day,
    bank_holiday,
  }: BankHolidayPlanning): BankHolidayPlanning {
    return new BankHolidayPlanning(id, date, day, bank_holiday);
  }

  private _convertBankHolidayPlanningResponseData({
    planning,
    users_with_event_same_day,
  }: BankHolidayPlanningResponse): BankHolidayPlanningResponse {
    return {
      planning: this._convertData(planning),
      users_with_event_same_day: users_with_event_same_day.map(
        ({
          id,
          email,
          mail_perso,
          tel_perso,
          firstName,
          company_id,
          lastName,
          unique_identifier,
          sommeil,
          is_constant,
        }: User) =>
          new User(
            id,
            firstName,
            lastName,
            email,
            mail_perso,
            tel_perso,
            unique_identifier,
            is_constant,
            sommeil,
            company_id
          )
      ),
    };
  }
}
