<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">nightlight_round</mat-icon>
    <span>Astreintes</span>
  </mat-card-title>
  <div class="horizontal-delimiter"></div>

  <div fxlayout="row" fxLayoutAlign="start center" class="actions-container">
    <app-custom-search-input [search$]="search$"></app-custom-search-input>
    <button mat-raised-button color="accent" (click)="handleReinitialization()">
      <mat-icon>restart_alt</mat-icon>
      <span>Réinitialiser la sélection</span>
    </button>
    <button mat-raised-button color="primary" (click)="handleSave()">
      <mat-icon>save</mat-icon>
      <span>Sauvegarder</span>
    </button>
    <button mat-raised-button color="warn" (click)="handleDelete()">
      <mat-icon>delete_outline</mat-icon>
      <span>Supprimer</span>
    </button>

    <button
      mat-raised-button
      class="print-button"
      color="primary"
      (click)="handlePrintPdf()"
      matTooltipShowDelay="500"
      matTooltipClass="line-broken-tooltip"
      matTooltip="Imprimer l'astreinte pour l'année'"
    >
      <mat-icon>print</mat-icon>
    </button>
  </div>
  <mat-card-content *ngIf="state$ | async as state">
    <table *ngIf="!(fetching$ | async)" class="mat-elevation-z8 mat-table">
      <thead>
        <tr class="mat-header-row">
          <th class="mat-header-cell" colspan="100">
            <button mat-button (click)="handlePreviousYear()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            {{ state.yearSelected }}
            <button mat-button (click)="handleNextYear()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </th>
          <th
            *ngFor="let month of state.weeksByMonth | keyvalue: keepOrder"
            class="mat-header-cell"
            [attr.colspan]="month.value * 10"
          >
            {{ month.key }}
          </th>
        </tr>
        <tr class="mat-header-row">
          <th class="mat-header-cell" colspan="100">Semaine</th>
          <th
            *ngFor="let week of state.weekNumbers"
            class="mat-header-cell"
            colspan="10"
          >
            {{ week }}
          </th>
        </tr>

        <ng-container *ngFor="let agent of state.agents">
          <tr
            app-astreinte-agent-row
            class="mat-row"
            [agent]="agent"
            [astreintes]="state.astreintesByAgents.get(agent.id) ?? []"
            [weekNumbers]="state.weekNumbers"
          ></tr>
        </ng-container>
      </thead>
    </table>

    <mat-progress-spinner
      *ngIf="fetching$ | async"
      diameter="30"
      color="accent"
      mode="indeterminate"
    >
    </mat-progress-spinner>
  </mat-card-content>
</mat-card>
