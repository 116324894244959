import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';

export interface HeaderAndValue<T> {
  displayName: string;
  propertyName: string;
  value: (data: T) => any;
}

export interface ContextMenuAndAction<T> {
  icon?: string;
  color?: string;
  name: (item: T) => string;
  callback: (item: T) => any;
  canBeActivated: (item: T) => boolean;
}

@Component({
  selector: 'app-custom-table',
  templateUrl: './custom-table.component.html',
  styleUrls: ['./custom-table.component.scss'],
})
export class CustomTableComponent<T> implements OnInit {
  @Input()
  headersAndValues: HeaderAndValue<T>[] = [];

  @Input()
  contextMenusAndActions: ContextMenuAndAction<T>[] = [];

  @Input()
  dataSource = new MatTableDataSource<T>([]);
  headers: string[] = [];

  @ViewChild(MatSort, { static: false })
  public sort: MatSort;

  @ViewChild(MatMenuTrigger)
  public contextMenu: MatMenuTrigger;

  contextMenuPosition: { x: string; y: string } = { x: '0', y: '0' };

  constructor() {}

  ngOnInit(): void {
    this.headers = this.headersAndValues.map(
      ({ propertyName }) => propertyName
    );

    this.dataSource.sortingDataAccessor = (item: any, property: string) => {
      if (property.includes('.'))
        return property.split('.').reduce((o, i) => o[i], item);
      return item[property];
    };
  }

  public handleContextMenuClick(itemMenu: ContextMenuAndAction<T>, item: T) {
    itemMenu.callback(item);
  }

  public onContextMenu(event: MouseEvent, data: { item: T }) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = data;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }
}
