import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {HourPayed} from 'src/app/model/hour-payed.model';
import {currentWeek, currentYear} from 'src/app/util/date.util';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

const HOURS_PAYED_URL = '/api/hours-payed';

@Injectable({
  providedIn: 'root',
})
export class HoursPayedService extends AbstractService<HourPayed> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, HOURS_PAYED_URL);
  }

  public getAllOnYearAndWeek$(
    year: number = currentYear(),
    week: number = currentWeek(),
    version: number | null = null
  ): Observable<HourPayed[]> {
    this.setFetching(true);

    return this._httpClient
      .get<HourPayed[]>(
        `${HOURS_PAYED_URL}?year=${year}&week=${week}${
          !!version ? `&version=${version}` : ''
        }`
      )
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
        finalize(() => this.setFetching(false))
      );
  }

  public getAllOnYearAndWeekGlobal$(
    year: number = currentYear(),
    week: number = currentWeek(),
    version: number | null = null
  ): void {
    this.getAllOnYearAndWeek$(year, week, version)
      .pipe(take(1))
      .subscribe((hoursPayed: HourPayed[]) =>
        this._globalData$.next(hoursPayed)
      );
  }

  protected _convertData(data: HourPayed): HourPayed {
    return data;
  }
}
