import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-alert-entity-present-dialog',
  templateUrl: './alert-entity-present-dialog.component.html',
  styleUrls: ['./alert-entity-present-dialog.component.scss']
})
export class AlertEntityPresentDialogComponent {

  constructor(
    public readonly dialogRef: MatDialogRef<AlertEntityPresentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public readonly data: { title: string, message: string }
  ) { }

  public onClose(): void {
    this.dialogRef.close();
  }
}
