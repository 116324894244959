import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from 'src/app/service/auth.service';
import {HeaderService} from 'src/app/service/header.service';
import {HOME_TITLE} from 'src/constant/string.constant';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  passwordForm = new FormGroup({
    unique_identifier: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    password_confirmation: new FormControl('', Validators.required),
  });

  hidePassword = true;
  hidePasswordConfirmation = true;
  fetching = false;

  constructor(
    private readonly _headerService: HeaderService,
    private readonly _authService: AuthService,
    private readonly _router: Router,
    private readonly _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._headerService.setTitle(HOME_TITLE);
  }

  public reset() {
    this.fetching = true;
    const { unique_identifier, password, password_confirmation } =
      this.passwordForm.value;
    const token = this._route.snapshot.queryParamMap.get('token') ?? '';
    this._authService
      .resetPassword(unique_identifier, password, password_confirmation, token)
      .then((_) => this._router.navigate(['/auth/login']))
      .catch((err) => {
        if (!!err.errors) {
          Object.keys(err.errors).forEach((attribute) =>
            this.passwordForm
              .get(attribute)
              ?.setErrors({ [attribute]: err.errors[attribute][0] })
          );
        }

        this.fetching = false;
      });
  }
}
