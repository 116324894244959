<mat-form-field  appearance="fill">
  <input matInput
         placeholder="Month and Year"
         [matDatepicker]="dp"
         [formControl]="formControl">
  <mat-datepicker-toggle matSuffix
                         [for]="dp">
  </mat-datepicker-toggle>
  <mat-datepicker #dp
                  startView="multi-year"
                  (yearSelected)="chosenYearHandler($event, dp)"
                  panelClass="month-picker">
  </mat-datepicker>
</mat-form-field>
