<h1 mat-dialog-title>
  Règle{{ errors.length > 1 ? "s" : "" }} RH non respectée{{
    errors.length > 1 ? "s" : ""
  }}
  pour {{ user.getName() }}
</h1>
<div mat-dialog-content>
  <ul>
    <li *ngFor="let error of errors">{{ error }}</li>
  </ul>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <button mat-raised-button color="primary" (click)="onClose()">Fermer</button>
</div>
