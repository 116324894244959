import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {
  BankHolidayPlanningDialogComponent
} from 'src/app/dialog/bank-holiday-planning-dialog/bank-holiday-planning-dialog.component';
import {
  BankHolidayPlanningDuplicateDialogComponent
} from 'src/app/dialog/bank-holiday-planning-duplicate-dialog/bank-holiday-planning-duplicate-dialog.component';
import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {BankHoliday} from 'src/app/model/bank-holiday.model';
import {Day} from 'src/app/model/day.model';
import {BankHolidayFacadeService} from 'src/app/service/facade/bank-holiday-facade.service';
import {formatDateToDisplay} from 'src/app/util/date.util';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

@Component({
  selector: 'app-bank-holiday',
  templateUrl: './bank-holiday.component.html',
  styleUrls: ['./bank-holiday.component.scss'],
})
export class BankHolidayComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;
  public fetching$: Observable<boolean>;
  public yearSelected$: Observable<number>;
  public bankHolidays$: Observable<BankHoliday[]>;
  public daysById$: Observable<Map<number, Day>>;
  public dataSource: MatTableDataSource<BankHolidayPlanning> =
    new MatTableDataSource<BankHolidayPlanning>([]);

  doubleClicked: boolean = false;

  public headers: HeaderAndValue<BankHolidayPlanning>[] = [
    {
      displayName: 'Évenement',
      propertyName: 'name',
      value: (data: BankHolidayPlanning) => data.bank_holiday.getName(),
    },
    {
      displayName: 'Date',
      propertyName: 'date',
      value: (data: BankHolidayPlanning) => formatDateToDisplay(data.date),
    },
    {
      displayName: 'Jour',
      propertyName: 'day',
      value: (data: BankHolidayPlanning) => data.day.getName(),
    },
  ];

  public contextMenu: ContextMenuAndAction<BankHolidayPlanning>[] = [
    {
      name: (_: BankHolidayPlanning) => 'Modifier',
      callback: (item: BankHolidayPlanning) => this.handleUpdate(item),
      canBeActivated: (_: BankHolidayPlanning) => true,
      icon: 'mode_edit',
      color: 'primary',
    },
    {
      name: (_: BankHolidayPlanning) => 'Supprimer',
      callback: (item: BankHolidayPlanning) => this.handleDelete(item),
      canBeActivated: (_: BankHolidayPlanning) => true,
      icon: 'delete',
      color: 'warn',
    },
  ];

  constructor(
    private readonly _bankHolidayFacadeService: BankHolidayFacadeService,
    private readonly _dialog: MatDialog,
    private readonly _snackbar: MatSnackBar
  ) {
    this.fetching$ = this._bankHolidayFacadeService.fetching$;
    this.bankHolidays$ = this._bankHolidayFacadeService.bankHolidays$;
    this.daysById$ = this._bankHolidayFacadeService.daysById$;
    this.yearSelected$ = this._bankHolidayFacadeService.yearSelected$;
  }

  ngOnInit(): void {
    this._subscription =
      this._bankHolidayFacadeService.bankHolidayPlannings$.subscribe(
        (datas: BankHolidayPlanning[]) => {
          this._bankHolidayFacadeService.fetching = true;
          this.dataSource = new MatTableDataSource(datas);
          this._bankHolidayFacadeService.fetching = false;
        }
      );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public handleChangeYear(quantity: number): void {
    this._bankHolidayFacadeService.handleYearChanged(quantity);
    this.doubleClicked = false;
  }

  public handleNewYear(event: any): void {
    this._bankHolidayFacadeService.year = Number.parseInt(event.target.value);
    this.doubleClicked = false;
  }

  public handleUpOrDown(event: any): void {
    this._bankHolidayFacadeService.year = Number.parseInt(event.target.value);
  }

  public handleCreate(): void {
    this.handleUpdate(null);
  }

  public handleUpdate(planning: BankHolidayPlanning | null): void {
    this.bankHolidays$.pipe(take(1)).subscribe((holidays: BankHoliday[]) => {
      const dialogRef = this._dialog.open(BankHolidayPlanningDialogComponent, {
        data: {
          planning: planning,
          holidays: holidays,
        },
        height: 'auto',
        maxHeight: 'auto',
      });
      dialogRef.afterClosed().pipe(take(1)).subscribe();
    });
  }

  public handleDelete(planning: BankHolidayPlanning): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: 'Voulez-vous supprimer ce jour ?',
        description: 'Cette action est définitif !',
        validButton: 'Supprimer',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (doDeletion: boolean) => {
        if (doDeletion) {
          this._bankHolidayFacadeService
            .deleteBankHolidayPlanning(planning)
            .then((_) => {
              this._snackbar.open(
                `Le jour ${planning.getName()} a été supprimé avec succès !`,
                'Fermer',
                {
                  duration: SNACKBAR_DEFAULT_DURATION,
                }
              );
            });
        }
      });
  }

  public handleDuplicate(): void {
    forkJoin([
      this.yearSelected$.pipe(take(1)),
      this.bankHolidays$.pipe(take(1)),
    ])
      .pipe(take(1))
      .subscribe(([year, bankHolidays]) => {
        const dialogRef = this._dialog.open(
          BankHolidayPlanningDuplicateDialogComponent,
          {
            data: {
              plannings: this.dataSource.data,
              holidays: bankHolidays,
              year: `${year}`,
            },
          }
        );
      });
  }
}
