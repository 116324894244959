import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Agent} from 'src/app/model/agent.model';

@Component({
  selector: 'app-rh-errors-dialog',
  templateUrl: './rh-errors-dialog.component.html',
  styleUrls: ['./rh-errors-dialog.component.scss'],
})
export class RhErrorsDialogComponent {
  public user: Agent;
  public errors: string[];

  constructor(
    public readonly dialogRef: MatDialogRef<RhErrorsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { user: Agent; errors: string[] }
  ) {
    this.user = data.user;
    this.errors = data.errors;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
