<div fxLayout="row" fxLayoutAlign="center center" class="form-wrapper">
  <mat-form-field>
    <mat-label>{{ label }}</mat-label>
    <textarea
      matInput
      [type]="type"
      [formControl]="control"
      [errorStateMatcher]="matcher"
      [cols]="cols"
    ></textarea>
  </mat-form-field>
</div>
