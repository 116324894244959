import {Injectable} from '@angular/core';
import {MatPaginatorIntl} from '@angular/material/paginator';
import {Subject} from 'rxjs';

@Injectable()
export class CustomPaginatorIntl implements MatPaginatorIntl {
  changes = new Subject<void>();

  firstPageLabel = 'Première page';
  itemsPerPageLabel = 'Lignes par page :';
  lastPageLabel = 'Dernière page';
  nextPageLabel = 'Page précédente';
  previousPageLabel = 'Page suivante';

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return 'Pas de résultat';
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} sur ${amountPages}`;
  }
}
