import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {HOME_TITLE} from 'src/constant/string.constant';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  private _title$: BehaviorSubject<string> = new BehaviorSubject<string>(
    HOME_TITLE
  );

  public getTitle$(): BehaviorSubject<string> {
    return this._title$;
  }

  public setTitle(title: string): void {
    this._title$.next(title);
  }
}
