import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';
import {Observable} from 'rxjs';

const WORK_SCHEDULE_URL = '/api/work_schedule';

@Injectable({
  providedIn: 'root',
})
export class WorkScheduleService extends AbstractService<WorkSchedule> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, WORK_SCHEDULE_URL);
  }

  protected _convertData({
                           id,
                           name,
                           code,
                           day_period,
                           starting_date,
                           ending_date,
                           legend_color,
                           site_status_id,
                           is_constant,
                         }: WorkSchedule): WorkSchedule {
    return new WorkSchedule(
      id,
      name,
      code,
      day_period,
      starting_date,
      ending_date,
      legend_color,
      site_status_id,
      is_constant
    );
  }


  getSchedulesForAgent(agentId: number): Observable<WorkSchedule[]> {
    const url = `${WORK_SCHEDULE_URL}/agent/${agentId}`;
    return this._httpClient.get<WorkSchedule[]>(url);
  }
  
  // Method to save copied schedules
  saveCopiedSchedules(agentId: number, schedules: WorkSchedule[]): Observable<void> {
    const url = `${WORK_SCHEDULE_URL}/agent/${agentId}/copy`;
    return this._httpClient.post<void>(url, schedules);
  }

}
