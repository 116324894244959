import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {Day} from './day.model';
import {OperatingSite} from './operating-site.model';

export class SiteStatus implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public name: string,
    public is_constant: boolean
  ) {}

  public getName(): string {
    return this.name;
  }
}

export class SiteStatusPlanning implements IdentifiedModel {
  constructor(
    public id: number,
    public date: string,
    public day: Day,
    public operating_site: OperatingSite,
    public site_status: SiteStatus,
    public event_version_id: number|null
  ) {
    this.day = new Day(day.id, day.day, day.code, day.is_constant);
    this.operating_site = new OperatingSite(
      operating_site.id,
      operating_site.name,
      operating_site.is_constant,
      operating_site.operating_location?.id
    );
    this.site_status = new SiteStatus(
      site_status.id,
      site_status.name,
      site_status.is_constant
    );
  }
}
