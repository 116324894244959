import {KeyValue} from '@angular/common';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {Agent} from 'src/app/model/agent.model';
import {AstreinteFacadeService, WeekAndMonth,} from 'src/app/service/facade/astreinte-facade.service';
import {getWeeksByMonth} from 'src/app/util/astreinte.util';
import {formatDateToDisplay} from 'src/app/util/date.util';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

@Component({
  selector: 'app-astreinte',
  templateUrl: './astreinte.component.html',
  styleUrls: ['./astreinte.component.scss'],
})
export class AstreinteComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;
  private readonly _working$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly keepOrder = (
    _: KeyValue<string, number>,
    __: KeyValue<string, number>
  ) => 0;

  public search$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public fetching$: Observable<boolean>;

  public state$: Observable<any>;

  constructor(
    private readonly _facade: AstreinteFacadeService,
    private readonly _snackBar: MatSnackBar
  ) {
    this.fetching$ = combineLatest([
      this._facade.fetching$,
      this._working$,
    ]).pipe(map((data) => data[0] || data[1]));
    this.state$ = combineLatest([
      this._facade.year$,
      this._facade.weekAndMonth$.pipe(
        map((weekAndMonth: WeekAndMonth[]) => getWeeksByMonth(weekAndMonth))
      ),
      combineLatest([this._facade.agents$, this.search$]).pipe(
        map(([agents, search]) =>
          agents.filter((agent: Agent) =>
            agent
              .getName()
              .toLocaleLowerCase()
              .includes(search.toLocaleLowerCase())
          )
        )
      ),
      this._facade.astreintesByAgent$,
      this._facade.weekNumbers$,
    ]).pipe(
      tap(() => this._working$.next(true)),
      map(
        ([
          yearSelected,
          weeksByMonth,
          agents,
          astreintesByAgents,
          weekNumbers,
        ]) => ({
          yearSelected,
          weeksByMonth,
          agents,
          astreintesByAgents,
          weekNumbers,
        })
      ),
      tap(() => this._working$.next(false))
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public handleReinitialization(): void {
    this._facade.resetSelection();
  }

  public handleSave(): void {
    this._facade
      .saveSelection()
      .then((agentByWeek: { [weekNumber: string]: Agent[] }) => {
        const keys: string[] = Object.keys(agentByWeek);

        if (keys.length > 0) {
          const message: string = keys
            .map((key: string) =>
              agentByWeek[key].reduce((acc, { firstName, lastName }: Agent) => {
                if (!acc.includes(`${firstName} ${lastName}`)) {
                  return `${acc}\n\t- ${firstName} ${lastName}`;
                }
                return acc;
              }, `Jour ${formatDateToDisplay(key)} :`)
            )
            .reduce(
              (acc, next, index: number) =>
                index == 0 ? next : `${acc}\n${next}`,
              ''
            );
          this._snackBar.open(
            `Il existe des conflits d'évènements pour certains agents : \n${message}`,
            'Fermer',
            {
              panelClass: ['snackbar-white-space'],
            }
          );
        } else {
          this._snackBar.open(
            'Toutes les astreintes ont été créées avec succès !',
            'Fermer',
            { duration: SNACKBAR_DEFAULT_DURATION }
          );
        }
      })
      .catch((err) => console.log(err));
  }

  public handleDelete(): void {
    this._facade.deleteSelection();
  }

  public handlePreviousYear(): void {
    this._facade.previousYear();
  }

  public handleNextYear(): void {
    this._facade.nextYear();
  }

  public handlePrintPdf(): void {
    this._facade.printPDF();
  }
}
