import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';
import {Indisponibility} from "../../model/abscence.model";

const INDISPONIBILITIES_URL = '/api/indisponibility';

@Injectable({
  providedIn: 'root',
})
export class IndisponibilityService extends AbstractService<Indisponibility> {
  constructor(@Inject(FetchingService) _fetchingService: FetchingService,
              protected readonly _httpClient: HttpClient) {
    super(_fetchingService, _httpClient, INDISPONIBILITIES_URL);
  }

  getOnYear$(year: number): Observable<Indisponibility[]> {

    return this._httpClient.get<Indisponibility[]>(`${INDISPONIBILITIES_URL}/${year}`).pipe(
      catchError(catchErrorOnArray()),
      take(1),
      map((data: Indisponibility[]) => data.map(this._convertData)),
    );
  }

  saveMultiple$(indisponibilities: Partial<Indisponibility>[]): Observable<Indisponibility[]> {
    return this._httpClient
      .post<Indisponibility[]>(`${INDISPONIBILITIES_URL}/multiple`, {data: indisponibilities})
  }

  deleteMultiple$(indisponibilities: Partial<Indisponibility>[]): Observable<any> {
    return this._httpClient
      .delete<void>(`${INDISPONIBILITIES_URL}/multiple`, {
        body: {
          data: indisponibilities.map(({id}) => id),
        },
      })
  }

  deleteOne$(indisponibily: Indisponibility): Observable<any> {
    return this._httpClient
      .delete<void>(`${INDISPONIBILITIES_URL}/${indisponibily.id}`)
  }

  updateOne$(indisponibility: Indisponibility): Observable<any> {
    return this._httpClient
      .put<void>(`${INDISPONIBILITIES_URL}/${indisponibility.id}`, indisponibility)
  }

  getOnYearGlobal$(year: number) {
    this.getOnYear$(year).subscribe((data) => this._globalData$.next(data));
  }


  protected _convertData({agent_id, activity_type_id, id, week, year}: Indisponibility): Indisponibility {
    return new Indisponibility(id, agent_id, activity_type_id, year, week);
  }

}
