<ng-container *ngIf="!!agent">
  <td class="td-agent-name mat-cell" colspan="60">
    {{ agent.getName() }}
  </td>

  <ng-container *ngFor="let weekDate of dates">
    <ng-container *ngFor="let date of weekDate">
      <ng-container *ngIf="eventsByDayPeriod.has(date)">
        <ng-template
          #eventsByPeriod
          [ngTemplateOutlet]="eventsByPeriod"
          let-morning="morning"
          let-day="day"
          let-afternoon="afternoon"
          [ngTemplateOutletContext]="eventsByDayPeriod.get(date)!"
        >
          <ng-container *ngIf="morning.length > 0; else noMorning">
            <ng-container *ngFor="let event of morning">
              <td class="mat-cell event-data"
                  matTooltipShowDelay="500"
                  matTooltipClass="line-broken-tooltip"
                  [matTooltip]="event | agentTooltip"
                  [style.background-color]="event | agentBackgroundColor"
                  [attr.colspan]="10 / (morning.length + day.length)"
                  [class.resize-font]="event.activity_type.code.length > 2"
                  [class.has-comment]="event.comment">
                {{ event | planningConsultCell }}
              </td>
            </ng-container>
          </ng-container>
          <ng-template #noMorning>
            <td
              class="mat-cell"
              *ngIf="day.length == 0"
              [attr.colspan]="10"
            ></td>
          </ng-template>

          <ng-container *ngIf="day.length > 0">
            <ng-container *ngFor="let event of day">
              <td class="mat-cell event-data"
                  matTooltipShowDelay="500"
                  matTooltipClass="line-broken-tooltip"
                  [matTooltip]="event | agentTooltip"
                  [style.background-color]="event | agentBackgroundColor"
                  [attr.colspan]="
                  10 / (afternoon.length + day.length) +
                  10 / (morning.length + day.length)
                "
                  [class.resize-font]="event.activity_type.code.length > 2"
                  [class.has-comment]="event.comment">
                {{ event | planningConsultCell }}
              </td>
            </ng-container>
          </ng-container>

          <ng-container *ngIf="afternoon.length > 0; else noAfternoon">
            <ng-container *ngFor="let event of afternoon">
              <td class="mat-cell event-data"
                  matTooltipShowDelay="500"
                  matTooltipClass="line-broken-tooltip"
                  [matTooltip]="event | agentTooltip"
                  [style.background-color]="event | agentBackgroundColor"
                  [attr.colspan]="10 / (afternoon.length + day.length)"
                  [class.resize-font]="event.activity_type.code.length > 2"
                  [class.has-comment]="event.comment">
                {{ event | planningConsultCell }}
              </td>
            </ng-container>
          </ng-container>

          <ng-template #noAfternoon>
            <td class="mat-cell"
              *ngIf="day.length == 0"
              [attr.colspan]="10">
            </td>
          </ng-template>
        </ng-template>
      </ng-container>
      <ng-container *ngIf="!eventsByDayPeriod.has(date)">
        <td class="mat-cell" colspan="10"></td>
        <td class="mat-cell" colspan="10"></td>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-container>

<ng-container *ngIf="!agent">
  <mat-progress-spinner diameter="30" color="accent" mode="indeterminate">
  </mat-progress-spinner>
</ng-container>
