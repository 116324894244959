import {Component, EventEmitter, Input, Output, ViewChild,} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {ActivityType, ASTREINTE_ID, INDISPONIBILITY_ID,} from 'src/app/model/activity-type.model';
import {DayPeriod} from 'src/app/model/day.model';
import {Event} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {SiteStatus} from "../../../../model/site-status.model";

export enum Action {
  MODIFY,
  DELETE,
}

@Component({
  selector: 'app-activity-context-menu',
  templateUrl: './activity-context-menu.component.html',
  styleUrls: ['./activity-context-menu.component.scss'],
})
export class ActivityContextMenuComponent {
  @Input()
  workSchedules: WorkSchedule[];

  @Input()
  sitesStatus: SiteStatus[];

  @Input()
  activityTypes: ActivityType[];

  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;

  @Input()
  length: number;

  @Input()
  job: Job | null;

  @Input()
  contextMenuPosition: { x: string; y: string };

  @Output()
  singleTDClicked$: EventEmitter<{
    site: OperatingSite;
    date: string;
    day_period: DayPeriod;
    astreinte: any;
  }> = new EventEmitter();

  @Output()
  modifyEvent$: EventEmitter<{ action: Action; event: Event }> =
    new EventEmitter();

  @Output()
  addLine$: EventEmitter<{
    morning: any | null;
    afternoon: any | null;
    site: OperatingSite;
  }> = new EventEmitter();

  @Input()
  selectedCellsToBeCopied: Set<number>;

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  get astreinte(): ActivityType {
    return this.activityTypes.filter(({id}) => id == ASTREINTE_ID)[0];
  }

  constructor() {
  }

  isIndisponibility({id}: ActivityType): boolean {
    return id != ASTREINTE_ID && INDISPONIBILITY_ID.has(id);
  }

  handleSingleTDClicked(
    site: OperatingSite,
    date: string,
    day_period: DayPeriod,
    astreinte: ActivityType | WorkSchedule
  ): void {
    this.singleTDClicked$.emit({
      site,
      date,
      day_period,
      astreinte,
    });
  }

  onModifyEvent(event: Event): void {
    this.modifyEvent$.emit({action: Action.MODIFY, event: event});
  }

  onDeleteEvent(event: Event): void {
    this.modifyEvent$.emit({action: Action.DELETE, event: event});
  }

  handleAddLine(
    morning: any | null,
    afternoon: any | null,
    site: OperatingSite
  ): void {
    this.addLine$.emit({
      morning: morning,
      afternoon: afternoon,
      site: site,
    });
  }
}
