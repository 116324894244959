<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition?.x"
  [style.top]="contextMenuPosition?.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-date="date"
    let-event="event"
    let-day_period="day_period"
  >
    <ng-container *ngIf="!event">
      <div class="menu-title">
        {{ length > 0 || day_period == "0" ? "Matin" : "Après-midi" }}
      </div>
      <button
        mat-menu-item
        *ngIf="length > 0"
        [matMenuTriggerFor]="subMenu"
        [matMenuTriggerData]="{
          site: site,
          date: date,
          previous: null
        }"
      >
        /
      </button>
      <button
        mat-menu-item
        [matMenuTriggerFor]="workSchedule"
        [matMenuTriggerData]="{
          site: site,
          day_period: day_period,
          date: date
        }"
      >
        Horaires
      </button>
      <button
        mat-menu-item
        [matMenuTriggerFor]="indisponibility"
        [matMenuTriggerData]="{
          site: site,
          day_period: day_period,
          date: date
        }"
      >
        Indisponibilités
      </button>

      <ng-container *ngIf="length == 0">
        <button
          mat-menu-item
          (click)="handleSingleTDClicked(site, date, day_period, astreinte)"
        >
          Astreinte
        </button>
      </ng-container>

      <ng-container *ngIf="length > 0">
        <button
          mat-menu-item
          [matMenuTriggerFor]="subMenu"
          [matMenuTriggerData]="{
            site: site,
            date: date,
            previous: astreinte
          }"
        >
          Astreinte
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!!event && !selectedCellsToBeCopied.has(event.id)">
      <div fxLayout="column" fxLayoutAlign="center start">
        <button mat-button color="primary" (click)="onModifyEvent(event)">
          <mat-icon>mode_edit</mat-icon>
          <span>Modifier</span>
        </button>
        <button mat-button (click)="onDeleteEvent(event)" color="warn">
          <mat-icon>delete</mat-icon>
          <span>Supprimer</span>
        </button>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #subMenu="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-date="date"
    let-previous="previous"
  >
    <div class="menu-title">Après-midi</div>
    <button
      mat-menu-item
      *ngIf="!!previous"
      (click)="handleAddLine(previous, null, site)"
    >
      - /
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="workScheduleSubMenu"
      [matMenuTriggerData]="{
        site: site,
        date: date,
        previous: previous
      }"
    >
      Horaires
    </button>
    <button
      mat-menu-item
      [matMenuTriggerFor]="indisponibilitySubMenu"
      [matMenuTriggerData]="{
        site: site,
        date: date,
        previous: previous
      }"
    >
      Indisponibilités
    </button>

    <button mat-menu-item (click)="handleAddLine(previous, astreinte, site)">
      Astreinte
    </button>
  </ng-template>
</mat-menu>

<mat-menu #workSchedule="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-day_period="day_period"
    let-date="date"
  >
    <button
      mat-menu-item
      *ngIf="length == 0"
      (click)="
        handleSingleTDClicked(site, date, day_period, job!.activity_type!)
      "
    >
      Horaires par défaut
    </button>
    <button
      mat-menu-item
      *ngIf="length > 0"
      [matMenuTriggerFor]="subMenu"
      [matMenuTriggerData]="{
        site: site,
        date: date,
        previous: job?.activity_type
      }"
    >
      Horaires par défaut
    </button>
    <ng-container
      *ngFor="let schedule of ( workSchedules | workSchedulesBySiteStatus: site: sitesStatusPlanningByOperatingSite: sitesStatus: date) ">
      <ng-container *ngIf="length == 0">
        <button
          mat-menu-item
          *ngIf="day_period == schedule.day_period"
          (click)="handleSingleTDClicked(site, date, day_period, schedule)"
        >
          {{ schedule.getName() }}
        </button>
      </ng-container>
      <ng-container *ngIf="length > 0">
        <button
          mat-menu-item
          *ngIf="schedule.day_period == '0'"
          [matMenuTriggerFor]="subMenu"
          [matMenuTriggerData]="{
            site: site,
            date: date,
            previous: schedule
          }"
        >
          {{ schedule.getName() }}
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #indisponibility="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-day_period="day_period"
    let-date="date"
  >
    <ng-container *ngIf="length == 0">
      <ng-container *ngFor="let activityType of activityTypes">
        <button
          mat-menu-item
          *ngIf="isIndisponibility(activityType)"
          (click)="handleSingleTDClicked(site, date, day_period, activityType)"
        >
          {{ activityType.getName() }}
        </button>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="length > 0">
      <ng-container *ngFor="let activityType of  activityTypes">
        <button
          mat-menu-item
          *ngIf="isIndisponibility(activityType)"
          [matMenuTriggerFor]="subMenu"
          [matMenuTriggerData]="{
            site: site,
            date: date,
            previous: activityType
          }"
        >
          {{ activityType.getName() }}
        </button>
      </ng-container>
    </ng-container>
  </ng-template>
</mat-menu>

<mat-menu #workScheduleSubMenu="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-date="date"
    let-previous="previous"
  >
    <button
      mat-menu-item
      (click)="handleAddLine(previous, job?.activity_type, site)"
    >
      Horaires par défaut
    </button>
    <ng-container
      *ngFor="let schedule of ( workSchedules | workSchedulesBySiteStatus: site: sitesStatusPlanningByOperatingSite: sitesStatus: date)">
      <button
        mat-menu-item
        *ngIf="schedule.day_period == '1'"
        (click)="handleAddLine(previous, schedule, site)"
      >
        {{ schedule.getName() }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
<mat-menu #indisponibilitySubMenu="matMenu">
  <ng-template
    matMenuContent
    let-site="site"
    let-previous="previous"
    let-date="date"
  >
    <ng-container *ngFor="let activityType of activityTypes">
      <button
        mat-menu-item
        *ngIf="isIndisponibility(activityType)"
        (click)="handleAddLine(previous, activityType, site)">
        {{ activityType.getName() }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
