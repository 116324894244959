import {Injectable} from '@angular/core';
import {ActivityTypeService} from '../admin/activity-type.service';
import {WorkScheduleService} from '../admin/work-schedule.service';
import {map} from 'rxjs/operators';
import {combineLatest, Observable,} from 'rxjs';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Legend} from 'src/app/common/legend/draggable-legend/draggable-legend.component';
import {generateLegends} from "../../util/legends.util";

@Injectable({
  providedIn: 'root',
})
export class LegendsFacadeService {

  constructor(private readonly _workScheduleService: WorkScheduleService,
              private readonly _activityTypeService: ActivityTypeService) {
  }

  public get workSchedules$(): Observable<WorkSchedule[]> {
    return this._workScheduleService.globalData$;
  }

  public get activityTypes$(): Observable<ActivityType[]> {
    return this._activityTypeService.globalData$;
  }

  public get legends$(): Observable<Legend[][]> {
    return combineLatest([
      this.workSchedules$,
      this.activityTypes$
    ]).pipe(
      map(([schedules, activityTypes]) =>
        generateLegends(schedules, activityTypes)
      )
    );
  }

  initialize = (): void => {
    this._workScheduleService.getAllWithRelationsGlobal$(['*']);
    this._activityTypeService.getAllWithRelationsGlobal$(['*']);
  }
}
