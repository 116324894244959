<div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title>Renseignez votre identifiant</mat-card-title>
    <mat-card-content>
      <form fxLayout="column" fxLayoutAlign="center center">
        <mat-form-field>
          <mat-label>Entrez votre identifiant</mat-label>
          <input
            matInput
            type="text"
            placeholder="Doe01"
            [formControl]="uniqueIdentifierControl"
          />
          <mat-icon matPrefix>perm_identity</mat-icon>
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="fetching || !uniqueIdentifierControl.valid"
        (click)="sendResetPassword()"
      >
        Reset
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="fetching"
      ></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
