import {Pipe, PipeTransform} from '@angular/core';
import {DATE_DELIMITATOR} from 'src/constant/string.constant';

@Pipe({
  name: 'planningDateDisplay',
})
export class PlanningDateDisplayPipe implements PipeTransform {
  transform(date: string, delimiter: string = DATE_DELIMITATOR): string {
    const dateSplited: string[] = date.split(delimiter);

    return `${dateSplited[2]}.${dateSplited[1]}`;
  }
}
