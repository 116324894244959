<table mat-table [dataSource]="dataSource">
  <ng-container matColumnDef="activityType">
    <th mat-header-cell *matHeaderCellDef>Type d'activité</th>
    <td mat-cell *matCellDef="let element">
      <mat-select
        matNativeControl
        [compareWith]="compareActivityType"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="element.activityType"
        (selectionChange)="handleActivityTypeChange(element, $event)"
      >
        <mat-option
          *ngFor="let activityType of activityTypes"
          [value]="activityType"
        >
          {{ activityType.getName() }}
        </mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="workSchedule">
    <th mat-header-cell *matHeaderCellDef>Type d'horaire</th>
    <td mat-cell *matCellDef="let element">
      <mat-select
        matNativeControl
        [compareWith]="compareWorkSchedule"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="element.workSchedule"
        (selectionChange)="handleWorkScheduleChange(element, $event)"
      >
        <mat-option
          *ngFor="let workSchedule of workSchedules"
          [value]="workSchedule"
        >
          {{ workSchedule.getName() }} ({{
            workSchedule.day_period == "2"
              ? "journée"
              : workSchedule.day_period == "0"
                ? "matin"
                : "après-midi"
          }})
        </mat-option>
      </mat-select>
    </td>
  </ng-container>

  <ng-container matColumnDef="startingDate">
    <th mat-header-cell *matHeaderCellDef>Début</th>
    <td mat-cell *matCellDef="let element">
      <input
        type="time"
        id="startingDate"
        name="startingDate"
        [(ngModel)]="element.startingDate"
        (change)="onStartChange($event, element)"
      />
    </td>
  </ng-container>

  <ng-container matColumnDef="endingDate">
    <th mat-header-cell *matHeaderCellDef>Fin</th>
    <td mat-cell *matCellDef="let element">
      <input
        type="time"
        id="endingDate"
        name="endingDate"
        [(ngModel)]="element.endingDate"
        (change)="onEndChange($event, element)"
      />
    </td>
  </ng-container>
  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <button
        #contextMenuButton
        mat-button
        color="primary"
        (click)="showContextMenu()"
      >
        <mat-icon class="header-icon">add_outline</mat-icon>
      </button>
    </th>
    <td mat-cell *matCellDef="let element; let i = index">
      <button mat-button color="warn" (click)="handleDelete(i)">
        <mat-icon class="header-icon">delete_outline</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headers"></tr>
  <tr mat-row *matRowDef="let row; columns: headers"></tr>
</table>

<app-activity-context-menu [contextMenuPosition]="contextMenuPosition"
                           [workSchedules]="workSchedules"
                           [sitesStatus]="sitesStatus"
                           [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite"
                           [activityTypes]="activityTypes"
                           [length]="distinctPeriod"
                           [job]="job"
                           (addLine$)="handleContextAddLine($event)">
</app-activity-context-menu>
