import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Agent} from 'src/app/model/agent.model';
import {Company} from 'src/app/model/company.model';
import {Job} from 'src/app/model/job.model';
import {Service} from 'src/app/model/service.model';
import {Skill} from 'src/app/model/skill.model';
import {AgentService} from 'src/app/service/admin/agent.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-agent-creation-dialog',
  templateUrl: './agent-creation-dialog.component.html',
  styleUrls: ['./agent-creation-dialog.component.scss'],
})
export class AgentCreationDialogComponent
  extends AbstractDialogComponent<Agent>
  implements OnInit
{
  services: Service[] = [];
  //selectedRole: 'admin' | 'planificateur' | 'agent' | 'consultant' | null = null;

  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Agent>>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      agent: Agent | null;
      jobs: Job[];
      skills: Skill[];
      companies: Company[];
    },
    @Inject(AgentService) _agentService: AgentService
  ) {
    super(dialogRef, _agentService, dialogData.agent);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.inputValues['role'] = 'agent';
    this._initializeDataWhenNeeded();
    
  }

  public getDataToSend(): Partial<Agent> {
    const agent: Partial<Agent> = {
      firstName: this.inputValues.firstName,
      lastName: this.inputValues.lastName,
      email: this.inputValues.email,
      mail_perso: this.inputValues.mailPerso,
      tel_perso: this.inputValues.telPerso,
      company: this.inputValues.company,
      jobs: this.inputValues.jobs ?? [],
      skills: this.inputValues.skills ?? [],
      services: this.inputValues.services ?? [],
    /*  is_admin: !!this.inputValues.isAdmin,
      is_planificateur: !!this.inputValues.isPlanificateur,
      is_agent:!!this.inputValues.isAgent,
      is_consultant:!!this.inputValues.isConsultant,*/
      
      is_admin: this.inputValues['role'] === 'admin' ? true : false,
      is_planificateur: this.inputValues['role'] === 'planificateur' ? true : false,
      is_agent: this.inputValues['role'] === 'agent'? true : false,
      is_consultant: this.inputValues['role'] === 'consultant' ? true : false,
      
      
    };
  
    if (!!this.data?.id) {
      agent.id = this.data.id;
    }

    return agent;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public handleCompanyChange(companyId: any): void {
    if (!!companyId && this.inputValues['company'] != companyId) {
      this.inputValues['company'] = companyId;
      this.inputValues['services'] = [];

      const currentCompany: Company = this.dialogData.companies.filter(
        ({ id }) => id === companyId
      )[0];

      this._setServices(currentCompany);
    }
  }

  protected override _initializeData({
    firstName,
    lastName,
    is_admin,
    is_planificateur,
    is_agent,
    is_consultant,
    email,
    mail_perso,
    tel_perso,
    company,
    jobs,
    services,
    skills,
  }: Agent): void {
    this.inputValues['firstName'] = firstName;
    this.inputValues['lastName'] = lastName;
    this.inputValues['email'] = email;
    this.inputValues['mailPerso'] = mail_perso;
    this.inputValues['telPerso'] = tel_perso;
    this.inputValues['company'] = company!.id;
    this.inputValues['jobs'] = jobs!.map(({ id }) => id);
    this.inputValues['services'] = services!.map(({ id }) => id);
    this.inputValues['skills'] = skills!.map(({ id }) => id);
    this.inputValues['isAdmin'] = is_admin;
    this.inputValues['isPlanificateur'] = is_planificateur;
    this.inputValues['isAgent'] = is_agent;
    this.inputValues['isConsultant'] = is_consultant;

    if (is_admin) this.inputValues['role']  = 'admin';
    else if (is_planificateur) this.inputValues['role']  = 'planificateur';
    else if (is_agent) this.inputValues['role']  = 'agent';
    else if (is_consultant) this.inputValues['role']  = 'consultant';


    
    this._setServices(
      this.dialogData.companies.filter(({ id }) => id === company!.id)[0]
    );
  }

  private _setServices(company: Company): void {
    this.services =
      company.services?.map(
        ({ id, name, is_constant, company, company_id, users }: Service) =>
          new Service(id, name, is_constant, company_id, company, users)
      ) || [];
  }
}
