import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {AuthService} from 'src/app/service/auth.service';
import {HeaderService} from 'src/app/service/header.service';
import {LocalStorageService} from 'src/app/service/local-storage.service';
import {UserService} from 'src/app/service/user.service';
import {HOME_TITLE, TOKEN_STORAGE_KEY, USER_STORAGE_KEY,} from 'src/constant/string.constant';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  credentialsForm = new FormGroup({
    unique_identifier: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  hide = true;
  fetching = false;

  constructor(
    private readonly _headerService: HeaderService,
    private readonly _authService: AuthService,
    private readonly _localStorageService: LocalStorageService,
    private readonly _userService: UserService,
    private readonly _router: Router
  ) {
    this._headerService.setTitle('Se connecter');
  }

  ngOnInit(): void {}

  connect() {
    this.fetching = true;
    const { unique_identifier, password } = this.credentialsForm.value;
    this._authService
      .login(unique_identifier, password)
      .then(async (result) => {
        const { token, user } = result;
        this.fetching = false;
        this._localStorageService.set(TOKEN_STORAGE_KEY, token.plainTextToken);
        this._localStorageService.set(USER_STORAGE_KEY, JSON.stringify(user));
        this._userService.setUser();
        this._headerService.setTitle(HOME_TITLE);
        if (user.is_admin) {
          await this._router.navigate(['view/tools/planificator']);
        } else {
          await this._router.navigate(['view/planning-consult']);
        }
      })
      .catch((err) => {
        if (!!err.errors) {
          Object.keys(err.errors).forEach((attribute) =>
            this.credentialsForm
              .get(attribute)
              ?.setErrors({ credentials: err.errors[attribute][0] })
          );
        }

        this.fetching = false;
      });
  }
}
