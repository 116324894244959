import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {map, take, tap, withLatestFrom} from 'rxjs/operators';
import {
  ResourcePlanificatorDialogComponent
} from 'src/app/dialog/resource-planificator-dialog/resource-planificator-dialog.component';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Agent} from 'src/app/model/agent.model';
import {Event} from 'src/app/model/event.model';
import {HourPayed} from 'src/app/model/hour-payed.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {JOBS_ID_NOT_COUNTING_ON_ETAT_SITE, SNACKBAR_DEFAULT_DURATION,} from 'src/constant/number.constants';
import {SiteStatus} from "../../../../../model/site-status.model";

@Component({
  selector: '[app-tbody-job]',
  templateUrl: './tbody-job.component.html',
  styleUrls: ['./tbody-job.component.scss'],
})
export class TbodyJobComponent implements OnInit, OnDestroy {
  sites: OperatingSite[] = [];
  dates: string[] = [];
  workSchedules: WorkSchedule[] = [];
  types: ActivityType[] = [];
  agentsAdded: Map<number, Agent> = new Map<number, Agent>();
  expanded: boolean = false;
  etatSiteDependant = false;
  fetching$: Observable<boolean>;
  isLatestEventVersionSelected$: Observable<boolean>;
  hoursPayed$: Observable<HourPayed[]>;
  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;
  @Input()
  sitesStatus: SiteStatus[];
  @Input()
  job: Job;
  private _subscription: Subscription | null = null;

  constructor( readonly planningFacade: PlanningFacadeService,
              private readonly _dialog: MatDialog,
              private readonly _snackBar: MatSnackBar) {
    this.fetching$ = this.planningFacade.fetching$;
    this.isLatestEventVersionSelected$ = this.planningFacade.isLatestEventVersionSelected$;
  }

  @Input()
  set operatingSites(data: OperatingSite[] | null) {
    if (!!data) {
      this.sites = data;
    }
  }

  @Input()
  set allDates(data: string[] | null) {
    if (!!data) {
      this.dates = data;
    }
  }

  @Input()
  set workingSchedules(schedules: WorkSchedule[] | null) {
    if (!!schedules) {
      this.workSchedules = schedules;
    }
  }

  @Input()
  set activityTypes(types: ActivityType[] | null) {
    if (!!types) {
      this.types = types;
    }
  }

  ngOnInit(): void {
    this.hoursPayed$ = this.planningFacade.getHoursPayedForJob$(this.job);
    this.etatSiteDependant = !JOBS_ID_NOT_COUNTING_ON_ETAT_SITE.includes(
      this.job.id
    );
    this._subscription = this.planningFacade.weekSelected$.subscribe((_) => {
      this.planningFacade.fetching = true;
      this.expanded = false;
      this.agentsAdded = new Map();
    });
    this._subscription.add(
      this.planningFacade.planningsByAgent$
        .pipe(
          tap(() => {
            this.planningFacade.fetching = true;
            this.expanded = false;
          }),
          withLatestFrom(this.planningFacade.agents$)
        )
        .subscribe(([events, agents]) => {
          const agentsById: Map<number, Agent> = agents.reduce((map, agent) => {
            map.set(agent.id, agent);
            return map;
          }, new Map<number, Agent>());

          agents
            .filter(({jobs}) => jobs?.some(({id}) => this.job.id == id))
            .forEach(({id}: Agent) => {
              if (events.has(id)) {
                if (
                  !Object.values(events.get(id)!).every((events: Event[]) =>
                    events
                      .filter(({job}) => this.job.id == job.id)
                      .every(({activity_type}) =>
                        activity_type.isIndisponibilityActive()
                      )
                  )
                ) {
                  this.agentsAdded.set(id, agentsById.get(id)!);
                }
              }
            });
          this.planningFacade.fetching = false;
          this.expanded = [...this.agentsAdded.keys()].length > 0;
        })
    );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  handleAddResources(): void {
    forkJoin([
      this.planningFacade.multipleDataForResourcePlanificator$,
      this.planningFacade.getAgentsForJob$(this.job).pipe(
        take(1),
        map((agents: Agent[]) =>
          agents.filter(({id}) => !this.agentsAdded.has(id))
        )
      ),
      this.planningFacade.plannings$.pipe(
        take(1),
        map((events: Event[]) =>
          events.filter(({user: {id}}) => !this.agentsAdded.has(id))
        )
      ),
    ])
      .pipe(take(1))
      .subscribe(([[skills, companies, services, days], agents, events]) => {
        const data = this._dialog.open(ResourcePlanificatorDialogComponent, {
          data: {
            job: this.job,
            agents: agents,
            events: events,
            dates: this.dates,
            days: days,
            skills: skills,
            companies: companies,
            services: services,
          },
        });
        data.afterClosed().subscribe((data: any | null) => {
          if (!!data && Array.isArray(data) && data.length > 0) {
            this.planningFacade.fetching = true;
            data.forEach((agent) => this.agentsAdded.set(agent.id, agent));
            if (!this.expanded) {
              this.expanded = true;
            }
            this._snackBar.open(
              `${data.length} agent${data.length > 1 ? 's' : ''} ajouté${
                data.length > 1 ? 's' : ''
              } avec succès !`,
              'Fermer',
              {
                duration: SNACKBAR_DEFAULT_DURATION,
              }
            );
            this.planningFacade.fetching = false;
          }
        });
      });
  }
}
