import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {CustomDate} from 'src/app/model/custom-date.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {SiteStatus, SiteStatusPlanning,} from 'src/app/model/site-status.model';
import {SiteStatusPlanningFacadeService} from 'src/app/service/facade/site-status-planning-facade.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';

@Component({
  selector: '[app-tbody-site]',
  templateUrl: './tbody-site.component.html',
  styleUrls: ['./tbody-site.component.scss'],
})
export class TbodySiteComponent implements OnInit {
  public plannedByDate: Map<string, SiteStatusPlanning>;
  public fetching$: Observable<boolean>;
  public selected: Set<CustomDate> = new Set();

  @Input()
  public operatingSite: OperatingSite;

  @Input()
  public siteStatuses: SiteStatus[];

  @Input()
  public days: CustomDate[];

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  contextMenuPosition = {x: '0px', y: '0px'};

  constructor(private readonly _siteStatusPlanningFacade: SiteStatusPlanningFacadeService,
              private readonly _snackbar: MatSnackBar) {
    this.fetching$ = this._siteStatusPlanningFacade.fetching$;
  }

  ngOnInit(): void {
    this._siteStatusPlanningFacade
      .getSiteStatusPlanningsForOperatingSite$(this.operatingSite)
      .subscribe((planned: SiteStatusPlanning[]) => {
        this.plannedByDate = planned.reduce((acc, next: SiteStatusPlanning) => {
          acc.set(next.date, next);
          return acc;
        }, new Map());
      });
    this._siteStatusPlanningFacade.siteMultiSelected$.subscribe(
      (site: OperatingSite | null) => {
        if (!!site && site.id != this.operatingSite.id) {
          this.selected.clear();
        }
      }
    );
  }

  public onPointerOver(event: PointerEvent, date: CustomDate): void {
    event.stopImmediatePropagation();
    event.preventDefault();
    if (event.buttons == 1) {
      if (this.selected.has(date)) {
        this.selected.delete(date);
      } else {
        this.selected.add(date);
      }
      this._setSiteSelected();
    }
  }

  public onPointerDown(event: PointerEvent, date: CustomDate): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    if (event.buttons == 1) {
      if (!this.selected.has(date)) {
        this.selected.add(date);
      } else {
        this.selected.clear();
      }
      this._setSiteSelected();
    }
  }

  public onContextMenu(event: MouseEvent, item: any) {
    this._setSiteSelected();
    if (!!item.date && !this.selected.has(item.date)) {
      this.selected.add(item.date);
    }
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = item;
    this.contextMenu.menu.focusFirstItem('mouse');
    this.contextMenu.openMenu();
  }

  public handleSetSiteStatus(status: SiteStatus): void {
    this._siteStatusPlanningFacade
      .saveSiteStatusPlanning$(
        [...this.selected.values()],
        this.operatingSite,
        status
      )
      .then(() => {
        const s = this.selected.size > 1 ? 's' : '';
        this._snackbar.open(
          `${this.selected.size} état${s} ajouté${s} avec succès !`,
          'Fermer',
          {
            duration: SNACKBAR_DEFAULT_DURATION,
          }
        );
        this.selected.clear();
      })
      .catch((err) => console.log(err));
  }

  public handleChangeSiteStatus(planned: SiteStatusPlanning,
                                status: SiteStatus): void {
    this._siteStatusPlanningFacade
      .updateSiteStatusPlanning$(planned, status)
      .then(() => {
        this._snackbar.open('Site mis à jour avec succès !', 'Fermer', {
          duration: SNACKBAR_DEFAULT_DURATION,
        });
        this.selected.clear();
      })
      .catch((err) => console.log(err));
  }

  public handleDelete(planned: SiteStatusPlanning): void {
    this._siteStatusPlanningFacade
      .deleteSiteStatusPlanning$(planned)
      .then(() =>
        this._snackbar.open('État supprimé avec succès !', 'Fermer', {
          duration: SNACKBAR_DEFAULT_DURATION,
        })
      )
      .catch((err) => console.log(err));
  }

  private _setSiteSelected(): void {
    this._siteStatusPlanningFacade.siteMultiSelected = this.operatingSite;
  }
}
