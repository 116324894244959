import {Component, Input} from '@angular/core';
import {HourPayed} from 'src/app/model/hour-payed.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {SiteStatus} from "../../../../model/site-status.model";

@Component({
  selector: '[app-site-status-header-row]',
  templateUrl: './site-status-header-row.component.html',
  styleUrls: ['./site-status-header-row.component.scss'],
})
export class SiteStatusHeaderRowComponent {
  @Input()
  sites: OperatingSite[];

  @Input()
  dates: string[];

  @Input()
  hoursPayed: HourPayed[];

  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;

  constructor() {
  }
}
