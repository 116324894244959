import {Pipe, PipeTransform} from '@angular/core';
import {HourPayed} from 'src/app/model/hour-payed.model';

@Pipe({
  name: 'hnnHours',
})
export class HnnHoursPipe implements PipeTransform {
  transform(values: HourPayed[]): number {
    return values.reduce((acc, { hnn }: HourPayed) => acc + hnn, 0.0);
  }
}
