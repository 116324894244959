<mat-form-field appearance="fill"
                class="week-select">
  <mat-select [formControl]="formControl"
              (selectionChange)="onWeekChanges()">
    <mat-option *ngFor="let weekIndex of WeeksArray"
                [value]="weekIndex"
                [class.highlighted]="weekIndex === initialWeekValue">
      Semaine {{ weekIndex }}
    </mat-option>
  </mat-select>
</mat-form-field>
