<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">apartment</mat-icon>
  <span>{{
    !!this.data ? "Mise à jour de l'entreprise" : "Nouvelle entreprise"
  }}</span>
</h1>
<div mat-dialog-content>
  <form fxLayout="column" fxLayoutAlign="center center">
    <app-custom-input
      [initialValue]="inputValues['name']"
      [label]="'Nom'"
      [required]="true"
      [fetching]="fetching"
      [error]="errors['name']"
      (value$)="handleChange('name', $event)"
    ></app-custom-input>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <mat-progress-bar mode="indeterminate" *ngIf="fetching"></mat-progress-bar>
  <button *ngIf="!fetching" mat-raised-button color="warn" (click)="onClose()">
    Annuler
  </button>
  <button
    *ngIf="!fetching"
    mat-raised-button
    [color]="!!this.data ? 'primary' : 'accent'"
    (click)="onSave()"
  >
    {{ !!this.data ? "Modifier" : "Créer" }}
  </button>
</div>
