import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomDate} from 'src/app/model/custom-date.model';
import {Day} from 'src/app/model/day.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {SiteStatus, SiteStatusPlanning,} from 'src/app/model/site-status.model';
import {currentWeek, currentYear, getWeeksInYear,} from 'src/app/util/date.util';
import {DayService} from '../admin/day.service';
import {FetchingService} from '../admin/fetching.service';
import {OperatingSiteService} from '../admin/operating-site.service';
import {SiteStatusPlanningService} from '../view/site-status-planning.service';
import {SiteStatusService} from '../view/site-status.service';
import {DateService} from "../date.service";
import {DAYS_QUANTITY_FOR_SITE_STATUS_PLANNING} from "../../../constant/number.constants";

@Injectable({
  providedIn: 'root',
})
export class SiteStatusPlanningFacadeService {
  private readonly _siteMultiSelected$: BehaviorSubject<OperatingSite | null> = new BehaviorSubject<OperatingSite | null>(null);

  constructor(private readonly _fetchingService: FetchingService,
              private readonly _siteStatusService: SiteStatusService,
              private readonly _siteStatusPlanningService: SiteStatusPlanningService,
              private readonly _operatingSiteService: OperatingSiteService,
              private readonly _dayService: DayService,
              private readonly _dateService: DateService) {
    this._siteStatusService.getAllWithRelationsGlobal$(['*']);
    this._siteStatusPlanningService.getOnWeekGlobal$(currentWeek(), currentYear());
    this._operatingSiteService.getAllWithRelationsGlobal$(['*']);
    this._dayService.getAllWithRelationsGlobal$(['*']);
  }

  initialize() {
    this._dateService.daysWanted = DAYS_QUANTITY_FOR_SITE_STATUS_PLANNING;
  }

  get siteStatuses$(): Observable<SiteStatus[]> {
    return this._siteStatusService.globalData$;
  }

  get siteStatusPlannings$(): Observable<SiteStatusPlanning[]> {
    return this._siteStatusPlanningService.globalData$;
  }

  getSiteStatusPlanningsForOperatingSite$({
                                            id,
                                          }: OperatingSite): Observable<SiteStatusPlanning[]> {
    return this.siteStatusPlannings$.pipe(
      map((planned: SiteStatusPlanning[]) =>
        planned.filter(({operating_site}) => operating_site.id == id)
      )
    );
  }

  get fetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  get days$(): Observable<Day[]> {
    return this._dayService.globalData$;
  }

  get operatingSites$(): Observable<OperatingSite[]> {
    return this._operatingSiteService.globalData$;
  }

  get weekSelected$(): Observable<number> {
    return this._dateService.weekSelected$;
  }

  get yearSelected$(): Observable<number> {
    return this._dateService.yearSelected$;
  }

  get siteMultiSelected$(): Observable<OperatingSite | null> {
    return this._siteMultiSelected$;
  }

  get dates(): Date[] {
    return this._dateService.getWeekDatesOnGivenWeekNumber(
      this._dateService.weekSelected$.getValue(),
      this._dateService.yearSelected$.getValue()
    );
  }

  set siteMultiSelected(operatingSite: OperatingSite | null) {
    this._siteMultiSelected$.next(operatingSite);
  }

  nextWeek(): void {
    const currentYear = this._dateService.yearSelected$.getValue();
    const currentWeek = this._dateService.weekSelected$.getValue();
    const totalWeeksInCurrentYear: number = getWeeksInYear(currentYear);

    let newWeek = currentWeek + 3;
    let newYear = currentYear;
    if (newWeek > totalWeeksInCurrentYear) {
      newYear = currentYear + 1;
      newWeek -= totalWeeksInCurrentYear;
    }
    this._dateService.yearSelected$.next(newYear);
    this._dateService.weekSelected$.next(newWeek);
    this.searchOnWeek(newWeek, newYear);
  }

  previousWeek(): void {
    const currentYear = this._dateService.yearSelected$.getValue();
    let newWeek = this._dateService.weekSelected$.getValue() - 3;
    let newYear = currentYear;
    if (newWeek <= 0) {
      newYear = currentYear - 1;
      newWeek += getWeeksInYear(newYear);
    }
    this._dateService.yearSelected$.next(newYear);
    this._dateService.weekSelected$.next(newWeek);
    this.searchOnWeek(newWeek, newYear);
  }

  saveSiteStatusPlanning$(customDates: CustomDate[],
                          site: OperatingSite,
                          siteStatus: SiteStatus): Promise<void> {
    return this._siteStatusPlanningService.saveSiteStatusPlanningGlobal$(
      customDates,
      site,
      siteStatus
    );
  }

  updateSiteStatusPlanning$(siteStatusPlanning: SiteStatusPlanning,
                            siteStatus: SiteStatus): Promise<void> {
    return this._siteStatusPlanningService.updateSiteStatusPlanningGlobal$(
      siteStatusPlanning,
      siteStatus
    );
  }

  deleteSiteStatusPlanning$(siteStatusPlanning: SiteStatusPlanning): Promise<void> {
    return this._siteStatusPlanningService.deleteGlobal$(siteStatusPlanning);
  }

  searchOnWeek(week: number,
               year: number): void {
    this._siteStatusPlanningService.getOnWeekGlobal$(week, year);
  }
}
