import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {DayPeriod} from './day.model';

export class WorkSchedule
  implements IdentifiedModel, NamedModel, ConstantModel
{
  public constructor(
    public id: number,
    public name: string,
    public code: string,
    public day_period: DayPeriod,
    public starting_date: string,
    public ending_date: string,
    public legend_color: string,
    public site_status_id: number,
    public is_constant: boolean
  ) {}

  getName(): string {
    return this.name;
  }
}
