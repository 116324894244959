import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {BankHoliday} from 'src/app/model/bank-holiday.model';
import {Day} from 'src/app/model/day.model';
import {User} from 'src/app/model/user.model';
import {BankHolidayFacadeService} from 'src/app/service/facade/bank-holiday-facade.service';
import {AbstractBankHolidayDialogDirective} from '../abstract-bank-holiday-dialog.directive';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-bank-holiday-planning-dialog',
  templateUrl: './bank-holiday-planning-dialog.component.html',
  styleUrls: ['./bank-holiday-planning-dialog.component.scss'],
})
export class BankHolidayPlanningDialogComponent
  extends AbstractBankHolidayDialogDirective
  implements OnInit, OnDestroy
{
  private _subscription: Subscription | null = null;
  fetching = false;
  errors: { [any: string]: string } = {};

  inputValues: { [any: string]: any } = {};

  constructor(
    protected readonly _dialogRef: MatDialogRef<
      AbstractDialogComponent<BankHolidayPlanning>
    >,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      planning: BankHolidayPlanning | null;
      holidays: BankHoliday[];
    },
    private readonly _facade: BankHolidayFacadeService,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_dialogRef, _snackBar);
  }

  ngOnInit(): void {
    this._subscription = this._facade.fetching$.subscribe(
      (isFetching: boolean) => (this.fetching = isFetching)
    );
    if (!!this.dialogData.planning) {
      this._initializeData(this.dialogData.planning);
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public handleChange(attribute: string, value: any): void {
    this.inputValues[attribute] = value;
  }

  public onSave(): void {
    this._facade
      .getDayForGivenDate$(this.inputValues['date'])
      .pipe(take(1))
      .subscribe((day: Day) => {
        const data: Partial<BankHolidayPlanning> = {
          date: this.inputValues['date'],
          bank_holiday_id: this.dialogData.holidays.filter(
            ({ id }) => `${id}` == this.inputValues['bankHoliday']
          )[0]?.id,
          day_id: day?.id,
          id: this.dialogData.planning?.id,
        };

        this._facade
          .saveOrUpdateBankHolidayPlanning(data)
          .then((users: User[]) => {
            this._handleUserWithEventResponse(users);
          })
          .catch((err) => {
            if (!!err && !!err.errors) {
              Object.keys(err.errors).forEach(
                (attribute: string) =>
                  (this.errors[attribute] = err.errors[attribute][0])
              );
            }
          });
      });
  }

  public onClose(): void {
    this._dialogRef.close();
  }

  protected _initializeData(data: BankHolidayPlanning): void {
    this.inputValues['date'] = data.date;
    this.inputValues['bankHoliday'] = data.bank_holiday.id;
  }
}
