import {Pipe, PipeTransform} from '@angular/core';
import {Event} from 'src/app/model/event.model';
import {SUNDAY_DAY_NUMBER, UNAVAILABLE_DATE_COLOR,} from 'src/constant/string.constant';
import {INDISPONIBILITY_ID} from "../../model/activity-type.model";

// TODO jours fériés
@Pipe({
  name: 'agentBackgroundColor',
})
export class AgentBackgroundColorPipe implements PipeTransform {
  transform({
              day: {day},
              activity_type: {id},
              work_schedule: {legend_color},
            }: Event): string {
    if (INDISPONIBILITY_ID.has(id)) {
      return UNAVAILABLE_DATE_COLOR;
    }

    if (`${day}` == `${SUNDAY_DAY_NUMBER}`) {
      return UNAVAILABLE_DATE_COLOR;
    }

    return legend_color;
  }
}
