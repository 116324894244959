import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Agent} from 'src/app/model/agent.model';
import {Astreinte} from 'src/app/model/astreinte.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

export interface AstreinteResponse {
  user_with_event_during_astreinte: { [date: string]: Agent[] };
  astreinte: Astreinte;
}

const ASTREINTE_URL = '/api/astreinte';

@Injectable({
  providedIn: 'root',
})
export class AstreinteService extends AbstractService<Astreinte> {
  constructor(@Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient) {
    super(_fetchingService, _httpClient, ASTREINTE_URL);
  }

  public getOnYear$(year: number): Observable<Astreinte[]> {
    this.setFetching(true);

    return this._httpClient.get<Astreinte[]>(`${ASTREINTE_URL}/${year}`).pipe(
      catchError(catchErrorOnArray()),
      take(1),
      map((data: Astreinte[]) => data.map(this._convertData)),
      finalize(() => this.setFetching(false))
    );
  }

  public postAstreinte$(
    entity: Partial<Astreinte>
  ): Promise<AstreinteResponse> {
    this.setFetching(true);

    const promise = this._httpClient
      .post<AstreinteResponse>(ASTREINTE_URL, entity)
      .pipe(
        take(1),
        map((d) => this._convertAstreinteResponseData(d)),
        finalize(() => this.setFetching(false))
      );

    return new Promise<AstreinteResponse>((resolve, reject) => {
      return promise.subscribe({
        next(entity: AstreinteResponse) {
          return resolve(entity);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public saveMultiple$(
    data: Partial<Astreinte>[]
  ): Promise<AstreinteResponse[]> {
    this.setFetching(true);

    const result = this._httpClient
      .post<AstreinteResponse[]>(`${ASTREINTE_URL}/multiple`, {
        data: data,
      })
      .pipe(
        take(1),
        map((data: AstreinteResponse[]) =>
          data.map((d) => this._convertAstreinteResponseData(d))
        ),
        finalize(() => this.setFetching(false))
      );

    return new Promise<AstreinteResponse[]>((resolve, reject) => {
      return result.subscribe({
        next(entities: AstreinteResponse[]) {
          return resolve(entities);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public getOnYearGlobal$(year: number) {
    this.getOnYear$(year).subscribe((data) => this._globalData$.next(data));
  }

  public deleteMultiple$(astreintes: Astreinte[]): Promise<void> {
    this.setFetching(true);

    const result = this._httpClient
      .delete<void>(`${ASTREINTE_URL}/multiple`, {
        body: {
          data: astreintes.map(({ id }) => id),
        },
      })
      .pipe(
        take(1),
        finalize(() => this.setFetching(false))
      );

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next() {
          return resolve();
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public deleteMultipleGlobal$(astreintes: Astreinte[]): void {
    this.deleteMultiple$(astreintes)
      .then(() => {
        const ids: number[] = astreintes.map(({ id }) => id);
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next(d.filter(({ id }) => !ids.includes(id)));
        });
      })
      .catch((err) => console.log(err));
  }

  protected _convertData({ agent_id, id, week, year }: Astreinte): Astreinte {
    return new Astreinte(id, agent_id, year, week);
  }

  private _convertAstreinteResponseData({
    astreinte,
    user_with_event_during_astreinte,
  }: AstreinteResponse): AstreinteResponse {
    return {
      astreinte: new Astreinte(
        astreinte.id,
        astreinte.agent_id,
        astreinte.year,
        astreinte.week
      ),
      user_with_event_during_astreinte: user_with_event_during_astreinte,
    };
  }
}
