import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {OperatingLocation} from 'src/app/model/operating-location.model';

import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const OPERATING_LOCATION_URL: string = '/api/operating-location';

@Injectable({
  providedIn: 'root',
})
export class OperatingLocationService extends AbstractService<OperatingLocation> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, OPERATING_LOCATION_URL);
  }

  public findAll$(): Observable<OperatingLocation[]> {
    this.setFetching(true);

    return this._httpClient
      .get<OperatingLocation[]>(
        `${OPERATING_LOCATION_URL}?relations[]=operatingSites`
      )
      .pipe(
        catchError(catchErrorOnArray()),
        map((data: OperatingLocation[]) => {
          return data.map(this._convertData);
        }),
        take(1),
        finalize(() => this.setFetching(false))
      );
  }

  protected override _convertData({
    id,
    is_constant,
    name,
    operating_sites,
  }: OperatingLocation): OperatingLocation {
    return new OperatingLocation(id, name, operating_sites, is_constant);
  }
}
