<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">date_range</mat-icon>
  <span>{{ isModify ? "Mise à jour" : "Nouvel événement" }}</span>
</h1>
<div mat-dialog-content>
  <form fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <div fxFlex="25%" class="input-container">
        <p><span>Période : </span>{{ period }}</p>
        <p><span>Site : </span>{{ site }}</p>
        <mat-divider></mat-divider>
        <h2>Ressource</h2>
        <p><span>Identification : </span>{{ identification }}</p>
        <p><span>Société : </span>{{ company }}</p>
        <p><span>Compétences : </span>{{ skills }}</p>
        <p><span>Service : </span>{{ services }}</p>
      </div>
      <div fxFlex="65%" class="input-container">
        <h2>Activités</h2>
        <app-activity-table [job]="dialogData.job"
                            [lines]="lines"
                            [workSchedules]="workSchedules"
                            [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite"
                            [sitesStatus]="sitesStatus"
                            [activityTypes]="activityTypes"
                            (lines$)="handleLinesSend($event)">
        </app-activity-table>
        <p class="is-error" *ngIf="!!errors['events']">
          {{ errors["events"] }}
        </p>
        <app-custom-textarea [initialValue]="comment"
                             [label]="'Commentaire'"
                             [required]="false"
                             [error]="errors['comment']"
                             (value$)="handleCommentchange($event)"
        ></app-custom-textarea>
        <mat-error *ngIf="!!errors['comment']">{{
            errors["comment"]
          }}
        </mat-error>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <button mat-raised-button color="warn" (click)="onClose()">
    Annuler
  </button>
  <button mat-raised-button
          [color]="isModify ? 'primary' : 'accent'"
          (click)="onSave()">
    {{ isModify ? "Modifier" : "Créer" }}
  </button>
</div>
