import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberPrecision',
})
export class NumberPrecisionPipe implements PipeTransform {
  transform(value: number): string {
    return value.toLocaleString('fr-FR', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
}
