import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {SiteStatus} from 'src/app/model/site-status.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

const SITE_STATUS_URL = '/api/site-status';

@Injectable({
  providedIn: 'root',
})
export class SiteStatusService extends AbstractService<SiteStatus> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, SITE_STATUS_URL);
  }

  protected _convertData({
    id,
    name,
    is_constant,
  }: SiteStatus): SiteStatus {
    return new SiteStatus(id, name, is_constant);
  }
}
