import {Day} from './day.model';

export class CustomDate {
  constructor(public date: Date,
              public day: Day) {
  }

  public get monthName(): string {
    const month: string = this.date.toLocaleDateString('fr-Fr', {
      month: 'long',
      timeZone: 'America/Cayenne',
    });
    return month.charAt(0).toUpperCase() + month.slice(1);
  }

  public get year(): number {
    return this.date.getFullYear();
  }

  public get dateFormated(): string {
    return `${this.date.getFullYear()}-${
      (this.date.getMonth() + 1)  > 9 ? '' : '0'
    }${this.date.getMonth() + 1 }-${
      this.date.getDate() > 9 ? '' : '0'
    }${this.date.getDate()}`;
  }
}
