import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Job} from 'src/app/model/job.model';
import {JobService} from 'src/app/service/admin/job.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-job-creation-dialog',
  templateUrl: './job-creation-dialog.component.html',
  styleUrls: ['./job-creation-dialog.component.scss'],
})
export class JobCreationDialogComponent
  extends AbstractDialogComponent<Job>
  implements OnInit
{
  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Job>>,
    private readonly _jobService: JobService,
    @Inject(MAT_DIALOG_DATA) data: Job | null
  ) {
    super(dialogRef, _jobService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<Job> {
    const job: Partial<Job> = {
      name: this.inputValues.name,
    };

    if (!!this.data?.id) {
      job.id = this.data!.id;
    }

    return job;
  }

  protected override _initializeData({ name }: Job): void {
    this.inputValues['name'] = name;
  }
}
