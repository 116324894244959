import {Pipe, PipeTransform} from '@angular/core';
import {OperatingSite} from "../model/operating-site.model";
import {SiteStatus} from "../model/site-status.model";
import {WorkSchedule} from "../model/work-schedule.model";
import {CSG_SITE_STATUS_NAME, SHIFT_CODES} from "../../constant/string.constant";

@Pipe({
  name: 'workSchedulesBySiteStatus',
})
export class WorkSchedulesBySiteStatusPipe implements PipeTransform {
  transform(worksSchedules: WorkSchedule[],
            operatingSite: OperatingSite,
            sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>,
            sitesStatus: SiteStatus[],
            date: string): WorkSchedule[] {
    const siteStatus: SiteStatus | undefined = sitesStatusPlanningByOperatingSite.get(operatingSite.id)?.get(date);
    const CsgSiteStatus: SiteStatus | undefined = sitesStatus.find(_ =>_.name === CSG_SITE_STATUS_NAME)
    const notShiftWorksSchedules: WorkSchedule[] = worksSchedules.filter(workSchedule => !SHIFT_CODES.includes(workSchedule.code));
    const siteStatusIdToFilterBy = !siteStatus ? CsgSiteStatus?.id:  siteStatus.id
    const shiftWorksSchedules: WorkSchedule[] = worksSchedules.filter(workSchedule => SHIFT_CODES.includes(workSchedule.code) &&
      workSchedule.site_status_id === siteStatusIdToFilterBy);
    return [...notShiftWorksSchedules, ...shiftWorksSchedules];
  }
}
