<div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title
      >Renseignez votre identifiant et mot de passe</mat-card-title
    >
    <mat-card-content>
      <form
        [formGroup]="credentialsForm"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-form-field>
          <mat-label>Entrez votre identifiant</mat-label>
          <input
            matInput
            type="text"
            placeholder="Doe01"
            formControlName="unique_identifier"
          />
          <mat-icon matPrefix>perm_identity</mat-icon>
          <mat-error
            *ngIf="
              credentialsForm.get('unique_identifier')!.hasError('credentials')
            "
            >{{
              credentialsForm.get("unique_identifier")!.getError("credentials")
            }}</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Entrez votre mot de passe</mat-label>
          <input
            matInput
            [type]="hide ? 'password' : 'text'"
            formControlName="password"
            (keyup.enter)="connect()"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hide = !hide"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hide"
          >
            <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
          </button>
          <mat-error
            *ngIf="credentialsForm.get('password')!.hasError('credentials')"
            >{{
              credentialsForm.get("password")!.getError("credentials")
            }}</mat-error
          >
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="column" fxLayoutAlign="center center">
      <ng-container *ngIf="!fetching">
        <button
          mat-stroked-button
          color="primary"
          [disabled]="!credentialsForm.valid || fetching"
          (click)="connect()"
        >
          Se connecter
        </button>
        <button
          mat-button
          color="accent"
          [routerLink]="'/auth/forgot-password'"
        >
          Mot de passe oublié
        </button>
      </ng-container>

      <ng-container *ngIf="fetching">
        <mat-progress-spinner
          diameter="30"
          color="primary"
          mode="indeterminate"
        >
        </mat-progress-spinner>
      </ng-container>
    </mat-card-actions>
  </mat-card>
</div>
