<mat-card>
    <mat-card-title>Modifier le modèle d'email</mat-card-title>
    <mat-card-content>
      <form (ngSubmit)="onSave()" #emailForm="ngForm" class="email-form">
        <div class="form-grid">
          <!-- Left Column -->
          <div class="form-left">
            <!-- Email -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]="email.email" name="email" required>
            </mat-form-field>
  
            <!-- Description -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Description</mat-label>
              <input matInput [(ngModel)]="email.description" name="description" required>
            </mat-form-field>
  
            <!-- Subject -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Sujet</mat-label>
              <input matInput [(ngModel)]="email.subject" name="subject" required>
            </mat-form-field>
  
            <!-- To (Recipient) -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Destinataire</mat-label>
              <input matInput [(ngModel)]="email.to" name="to" required>
            </mat-form-field>
  
            <!-- Key -->
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>Clé</mat-label>
              <input matInput [(ngModel)]="email.key" name="key">
            </mat-form-field>

            <!-- SMS Content -->
            <mat-form-field appearance="fill" class="full-width">
                <mat-label>Contenu SMS</mat-label>
                <textarea matInput [(ngModel)]="email.sms_content" name="sms_content"></textarea>
              </mat-form-field>
    
              <!-- Send Method -->
              <mat-form-field appearance="fill" class="full-width">
                <mat-label>Méthode d'envoi</mat-label>
                <mat-select [(ngModel)]="email.send_method" name="send_method">
                  <mat-option [value]="1">Email</mat-option>
                  <mat-option [value]="2">SMS</mat-option>
                </mat-select>
              </mat-form-field>
              
          </div>
  
          <!-- Right Column -->
          <div class="form-right">
            <!-- Template -->
            <div class="editor-container">
              <editor
                apiKey="1t28m348e0zhhjchmdllxqimj7aqdeizkx4dkhlmodlhqyuk"
                [(ngModel)]="email.template"
                [init]="{
                    height: 500,
                    menubar: false,
                    plugins: [
                    'advlist autolink lists link image charmap print preview anchor',
                    'searchreplace visualblocks code fullscreen',
                    'insertdatetime media table paste code help wordcount'
                    ],
                    toolbar: 'undo redo | formatselect | bold italic backcolor | \
                    alignleft aligncenter alignright alignjustify | \
                    bullist numlist outdent indent | removeformat | help',
                    apiKey: API_KEY
                }"
                name="template"
              >
              </editor>
            </div>
  
            
          </div>
        </div>
  
        <!-- Toggle Options -->
        <div class="form-toggles">
          <mat-slide-toggle [(ngModel)]="email.send_to_superadmin" name="send_to_superadmin">Envoyer au Superadmin?</mat-slide-toggle>
          <mat-slide-toggle [(ngModel)]="email.phone_verification" name="phone_verification">Vérification téléphonique?</mat-slide-toggle>
          <mat-slide-toggle [(ngModel)]="email.sms_response" name="sms_response">Réponse SMS?</mat-slide-toggle>
          <mat-slide-toggle [(ngModel)]="email.sms_type" name="sms_type">Type de SMS?</mat-slide-toggle>
          <mat-slide-toggle [(ngModel)]="email.status" name="status">Actif?</mat-slide-toggle>
        </div>
  
        <!-- Submit Button -->
        <div class="form-actions">
          <button mat-raised-button color="primary" type="submit">Enregistrer</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
  