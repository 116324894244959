import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OperatingLocation} from 'src/app/model/operating-location.model';
import {OperatingLocationService} from 'src/app/service/admin/operating-location.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-operating-location-creation-dialog',
  templateUrl: './operating-location-creation-dialog.component.html',
  styleUrls: ['./operating-location-creation-dialog.component.scss'],
})
export class OperatingLocationCreationDialogComponent
  extends AbstractDialogComponent<OperatingLocation>
  implements OnInit
{
  constructor(
    public readonly dialogRef: MatDialogRef<
      AbstractDialogComponent<OperatingLocation>
    >,
    protected readonly _operatingLocationService: OperatingLocationService,
    @Inject(MAT_DIALOG_DATA) data: OperatingLocation | null
  ) {
    super(dialogRef, _operatingLocationService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<OperatingLocation> {
    const operatingLocation: Partial<OperatingLocation> = {
      name: this.inputValues['name'],
    };

    if (!!this.data?.id) {
      operatingLocation.id = this.data.id;
    }

    return operatingLocation;
  }

  protected override _initializeData({ name }: OperatingLocation): void {
    this.inputValues['name'] = name;
  }
}
