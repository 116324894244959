import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ExcelExportService} from 'src/app/service/admin/excel-export.service';
import {FetchingService} from 'src/app/service/admin/fetching.service';
import {currentMonth, currentYear} from 'src/app/util/date.util';
import {ExcelExport} from 'src/constant/enum.contants';

@Component({
  selector: 'app-export-excel-dialog',
  templateUrl: './export-excel-dialog.component.html',
  styleUrls: ['./export-excel-dialog.component.scss'],
})
export class ExportExcelDialogComponent implements OnInit {
  private readonly _work$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public title: string = '';
  public icon: string | null = null;
  public year: number = currentYear();
  public month: number = currentMonth();

  public working$: Observable<boolean>;

  constructor(
    private readonly _fetchingService: FetchingService,
    private readonly _excelExportService: ExcelExportService,
    private readonly _dialogRef: MatDialogRef<ExportExcelDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      type: string;
    }
  ) {
    this.working$ = combineLatest([
      this._fetchingService.fetching$,
      this._work$,
    ]).pipe(map((data) => data[0] || data[1]));
    this._initialize();
  }

  ngOnInit(): void {}

  private _initialize(): void {
    switch (this.dialogData.type) {
      case ExcelExport.INCREASED_HOURS_ACCUMULATION: {
        this.title = 'Cumul des heures majorées';
        this.icon = 'access_time_filled';
        break;
      }

      case ExcelExport.POSTED_HOURS: {
        this.title = 'État des jours postés';
        this.icon = 'calendar_view_week';
        break;
      }

      case ExcelExport.TEMP_WORKER_EQUITY: {
        this.title = 'Équité intérimaire';
        this.icon = 'group';
        break;
      }
    }
  }

  public onClose(): void {
    this._dialogRef.close();
  }

  public async handleExport() {
    this._work$.next(true);
    try {
      switch (this.dialogData.type) {
        case ExcelExport.INCREASED_HOURS_ACCUMULATION: {
          await this._excelExportService.getIncreasedHoursAccumulation$(
            this.year,
            this.month
          );
          break;
        }

        case ExcelExport.POSTED_HOURS: {
          await this._excelExportService.getPostedHours$(this.year);
          break;
        }

        case ExcelExport.TEMP_WORKER_EQUITY: {
          await this._excelExportService.getTempWorkerEquity$(this.year);
          break;
        }
      }

      this._dialogRef.close();
    } catch (err: any) {
      console.log(err);
    } finally {
      this._work$.next(false);
    }
  }
}
