import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FetchingService {
  private _fetching$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  public get fetching$(): Observable<boolean> {
    return this._fetching$;
  }

  public set fetching(value: boolean) {
    this._fetching$.next(value);
  }
}
