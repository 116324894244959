import {Component, EventEmitter, Input, Output} from '@angular/core';

export interface Legend {
  color: string;
  name: string;
}

@Component({
  selector: 'app-draggable-legend',
  templateUrl: './draggable-legend.component.html',
  styleUrls: ['./draggable-legend.component.scss'],
})
export class DraggableLegendComponent {
  @Input()
  public legends: Legend[][];

  @Input()
  public titles: string[];

  @Input()
  public boundaryLimit: string;

  @Output()
  public closeWanted$: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  public handleClose(): void {
    this.closeWanted$.emit(true);
  }
}
