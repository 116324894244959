<h1 mat-dialog-title>Affecter / dé-affecter des agents</h1>
<div mat-dialog-content>
  <app-drag-drop-two-tables
    [availableItems]="data.agentAvailable"
    [assignedItems]="data.agentAssigned"
    [disabledDeassigned]="true"
    (itemDragged$)="onChanged($event)"
  >
  </app-drag-drop-two-tables>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <button mat-button color="warn" (click)="onClose()">Annuler</button>
  <button mat-button color="primary" (click)="onSave()">Modifier</button>
</div>
