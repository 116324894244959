import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { Agent } from 'src/app/model/agent.model';
import { Company } from 'src/app/model/company.model';
import { Event } from 'src/app/model/event.model';
import { Job } from 'src/app/model/job.model';
import { Service } from 'src/app/model/service.model';
import { Skill } from 'src/app/model/skill.model';
import { PlannificationUtil } from 'src/app/util/plannification.util';

@Component({
  selector: 'app-resource-planificator-dialog',
  templateUrl: './resource-planificator-dialog.component.html',
  styleUrls: ['./resource-planificator-dialog.component.scss'],
})
export class ResourcePlanificatorDialogComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;
  private _companySubscription: Subscription | null = null;
  public servicesAvailables: Service[] = [];
  public eventsByAgentByDate: Map<number, Map<string, Event[]>> = new Map();

  public selection: SelectionModel<Agent> = new SelectionModel<Agent>(true, []);
  public dataSource: MatTableDataSource<Agent>;

  public filterGroup: FormGroup = new FormGroup({
    searchControl: new FormControl(''),
    skillsControl: new FormControl(),
    companiesControl: new FormControl(),
    servicesControl: new FormControl(),
  });

  public get daysColumns(): string[] {
    return ['select', 'name', ...this.data.days];
  }

  constructor(
    private readonly _plannificator: PlannificationUtil,
    private readonly _dialogRef: MatDialogRef<ResourcePlanificatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      job: Job;
      events: Event[];
      dates: string[];
      companies: Company[];
      services: Service[];
      skills: Skill[];
      days: string[];
      agents: Agent[];
    }
  ) {
    console.log('Dialog Data:', this.data);

    // Initialize eventsByAgentByDate pour tous les agents
    this.data.agents.forEach(agent => {
      if (!this.eventsByAgentByDate.has(agent.id)) {
        this.eventsByAgentByDate.set(agent.id, new Map());
      }
    });

    this.data.events.forEach((event: Event) => {
      const agentId = event.user.id;
      const eventDate = event.date;

      console.log('Processing Event for User ID:', agentId);
      console.log('Event Date:', eventDate);

      if (this.eventsByAgentByDate.has(agentId)) {
        if (!this.eventsByAgentByDate.get(agentId)?.has(eventDate)) {
          this.eventsByAgentByDate.get(agentId)!.set(eventDate, [event]);
          console.log(`Added event for Agent ID ${agentId} on Date ${eventDate}`);
        } else {
          this.eventsByAgentByDate.get(agentId)!.get(eventDate)!.push(event);
          console.log(`Updated events for Agent ID ${agentId} on Date ${eventDate}`);
        }
      } else {
        console.log(`Agent ID ${agentId} not found in eventsByAgentByDate`);
      }
    });

    console.log('Events By Agent By Date:', this.eventsByAgentByDate);

    this.filterGroup.get('skillsControl')!.setValue(this.data.skills);
    this.dataSource = new MatTableDataSource(this.data.agents);
    this._subscription = this.filterGroup.valueChanges.subscribe(
      (data: {
        searchControl: string | null;
        skillsControl: Skill[] | null;
        companiesControl: Company | null;
        servicesControl: Service | null;
      }) => {
        this.selection.clear();
        const agentFiltered: Agent[] = this.data.agents.filter(
          (agent: Agent) => {
            return (
              !data.skillsControl ||
              (!!agent.skills &&
                agent.skills!.some(({ id }) =>
                  data.skillsControl!.some((skill) => skill.id == id)
                ) &&
                (!data.companiesControl ||
                  (agent.company?.id == data.companiesControl!.id &&
                    (!data.servicesControl ||
                      agent.services?.some(
                        (service: Service) =>
                          service.id == data.servicesControl?.id
                      )))))
            );
          }
        );
        this.dataSource.data = agentFiltered;
        this.dataSource.filter = data.searchControl ?? '';
        this.dataSource.filterPredicate = (agent: Agent, search: string) =>
          agent
            .getName()
            .toLocaleLowerCase()
            .includes(search.toLocaleLowerCase());
      }
    );
  }

  ngOnInit(): void {
    console.log('Events By Agent By Date:', this.eventsByAgentByDate);
    console.log('Filtered Events:', this.data.events);

    this._companySubscription = this.filterGroup
      .get('companiesControl')!
      .valueChanges.subscribe((company: Company | null) => {
        this.filterGroup.get('servicesControl')!.setValue(null);
        this.servicesAvailables = !!company
          ? this.data.services.filter(
              (service) => service.company_id == company.id
            )
          : [];
      });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this._companySubscription?.unsubscribe();
  }

  public getEventByPeriod(events: Event[]): {
    morning: Event[];
    day: Event[];
    afternoon: Event[];
  } {
    return this._plannificator.getEventByPeriod(events);
  }

  public isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  public masterToggle(): void {
    if (this.isAllSelected()) {
      this.selection.clear();
    } else {
      this.selection.select(...this.dataSource.data);
    }
  }

  public onClose(): void {
    this._dialogRef.close(this.selection.selected ?? []);
  }

  public onCancel(): void {
    this._dialogRef.close();
  }
}
