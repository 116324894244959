import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {BankHoliday} from 'src/app/model/bank-holiday.model';
import {Day} from 'src/app/model/day.model';
import {User} from 'src/app/model/user.model';
import {BankHolidayFacadeService} from 'src/app/service/facade/bank-holiday-facade.service';
import {AbstractBankHolidayDialogDirective} from '../abstract-bank-holiday-dialog.directive';
import {DateService} from "../../service/date.service";

@Component({
  selector: 'app-bank-holiday-planning-duplicate-dialog',
  templateUrl: './bank-holiday-planning-duplicate-dialog.component.html',
  styleUrls: ['./bank-holiday-planning-duplicate-dialog.component.scss'],
})
export class BankHolidayPlanningDuplicateDialogComponent extends AbstractBankHolidayDialogDirective implements OnInit {
  public yearSelected: string;
  public lines: { id: number; date: string }[];
  public errors: Map<number, string> = new Map();

  public fetching: boolean = false;
  public yearsAvailables: string[];

  constructor(protected readonly _dialogRef: MatDialogRef<BankHolidayPlanningDuplicateDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public dialogData: {
                plannings: BankHolidayPlanning[];
                holidays: BankHoliday[];
                year: string;
              },
              private readonly _facade: BankHolidayFacadeService,
              protected readonly _snackBar: MatSnackBar,
              protected readonly _dateService: DateService
  ) {
    super(_dialogRef, _snackBar);
    this.yearSelected = this.dialogData.year;
  }

  ngOnInit(): void {
    this._facade.fetching$.subscribe(
      (isFetching: boolean) => (this.fetching = isFetching)
    );
    this.lines = this.dialogData.plannings.map(({bank_holiday, date}) => {
      return {
        id: bank_holiday.id,
        date: date,
      };
    });
    this.yearsAvailables = new Array(20)
      .fill(this.dialogData.year)
      .map((year: string, index: number) => `${Number.parseInt(year) + index}`);
  }

  public handleIdChange(index: number, data: any): void {
    this.lines[index].id = data;
  }

  public handleDateChange(index: number, data: any): void {
    this.lines[index].date = data;
  }

  public handleYearChange(_: any): void {
    this.lines = this.lines.map(({id, date}) => {
      const newDate: Date = new Date(date);
      newDate.setFullYear(Number.parseInt(this.yearSelected));

      return {
        id: id,
        date: this._dateService.formatDate(newDate),
      };
    });
  }

  public onSave(): void {
    forkJoin(
      this.lines.map(({date}) =>
        this._facade.getDayForGivenDate$(date).pipe(take(1))
      )
    )
      .pipe(take(1))
      .subscribe((days: Day[]) => {
        const data: Partial<BankHolidayPlanning>[] = this.lines.map(
          ({date, id}, index: number) => {
            return {
              date: date,
              bank_holiday_id: id,
              day_id: days[index].id,
            };
          }
        );

        this._facade
          .saveMultiple(data)
          .then((users: User[]) => this._handleUserWithEventResponse(users))
          .catch((err) => {
            if (!!err && !!err.errors) {
              Object.keys(err.errors).forEach((arrayKey: string) => {
                const splitted: string[] = arrayKey.split('.');
                if (splitted.length == 3) {
                  const index = Number.parseInt(splitted[1]);
                  const error = err.errors[arrayKey][0];
                  this.errors.set(index, error);
                }
              });
            }
          });
      });
  }

  public onClose(): void {
    this._dialogRef.close();
  }
}
