import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {Email} from 'src/app/model/email.model';

const EMAIL_URL = '/api/email';
@Injectable({
  providedIn: 'root'
})
export class EmailService {


  constructor(private http: HttpClient) { }

  // Récupérer la liste des emails
  getEmails(): Observable<any[]> {
    return this.http.get<any[]>(EMAIL_URL);
  }

  getEmailById(id: number): Observable<Email> {
    return this.http.get<Email>(`${EMAIL_URL}/${id}`);
  }

  // Update email
  updateEmail(email: Email): Observable<any> {
    return this.http.put(`${EMAIL_URL}/${email.id}`, email);
  }

  deleteEmail(email: Email): Observable<any> {
    return this.http.delete(`${EMAIL_URL}/${email.id}`);
  }
}
