import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {LocalStorageService} from '../service/local-storage.service';
import {TOKEN_STORAGE_KEY, USER_STORAGE_KEY} from '../../constant/string.constant';
import {UserService} from '../service/user.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private readonly _tokenService: LocalStorageService,
    private readonly _router: Router,
    private readonly _userService: UserService
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const cloneRequest = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this._tokenService.get(TOKEN_STORAGE_KEY) ?? ''
          }`,
      },
    });

    return next.handle(cloneRequest).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this._tokenService.remove(USER_STORAGE_KEY);
          this._userService.disconnect();
          this._router.navigate(['/auth/login']);
        }

        return throwError(err);
      })
    );
  }
}
