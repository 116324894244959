import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const OPERATING_SITE_URL: string = '/api/operating-site';

@Injectable({
  providedIn: 'root',
})
export class OperatingSiteService extends AbstractService<OperatingSite> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, OPERATING_SITE_URL);
  }

  protected override _convertData({
                                    id,
                                    is_constant,
                                    operating_location_id,
                                    operating_location,
                                    name,
                                  }: OperatingSite): OperatingSite {
    return new OperatingSite(
      id,
      name,
      is_constant,
      operating_location_id,
      operating_location
    );
  }
}
