import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {OperatingLocation} from 'src/app/model/operating-location.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {OperatingSiteService} from 'src/app/service/admin/operating-site.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-operating-site-creation-dialog',
  templateUrl: './operating-site-creation-dialog.component.html',
  styleUrls: ['./operating-site-creation-dialog.component.scss'],
})
export class OperatingSiteCreationDialogComponent
  extends AbstractDialogComponent<OperatingSite>
  implements OnInit
{
  constructor(
    public readonly dialogRef: MatDialogRef<
      AbstractDialogComponent<OperatingSite>
    >,
    protected readonly _operatingSiteService: OperatingSiteService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      site: OperatingLocation | null;
      operatingLocations: OperatingLocation[];
    }
  ) {
    super(dialogRef, _operatingSiteService, dialogData.site);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<OperatingSite> {
    const operatingSite: Partial<OperatingSite> = {
      name: this.inputValues['name'],
      operating_location: this.inputValues['operating_location'],
    };

    if (!!this.data?.id) {
      operatingSite.id = this.data.id;
    }

    return operatingSite;
  }

  protected override _initializeData({
    name,
    operating_location,
  }: OperatingSite): void {
    this.inputValues['name'] = name;
    this.inputValues['operating_location'] = operating_location!.id;
  }
}
