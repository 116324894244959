<ng-container *ngIf="!!plannedByDate && !!days">
  <tr class="mat-row">
    <td colspan="80" class="mat-cell">
      {{ operatingSite.getName() }}
    </td>

    <ng-container *ngIf="fetching$ | async">
      <td [attr.colspan]="days.length * 20">
        <mat-progress-spinner
          diameter="30"
          color="primary"
          mode="indeterminate"
        >
        </mat-progress-spinner>
      </td>
    </ng-container>

    <ng-container *ngIf="!(fetching$ | async)">
      <ng-container *ngFor="let date of days">
        <td
          *ngIf="plannedByDate.has(date.dateFormated)"
          colspan="20"
          class="mat-cell"
          (contextmenu)="
            onContextMenu($event, {
              planned: plannedByDate.get(date.dateFormated)!
            })
          "
          (pointerdown)="selected.clear()"
        >
          {{ plannedByDate.get(date.dateFormated)!.site_status.getName() }}
        </td>

        <td
          *ngIf="!plannedByDate.has(date.dateFormated)"
          [ngClass]="{
            'is-selected': selected.has(date)
          }"
          colspan="20"
          class="mat-cell"
          (contextmenu)="onContextMenu($event, { date: date })"
          (pointerover)="onPointerOver($event, date)"
          (pointerdown)="onPointerDown($event, date)"
        ></td>
      </ng-container>
    </ng-container>
  </tr>
</ng-container>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-planned="planned" let-date="date">
    <div fxLayout="column" fxLayoutAlign="center" *ngIf="!!planned">
      <ng-container *ngFor="let status of siteStatuses">
        <button
          mat-button
          *ngIf="status.id != planned.site_status.id"
          (click)="handleChangeSiteStatus(planned, status)">
          {{ status.getName() }}
        </button>
      </ng-container>
      <button mat-button color="warn" (click)="handleDelete(planned)">
        <mat-icon>delete</mat-icon>
        <span>Supprimer</span>
      </button>
    </div>

    <ng-container *ngIf="!!date">
      <button
        mat-menu-item
        *ngFor="let status of siteStatuses"
        (click)="handleSetSiteStatus(status)">
        {{ status.getName() }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
