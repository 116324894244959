import {ContentTable, TableCell, TDocumentDefinitions,} from 'pdfmake/interfaces';
import {Agent} from 'src/app/model/agent.model';
import {Astreinte} from 'src/app/model/astreinte.model';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export class AstreintePlanningPrintCreator {
  constructor(
    private readonly _year: number,
    private readonly _weeksByMonth: Map<string, number>,
    private readonly _agents: Agent[],
    private readonly _astreintesByAgent: Map<number, Astreinte[]>,
    private readonly _weekNumbers: number[]
  ) {}

  public printPDF(): void {
    const pdf: TDocumentDefinitions = {
      pageSize: 'A3',
      content: [
        {
          layout: {
            hLineWidth: (_: number, __: ContentTable, ___: number) => 1,
            vLineWidth: (_: number, __: ContentTable, ___: number) => 1,
          },
          table: {
            headerRows: 2,
            widths: [
              '*',
              ...new Array(2 * this._weekNumbers.length).fill('auto'),
            ],
            body: [
              this._createFirstHeader(),
              this._createSecondHeader(),
              ...this._createAgentBody(),
            ],
          },
        },
      ],
      defaultStyle: {
        fontSize: 10,
        alignment: 'center',
      },
      pageOrientation: 'landscape',
    };

    pdfMake
      .createPdf(pdf)
      .download(`Astreinte ${this._year} - ${new Date().getTime()}`);
  }

  private _createFirstHeader(): TableCell[] {
    return [
      {
        text: this._year,
      },
      ...[...this._weeksByMonth.entries()]
        .map(([month, weeks]) => {
          return [
            { text: month, colSpan: 2 * weeks },
            ...new Array(2 * weeks - 1).fill({ text: '' }),
          ];
        })
        .reduce((acc, next) => [...acc, ...next]),
    ];
  }

  private _createSecondHeader(): TableCell[] {
    return [
      {
        text: 'Semaine',
      },
      ...this._weekNumbers
        .map((weekNumber: number) => [
          { text: weekNumber, colSpan: 2 },
          { text: '' },
        ])
        .reduce((acc, next) => [...acc, ...next]),
    ];
  }

  private _createAgentBody(): TableCell[][] {
    return this._agents.map((agent: Agent) => {
      const astreintesForAgent: Map<number, Astreinte> = (
        this._astreintesByAgent.get(agent.id) ?? []
      ).reduce((acc, next) => {
        acc.set(next.week, next);
        return acc;
      }, new Map<number, Astreinte>());
      return [
        { text: agent.getName(), bold: true, alignment: 'right' },
        ...this._weekNumbers
          .map((weekNumber: number) => {
            return [
              {
                text: astreintesForAgent.has(weekNumber) ? '=' : '',
                colSpan: 2,
                fillColor: astreintesForAgent.has(weekNumber) ? '#ffff00' : '',
              },
              {
                text: '',
              },
            ];
          })
          .reduce((acc, next) => [...acc, ...next]),
      ];
    });
  }
}
