export const TAKE_DEFAULT: number = 25;
export const PAGE_WANTED_DEFAULT: number = 0;
export const OPERATING_LOCATION_ID = 1;
export const DAYS_QUANTITY_FOR_PLANIFICATOR = 7;
export const DAYS_QUANTITY_FOR_SITE_STATUS_PLANNING = 21;
export const SNACKBAR_DEFAULT_DURATION = 2000;

// Samsic Gardiennage, Samsic Nettoyage, Renfort EPI, Renfort BT, Renfort BM
export const JOBS_ID_NOT_COUNTING_ON_ETAT_SITE = [10, 11, 12, 13, 14];

export const ASTREINTE_SKILL_ID = 5;

export const COLSPAN_BY_DAY_PERIOD = 4;
