<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">attribution</mat-icon>
    <span>Les compétences</span>
  </mat-card-title>

  <div class="horizontal-delimiter"></div>

  <mat-card-content>
    <div class="search-field-container">
      <app-custom-search-input [search$]="search$">
      </app-custom-search-input>
      <button mat-raised-button color="accent" (click)="handleCreate()">
        <mat-icon>add_circle_outline</mat-icon>
      </button>
    </div>

    <div class="horizontal-delimiter"></div>

    <app-custom-table *ngIf="!isFetching" [dataSource]="dataSource" [headersAndValues]="headersAndValues"
      [contextMenusAndActions]="contextMenusAndActions">
    </app-custom-table>

    <mat-progress-spinner [diameter]="50" mode="indeterminate" *ngIf="isFetching"></mat-progress-spinner>
  </mat-card-content>
  <mat-card-actions>
    <mat-paginator (page)="handlePageEvent($event)" [length]="total" [pageSize]="pageSize" [pageIndex]="pageIndex">
    </mat-paginator>
  </mat-card-actions>
</mat-card>