import {Component, OnInit} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AuthService} from 'src/app/service/auth.service';
import {HeaderService} from 'src/app/service/header.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  uniqueIdentifierControl: FormControl = new FormControl('', [
    Validators.required,
  ]);

  fetching = false;
  mailSend = false;

  constructor(
    private readonly _headerService: HeaderService,
    private readonly _authService: AuthService,
    private readonly _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this._headerService.setTitle('Mot de passe oublié');
  }

  public sendResetPassword(): void {
    this.fetching = true;
    this._authService
      .sendMailForResettingPassword(this.uniqueIdentifierControl.value)
      .then(() => (this.mailSend = true))
      .catch((err) => console.log(err))
      .finally(() => {
        this.fetching = false;
        this._snackBar.open(
          "Si l'identifiant existe, un lien pour la réinitialisation vous sera envoyé à votre adresse mail d'ici quelques minutes.",
          'Fermer'
        );
      });
  }
}
