import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {forkJoin} from 'rxjs';
import {take} from 'rxjs/operators';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Agent} from 'src/app/model/agent.model';
import {Day} from 'src/app/model/day.model';
import {EventActivityLine} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {DateFormatted} from 'src/app/service/view/planning.service';
import {formatDateToDisplay} from 'src/app/util/date.util';
import {SiteStatus} from "../../model/site-status.model";
import {WorkSchedulesBySiteStatusPipe} from "../../pipe/work-schedules-by-site-status.pipe";

@Component({
  selector: 'app-event-creation-dialog',
  templateUrl: './event-creation-dialog.component.html',
  styleUrls: ['./event-creation-dialog.component.scss'],
})
export class EventCreationDialogComponent implements OnInit {
  lines: EventActivityLine[];
  errors: { [any: string]: string } = {};
  comment: string | null = null;

  constructor(private readonly _planningFacade: PlanningFacadeService,
              readonly dialogRef: MatDialogRef<EventCreationDialogComponent>,
              @Inject(MAT_DIALOG_DATA)
              public dialogData: {
                activityTypes: ActivityType[],
                workSchedules: WorkSchedule[],
                lines: EventActivityLine[];
                agent: Agent;
                site: OperatingSite;
                job: Job;
                dates: string[];
                id?: number;
                comment: string | null;
                sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;
                sitesStatus: SiteStatus[];
                version: number |null
              }) {
    this.lines = this.dialogData.lines;
    this.comment = this.dialogData.comment;
  }

  ngOnInit(): void {
  }

  get workSchedules(): WorkSchedule[] {
    if (this.dialogData.dates.length === 1) {
      return new WorkSchedulesBySiteStatusPipe().transform(this.dialogData.workSchedules,
        this.dialogData.site,
        this.sitesStatusPlanningByOperatingSite,
        this.sitesStatus,
        this.dialogData.dates[0]);
    }
    return this.dialogData.workSchedules;
  }

  get sitesStatusPlanningByOperatingSite(): Map<number, Map<string, SiteStatus>> {
    return this.dialogData.sitesStatusPlanningByOperatingSite
  }

  get sitesStatus(): SiteStatus[] {
    return this.dialogData.sitesStatus
  }

  get activityTypes(): ActivityType[] {
    return this.dialogData.activityTypes;
  }

  get isModify(): boolean {
    return this.dialogData.lines.some(({id}) => !!id);
  }

  get period(): string {
    if (this.dialogData.dates.length > 1) {
      return `du ${formatDateToDisplay(
        this.dialogData.dates[0]
      )} au ${formatDateToDisplay(
        this.dialogData.dates[this.dialogData.dates.length - 1]
      )}`;
    }

    return `le ${formatDateToDisplay(this.dialogData.dates[0])}`;
  }

  get site(): string {
    return this.dialogData.site.getName();
  }

  get identification(): string {
    return this.dialogData.agent.getName();
  }

  get company(): string {
    return this.dialogData.agent.company!.getName();
  }

  get skills(): string {
    return this.dialogData.agent
      .skills!.map((skill) => skill.getName())
      .reduce((prev, next, index) => {
        if (index != 0) {
          return prev + ', ' + next;
        }
        return prev + next;
      }, '');
  }

  get services(): string {
    return this.dialogData.agent
      .services!.map((service) => service.getName())
      .reduce((prev, next, index) => {
        if (index != 0) {
          return prev + ', ' + next;
        }
        return prev + next;
      }, '');
  }

  handleCommentchange(value: any) {
    this.comment = value;
  }

  handleLinesSend(lines: EventActivityLine[]) {
    this.lines = [...lines];
  }

  onClose() {
    this.dialogRef.close();
  }

  onSave() {
    if (!!this.dialogData.id) {
      this._planningFacade
        .getDayForGivenDate$(this.dialogData.dates[0])
        .pipe(take(1))
        .subscribe(async (day: Day) => {
          try {
            await this._planningFacade.updateEvent$(
              this.lines,
              this.dialogData.id!,
              this.dialogData.agent,
              this.dialogData.site,
              this.dialogData.job,
              this.dialogData.agent.skills!,
              this.dialogData.agent.services!,
              this.dialogData.dates[0],
              day,
              this.comment,
              this.dialogData.version
            );
            this.dialogRef.close();
          } catch (err: any) {
            this._handleErrors(err);
          }
        });
    } else {
      forkJoin(
        this.dialogData.dates.map((date: string) =>
          this._planningFacade.getDayForGivenDate$(date).pipe(take(1))
        )
      ).subscribe(async (days: Day[]) => {
        const datesFormatted: DateFormatted[] = days.map(
          ({id}: Day, index: number) => {
            return {
              date: this.dialogData.dates[index],
              day_id: id,
            };
          }
        );

        try {
          await this._planningFacade.saveEvent$(
            this.lines,
            this.dialogData.agent,
            this.dialogData.site,
            this.dialogData.job,
            this.dialogData.agent.skills!,
            this.dialogData.agent.services!,
            datesFormatted,
            this.comment,
            this.dialogData.version
          );
          this.dialogRef.close();
        } catch (err: any) {
          this._handleErrors(err);
        }
      });
    }
  }

  private _handleErrors(err: any): void {
    if (!!err.errors) {
      Object.keys(err.errors).forEach(
        (attribute: string) =>
          (this.errors[attribute] = err.errors[attribute][0])
      );
    }
  }
}
