import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {Service} from './service.model';
import {User} from './user.model';

export class Company implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public name: string,
    public is_constant: boolean,
    public services?: Service[],
    public users?: User[]
  ) {
    if (!!services) {
      this.services = services!.map(
        ({ id, name, is_constant, company, company_id, users }) =>
          new Service(id, name, is_constant, company_id, company, users)
      );
    }

    if (!!users) {
      this.users = users!.map(
        ({
          id,
          firstName,
          lastName,
          email,
          mail_perso,
          tel_perso,
          unique_identifier,
          is_constant,
          sommeil,
          company_id,
        }) =>
          new User(
            id,
            firstName,
            lastName,
            email,
            mail_perso,
            tel_perso,
            unique_identifier,
            is_constant,
            sommeil,
            company_id
          )
      );
    }
  }

  getName(): string {
    return this.name;
  }
}
