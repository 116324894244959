import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

const EMAIL_URL = '/api/settings/email';
const SMS_URL = '/api/settings/sms';
@Injectable({
  providedIn: 'root'
})
export class SettingsService {


  constructor(private http: HttpClient) { }

  saveEmailSettings(settings: any): Observable<any> {
  if (Array.isArray(settings)) {
    settings = Object.assign({}, settings); 
  }
  console.log('Données envoyées : ', settings);
    return this.http.post(EMAIL_URL, settings, {
      headers: { 'Content-Type': 'application/json' }
    });
  }

  saveSmsSettings(settings: any): Observable<any> {
    if (Array.isArray(settings)) {
        settings = Object.assign({}, settings); 
    }

    console.log('Données envoyées : ', settings);
    return this.http.post(SMS_URL, settings, {
        headers: { 'Content-Type': 'application/json' }
    });
}


  getEmailSettings(): Observable<any> {
    return this.http.get<any>(EMAIL_URL);
  }

  getSmsSettings(): Observable<any> {
    return this.http.get<any>(SMS_URL);
  }

}
