import {IdentifiedModel} from './base-model.model';

export class Astreinte implements IdentifiedModel {
  public constructor(
    public id: number,
    public agent_id: number,
    public year: number,
    public week: number
  ) {}
}
