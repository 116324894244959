import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {ServicePlanningPrint} from 'src/app/service/creator/service-planning-print.service';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {PlanningMode} from 'src/constant/enum.contants';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {LegendsFacadeService} from "../../../service/facade/legends-facade.service";
import {EventVersion} from "../../../model/event-version.model";
import { NotificationService } from '../../../service/admin/notification.service';

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss']
})

export class PlanningComponent implements OnInit {

  state$: Observable<any>;

  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  job$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  showLegends: boolean = false;
  showRecapEtatSite = false;
  mode: PlanningMode = PlanningMode.BY_JOB;
  loading$ = new BehaviorSubject<boolean>(false);
  daysOff: Map<string, BankHolidayPlanning> = new Map();
  isLoading = false;
  constructor(private readonly _planningFacade: PlanningFacadeService,
              private readonly _legendsFacadeService: LegendsFacadeService,
              private readonly _pdfService: ServicePlanningPrint,
              private readonly _snackBar: MatSnackBar,
              private notificationService: NotificationService,
              private readonly _dialog: MatDialog) {
  }

  ngOnInit(): void {
    console.log("ngOnInit");
    this.loading$.next(true);
    this._planningFacade.initialize();
    this.state$ = combineLatest([
      this._planningFacade.agents$,
      this._planningFacade.getAgentsByService$(this.search$, this.job$),
      this._planningFacade.datesString$,
      this._planningFacade.days$,
      this._planningFacade.workSchedules$,
      this._planningFacade.activityTypes$,
      this._planningFacade.jobs$,
      this._planningFacade.operatingLocationSelected$,
      this._planningFacade.operatingSitesAvailable$,
      this._planningFacade.fetching$,
      this._planningFacade.weekSelected$,
      this._planningFacade.yearSelected$,
      this._planningFacade.plannings$,
      this._planningFacade.versions$,
      this._planningFacade.versionSelected$,
      this._planningFacade.hoursPayed$,
      this._planningFacade.sitesStatusPlanningByOperatingSite$,
      this._planningFacade.sitesStatus$,
      this._planningFacade.sitesStatusesPlanningRecap$,
      this._legendsFacadeService.legends$,
    ]).pipe(
      map(
        ([
           agents,
           agentsByService,
           dates,
           days,
           workSchedules,
           activityTypes,
           jobs,
           operatingLocationSelected,
           operatingSitesAvailable,
           fetching,
           weekSelected,
           yearSelected,
           plannings,
           versions,
           versionSelected,
           hoursPayed,
           sitesStatusPlanningByOperatingSite,
           sitesStatus,
           sitesStatusesPlanningRecap,
           legends,
         ]) => ({
          agents,
          agentsByService,
          dates,
          days,
          workSchedules,
          activityTypes,
          jobs,
          operatingLocationSelected,
          operatingSitesAvailable,
          fetching,
          weekSelected,
          yearSelected,
          plannings,
          versions,
          versionSelected,
          hoursPayed,
          sitesStatusPlanningByOperatingSite,
          sitesStatus,
          sitesStatusesPlanningRecap,
          legends,
        })
      )
    );
    this._planningFacade.selectDefaultOperatingLocation();
    this._planningFacade.daysOff$.subscribe((daysOff) => {
      this.daysOff = daysOff.reduce((map, next: BankHolidayPlanning) => {
        map.set(next.date, next);
        return map;
      }, new Map<string, BankHolidayPlanning>());
    });
    combineLatest([
      this._planningFacade.versions$,
      this._planningFacade.versionSelected$,
    ]).subscribe(([versions, versionSelected]) => {
      if(versions.length){
        if (versionSelected && versions.filter(_ => _.id === versionSelected).length) {
          this._planningFacade.isLatestEventVersionSelected = versionSelected === versions[0].id
        } else {
          this._planningFacade.isLatestEventVersionSelected = true;
          this._planningFacade.versionSelected = versions[0].id;
        }
      }else{
        this._planningFacade.isLatestEventVersionSelected = true;
      }
      
      setTimeout(() => {
        this.loading$.next(false);
      }, 10000); 
      
    })

  }

  handleSearchChange(event: any): void {
    this.search$.next(event.target.value ?? '');
  }

  handleSelectionChange(change: MatSelectChange): void {
    this.job$.next(change.value);
  }

  handleVersionChange(change: MatSelectChange): void {
    this._planningFacade.isLatestEventVersionSelected = change.value === this._planningFacade.versionSelected;
    this._planningFacade.versionSelected = change.value;
    this._planningFacade.getEventsGlobal();

  }

  versionateEvents(): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: "Création d'une nouvelle version",
        description:
          'Les activités actuelles vont être versionnées. Cette action est définitive !',
        validButton: 'Versionner',
        cancelButton: 'Annuler',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((doAction: boolean) => {
        if (doAction) {
          this._planningFacade
            .versionate$()
            .then((eventVersion: EventVersion) => {
              this._planningFacade.versionSelected = eventVersion.id;
              this._planningFacade.isLatestEventVersionSelected = true;
              this._snackBar.open(
                'La semaine a été versionnée avec succès !',
                'Fermer',
                {duration: SNACKBAR_DEFAULT_DURATION}
              );
            })
            .catch((err) => console.log(err));
        }
      });
  }
  notifAllAgents(version: number) {
    this.isLoading = true;  
    this._planningFacade.agents$.subscribe(agents => {
      if (agents && agents.length > 0) {
        const tasks: Promise<void>[] = [];

        agents.forEach(agent => {
          if (agent.tel_perso != null) {
            tasks.push(this.sendSms(agent.tel_perso, this._planningFacade.week));
          }
          if (agent.mail_perso != null) {
            tasks.push(this.sendEmail(agent.mail_perso, this._planningFacade.week));
          }
        });

        Promise.all(tasks)
          .then(() => {
            this.isLoading = false; 
            this._snackBar.open('Notifications envoyées avec succès !', 'Fermer', {
              duration: 3000,
            });
          })
          .catch(() => {
            this.isLoading = false; 
           /* this._snackBar.open('Une erreur est survenue lors de l\'envoi des notifications.', 'Fermer', {
              duration: 3000,
            }); */
            console.log('Une erreur est survenue lors de l\'envoi des notifications.');
          });
      } else {
        this.isLoading = false; 
        console.log("Aucun agent trouvé.");
        this._snackBar.open('Aucun agent trouvé.', 'Fermer', {
          duration: 3000,
        });
      }
    });
  }

  sendEmail(email: string, week: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.notificationService.sendEmail(email, week).subscribe(
        response => {
          console.log('Email sent successfully:', response);
          resolve();
        },
        error => {
          console.error('Error sending email:', error);
          reject();
        }
      );
    });
  }

  sendSms(tel: string, week: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.notificationService.sendSms(tel, week).subscribe(
        response => {
          console.log('SMS sent successfully:', response);
          resolve();
        },
        error => {
          console.error('Error sending SMS:', error);
          reject();
        }
      );
    });
  }

  publishVersion(version: number) {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: "Publication d'une nouvelle version",
        description:
          'Les activités actuelles vont être publiées. Cette action est définitive !',
        validButton: 'Publier',
        cancelButton: 'Annuler',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((doAction: boolean) => {
        if (doAction) {
          if (!version) {
            this._planningFacade.versionate$().then((eventVersion: EventVersion) => {
              this._planningFacade.versionSelected = eventVersion.id;
              this._planningFacade.isLatestEventVersionSelected = true;
              this._planningFacade.publishVersion$().then(() => {
                this._snackBar.open(
                  'La version a été publiée avec succès !',
                  'Fermer',
                  {duration: SNACKBAR_DEFAULT_DURATION}
                );
              })
            })
          } else {
            this._planningFacade.publishVersion$().then(() => {
              this._snackBar.open(
                'La version a été publiée avec succès !',
                'Fermer',
                {duration: SNACKBAR_DEFAULT_DURATION}
              );
            })
          }
        }
      });
  }

  printPdf(): void {
    this._pdfService.createPdf();
  }

  onYearChanges(newYear: number) {
    this._planningFacade.year = newYear;
    this._planningFacade.versionSelected = null;
  }

  get currentWeek(): number {
    return this._planningFacade.week;
  }

  get currentYear(): number {
    return this._planningFacade.year;
  }

  onWeekChange(newWeek: number) {
    this.loading$.next(true);
    this._planningFacade.week = newWeek;
    this._planningFacade.versionSelected = null;
    
  }

  get isLatestEventVersionSelected$(): Observable<boolean> {
    return this._planningFacade.isLatestEventVersionSelected$
  }


}
