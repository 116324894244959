import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../service/admin/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-sms-management',
  templateUrl: './sms-management.component.html',
  styleUrls: ['./sms-management.component.scss']
})
export class SmsManagementComponent implements OnInit {
  smsSettings = {
    sms_status: 'active',
    api_sms: '',
    emetteur_sms: '',
    sms_nbr_attempts: 1,
    sms_sandbox: 'active',
    delete_sms: 'active',
    reminder_sms: 'active'
  };
  constructor(private smsService: SettingsService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadSmsSettings();
  }

  loadSmsSettings() {
    this.smsService.getSmsSettings().subscribe({
      next: (settings) => {
        this.smsSettings = settings;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des paramètres de SMS :', error);
      }
    });
  }
  
  onSave() {
    console.log('Paramètres SMS enregistrés :', this.smsSettings);
    this.smsService.saveSmsSettings(this.smsSettings).subscribe({
      next: (response) => {
        console.log('Paramètres SMS enregistrés avec succès :', response);
        this.snackBar.open('Paramètres SMS enregistrés avec succès !', 'Fermer', {
          duration: 3000, 
        });
      },
      error: (error) => {
        console.error('Erreur lors de l\'enregistrement des paramètres SMS :', error);
        this.snackBar.open('Erreur lors de l\'enregistrement des paramètres SMS !', 'Fermer', {
          duration: 3000,
        });
      }
    });

    
  }
}
