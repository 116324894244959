import {Component, EventEmitter, Input, OnInit, Output, ViewChild,} from '@angular/core';
import {MatMenuTrigger} from '@angular/material/menu';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Agent} from 'src/app/model/agent.model';
import {DayPeriod} from 'src/app/model/day.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {Action, ActivityContextMenuComponent,} from '../activity-context-menu/activity-context-menu.component';
import {Event} from 'src/app/model/event.model';
import {SiteStatus} from "../../../../model/site-status.model";

@Component({
  selector: 'app-job-context-menu',
  templateUrl: './job-context-menu.component.html',
  styleUrls: ['./job-context-menu.component.scss'],
})
export class JobContextMenuComponent implements OnInit {
  public job: Job | null = null;

  @Input()
  public workSchedules: WorkSchedule[];

  @Input()
  sitesStatusPlanningByOperatingSite: Map<number, Map<string, SiteStatus>>;

  @Input()
  sitesStatus: SiteStatus[];

  @Input()
  public activityTypes: ActivityType[];

  @Input()
  public length: number;

  @Input()
  public contextMenuPosition: { x: string; y: string };

  @Input()
  public agent: Agent;

  @Output()
  public singleTDClicked$: EventEmitter<{
    site: OperatingSite;
    date: string;
    day_period: DayPeriod;
    job: Job;
    astreinte: any;
  }> = new EventEmitter();

  @Output()
  public modifyEvent$: EventEmitter<{ action: Action; event: Event }> =
    new EventEmitter();

  @Output()
  public addLine$: EventEmitter<{
    morning: any | null;
    afternoon: any | null;
    job: Job;
    site: OperatingSite;
  }> = new EventEmitter();

  @ViewChild(MatMenuTrigger)
  contextMenu: MatMenuTrigger;

  @ViewChild(ActivityContextMenuComponent)
  public activityContextMenuComponent: ActivityContextMenuComponent;

  constructor() {
  }

  ngOnInit(): void {
  }

  public handleClickOnJob(
    job: Job,
    date: string,
    site: OperatingSite,
    day_period: DayPeriod
  ): void {
    this.job = job;
    this.contextMenu.closeMenu();
    this.activityContextMenuComponent.contextMenu.menuData = {
      job,
      date,
      site,
      day_period,
    };
    this.activityContextMenuComponent.contextMenu.menu.focusFirstItem('mouse');
    this.activityContextMenuComponent.contextMenu.openMenu();
  }

  public handleSingleTDClicked({
                                 site,
                                 date,
                                 day_period,
                                 astreinte,
                               }: {
    site: OperatingSite;
    date: string;
    day_period: DayPeriod;
    astreinte: ActivityType;
  }) {
    this.singleTDClicked$.emit({
      site,
      date,
      day_period,
      astreinte,
      job: this.job!,
    });
  }

  public handleActionOnEvent({
                               action,
                               event,
                             }: {
    action: Action;
    event: Event;
  }): void {
    switch (action) {
      case Action.MODIFY: {
        this.onModifyEvent(event);
        break;
      }

      case Action.DELETE: {
        this.onDeleteEvent(event);
        break;
      }
    }
  }

  public onModifyEvent(event: Event): void {
    this.modifyEvent$.emit({action: Action.MODIFY, event: event});
  }

  public onDeleteEvent(event: Event): void {
    this.modifyEvent$.emit({action: Action.DELETE, event: event});
  }

  public handleAddLine({
                         morning,
                         afternoon,
                         site,
                       }: {
    morning: any | null;
    afternoon: any | null;
    site: OperatingSite;
  }): void {
    this.addLine$.emit({
      morning,
      afternoon,
      site,
      job: this.job!,
    });
  }
}
