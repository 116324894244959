import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ItemsDragged} from 'src/app/common/table/drag-drop-two-tables/drag-drop-two-tables.component';
import {Agent} from 'src/app/model/agent.model';

@Component({
  selector: 'app-company-agent-assign-dialog',
  templateUrl: './company-agent-assign-dialog.component.html',
  styleUrls: ['./company-agent-assign-dialog.component.scss']
})
export class CompanyAgentAssignDialogComponent implements OnInit {

  constructor(
    public readonly dialogRef: MatDialogRef<CompanyAgentAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      agentAvailable: Agent[],
      agentAssigned: Agent[]
    },
  ) { }

  ngOnInit(): void {
  }

  public onChanged(data: ItemsDragged<Agent>): void {
    this.data.agentAssigned = data.assigned;
    this.data.agentAvailable = data.available;
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onSave(): void {
    this.dialogRef.close(
      this.data.agentAssigned
    );
  }

}
