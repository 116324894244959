<h1 mat-dialog-title class="administration-title">
  <mat-icon class="header-icon">person_add</mat-icon>
  <span>Ajout d'une ressource pour la fonction {{ data.job.code }}</span>
</h1>
<div mat-dialog-content>
  <form fxLayout="column"
        fxLayoutAlign="space-between"
        [formGroup]="filterGroup">
    <div fxLayout="row"
         fxLayoutAlign="space-between center">
      <div fxFlex="20%">
        <mat-form-field>
          <mat-label>Chercher</mat-label>
          <input matInput
                 formControlName="searchControl"
                 placeholder="nom ou prénom"/>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <mat-form-field>
          <mat-label>Compétence(s)</mat-label>
          <mat-select formControlName="skillsControl" multiple>
            <mat-option *ngFor="let skill of data.skills"
                        [value]="skill">{{ skill.getName() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <mat-form-field>
          <mat-label>Entreprise</mat-label>
          <mat-select formControlName="companiesControl">
            <mat-option> -</mat-option>
            <mat-option *ngFor="let company of data.companies"
                        [value]="company">{{ company.getName() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="20%">
        <mat-form-field *ngIf="servicesAvailables.length > 0">
          <mat-label>Service</mat-label>
          <mat-select formControlName="servicesControl">
            <mat-option> -</mat-option>
            <mat-option *ngFor="let service of servicesAvailables"
                        [value]="service">{{ service.getName() }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>

  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef colspan="20">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="masterToggle()"
                      [checked]="selection.hasValue() && isAllSelected()"
                      [indeterminate]="selection.hasValue() && !isAllSelected()">
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row" colspan="20">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="$event ? selection.toggle(row) : null"
                      [checked]="selection.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef colspan="140"></th>
      <td mat-cell *matCellDef="let agent" colspan="140" class="td-agent-name">
        {{ agent.getName() }}
      </td>
    </ng-container>

    <ng-container *ngFor="let day of data.days; let i = index"
                  [matColumnDef]="day">
      <th mat-header-cell
          *matHeaderCellDef
          colspan="20">
        {{ day }}<br/>{{ data.dates[i] | planningDateDisplay }}
      </th>
      <ng-container *matCellDef="let element">
        <ng-template #eventsPresent
                     [ngTemplateOutlet]="eventsPresent"
                     let-events="events"
                     [ngTemplateOutletContext]="{
            events:
              eventsByAgentByDate.get(element.id)?.get(data.dates[i]) ?? []
          }">
          <ng-container *ngIf="events.length > 0">
            <ng-template #eventsByPeriod
                         [ngTemplateOutlet]="eventsByPeriod"
                         let-morning="morning"
                         let-day="day"
                         let-afternoon="afternoon"
                         [ngTemplateOutletContext]="getEventByPeriod(events)">
              <ng-container *ngIf="morning.length > 0; else noMorning">
                <ng-container *ngFor="let event of morning">
                  <td [attr.colspan]="10 / (morning.length + day.length)"
                      class="cell-unavailable">
                    {{ event.activity_type.code }}
                  </td>
                </ng-container>
              </ng-container>
              <ng-template #noMorning>
                <td *ngIf="day.length == 0" [attr.colspan]="10"></td>
              </ng-template>

              <ng-container *ngIf="day.length > 0">
                <ng-container *ngFor="let event of day">
                  <td [attr.colspan]="
                      10 / (afternoon.length + day.length) +
                      10 / (morning.length + day.length)
                    "
                      class="cell-unavailable">
                    {{ event.activity_type.code }}
                  </td>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="afternoon.length > 0; else noAfternoon">
                <ng-container *ngFor="let event of afternoon">
                  <td [attr.colspan]="10 / (afternoon.length + day.length)"
                      class="cell-unavailable">
                    {{ event.activity_type.code }}
                  </td>
                </ng-container>
              </ng-container>

              <ng-template #noAfternoon>
                <td *ngIf="day.length == 0" [attr.colspan]="10"></td>
              </ng-template>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="events.length == 0">
            <td colspan="10"></td>
            <td colspan="10"></td>
          </ng-container>
        </ng-template>
      </ng-container>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="daysColumns"
        (click)="masterToggle()"></tr>
    <tr mat-row
        *matRowDef="let agent; columns: daysColumns"
        (click)="selection.toggle(agent)"></tr>
  </table>
</div>
<div mat-dialog-actions fxLayoutAlign="flex-end center">
  <button mat-raised-button color="warn" (click)="onCancel()">Annuler</button>
  <button mat-raised-button
          color="primary"
          (click)="onClose()"
          [disabled]="!selection.hasValue()">
    Valider
  </button>
</div>
