import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Job} from 'src/app/model/job.model';

import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const JOB_URL: string = '/api/job';

@Injectable({
  providedIn: 'root',
})
export class JobService extends AbstractService<Job> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, JOB_URL);
  }

  public findJobs$(): Observable<Job[]> {
    this.setFetching(true);

    return this._httpClient.get<Job[]>(`${JOB_URL}?relations[]=`).pipe(
      catchError(catchErrorOnArray()),
      map((jobs: Job[]) => {
        return jobs.map(this._convertData);
      }),
      take(1),
      finalize(() => this.setFetching(false))
    );
  }

  protected override _convertData({
    id,
    name,
    code,
    activity_type_id,
    is_constant,
    activity_type,
    column_order,
  }: Job): Job {
    return new Job(
      id,
      name,
      code,
      activity_type_id,
      column_order,
      is_constant,
      activity_type
    );
  }
}
