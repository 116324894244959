import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';

export enum BankHolidayType {
  JFR = '0',
  JARTT_ACIE = '1',
  PONT_CSG = '2',
}

export class BankHoliday implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public name: string,
    public type: BankHolidayType,
    public is_constant: boolean
  ) {}

  public getName(): string {
    return this.name;
  }
}
