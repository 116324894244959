export class PaginationResult<T> {
  constructor(
    public results: T[],
    public total: number,
    public next: boolean,
    public previous: boolean
  ) {

  }
}

export function createDefault<T>(): PaginationResult<T> {
  return {
    results: [],
    total: 0,
    next: false,
    previous: false,
  };
}
