<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">event</mat-icon>
    <span>Planning</span>
  </mat-card-title>

  <div class="horizontal-delimiter"></div>

  <mat-card-content *ngIf="state$ | async as state">
    <ng-container *ngIf="!(fetching$ | async)">
      <app-draggable-legend *ngIf="showLegends"
        [legends]="state.legends"
        [boundaryLimit]="'.mat-card'"
        [titles]="['Horaires', 'Activités']"
        (closeWanted$)="showLegends = false">
      </app-draggable-legend>

      <div class="planning-form-container">
        <mat-slide-toggle [(ngModel)]="showLegends">{{
            showLegends ? "Légende affichée" : "Légende cachée"
          }}
        </mat-slide-toggle>
      </div>

      <table class="mat-elevation-z8 mat-table">
        <thead>
        <tr class="site-tr mat-header-row">
          <th colspan="60" class="mat-header-cell">Service EPCU</th>

          <th colspan="140"
            class="mat-header-cell"
            *ngFor="let week of state.weekNumbers; let i = index">
            Semaine {{ week }} ({{
              !!state.eventVersions[i]
                ? "V" + state.eventVersions[i].version
                : "non validé"
            }})
          </th>
        </tr>

        <tr class="day-name-tr mat-header-row">
          <th colspan="60" class="mat-header-cell">
            {{ state.years | yearsSelectedDisplay }}
          </th>
          <ng-container *ngFor="let _ of state.weekNumbers">
            <th colspan="20"
              *ngFor="let day of state.days"
              class="mat-header-cell">
              {{ day.getName() }}
            </th>
          </ng-container>
        </tr>

        <tr class="day-number-tr mat-header-row">
          <th colspan="60" class="mat-header-cell">
            <button mat-button (click)="handlePreviousWeeks()">
              <mat-icon>navigate_before</mat-icon>
            </button>
            <button mat-button (click)="handleNextWeeks()">
              <mat-icon>navigate_next</mat-icon>
            </button>
          </th>
          <ng-container *ngFor="let _ of state.weekNumbers; let i = index">
            <th colspan="20"
              *ngFor="let date of state.dates[i]"
              class="mat-header-cell"
              [class.off-day]="(daysOff$ | async)?.has(date)"
              [matTooltip]="(daysOff$ | async)?.get(date)?.getName() ?? ''"
              [matTooltipDisabled]="!(daysOff$ | async)?.has(date)"
              matTooltipShowDelay="500"
              matTooltipClass="line-broken-tooltip">
              {{ date | planningDateDisplay }}
            </th>
          </ng-container>
        </tr>
        </thead>

        <tr *ngFor="let agent of state.agents"
            app-agent-row-consult
            [agent]="agent"
            [events]="state.events.get(agent.id)"
            [workSchedules]="state.workSchedules"
            [activityTypes]="state.activityTypes"
            [days]="state.days"
            [dates]="state.dates">

        </tr>
      </table>
    </ng-container>
  </mat-card-content>
  <mat-card-content *ngIf="!state$ || (fetching$ | async)">
    <mat-progress-spinner diameter="30" color="accent" mode="indeterminate">
    </mat-progress-spinner>
  </mat-card-content>
</mat-card>
