import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';

export const INDISPONIBILITY_ID = new Set<Number>([2, 4, 5, 6, 13]);
export const INDISPONIBILITY_ID_ACTIVE = new Set<Number>([]);
export const ASTREINTE_ID = 1;

export class ActivityType
  implements IdentifiedModel, NamedModel, ConstantModel
{
  public constructor(
    public id: number,
    public name: string,
    public code: string,
    public favorite_work_schedule_code: string,
    public is_constant: boolean
  ) {}

  getName(): string {
    return this.name;
  }

  public isIndisponibility(): boolean {
    return INDISPONIBILITY_ID.has(this.id);
  }

  public isIndisponibilityActive(): boolean {
    return INDISPONIBILITY_ID_ACTIVE.has(this.id);
  }

}
