import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-site-status-errors-dialog',
  templateUrl: './site-status-errors-dialog.component.html',
  styleUrls: ['./site-status-errors-dialog.component.scss'],
})
export class SiteStatusPlanningErrorDialog {
  public errors: string[];

  constructor(public readonly dialogRef: MatDialogRef<SiteStatusPlanningErrorDialog>,
              @Inject(MAT_DIALOG_DATA) data: { errors: string[] }) {
    this.errors = data.errors;
  }

  public onClose(): void {
    this.dialogRef.close();
  }
}
