import {Component} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {stringifyNamedModels} from 'src/app/common/helper';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {
  AlertEntityPresentDialogComponent
} from 'src/app/dialog/alert-entity-present-dialog/alert-entity-present-dialog.component';
import {
  OperatingLocationCreationDialogComponent
} from 'src/app/dialog/operating-location-creation-dialog/operating-location-creation-dialog.component';
import {OperatingLocation} from 'src/app/model/operating-location.model';
import {OperatingLocationService} from 'src/app/service/admin/operating-location.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';

@Component({
  selector: 'app-operating-location',
  templateUrl: './operating-location.component.html',
  styleUrls: ['./operating-location.component.scss'],
})
export class OperatingLocationComponent extends AbstractAdministrationComponent<OperatingLocation> {
  headersAndValues: HeaderAndValue<OperatingLocation>[] = [
    {
      displayName: '#',
      propertyName: 'id',
      value: (operatingLocation: OperatingLocation) => operatingLocation.id,
    },
    {
      displayName: 'Nom',
      propertyName: 'name',
      value: (operatingLocation: OperatingLocation) => operatingLocation.name,
    },
    {
      displayName: 'Site(s)',
      propertyName: 'operating_sites',
      value: (operatingLocation: OperatingLocation) =>
        stringifyNamedModels(operatingLocation.operating_sites ?? []),
    },
  ];

  contextMenusAndActions: ContextMenuAndAction<OperatingLocation>[] = [
    {
      name: (_: OperatingLocation) => 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: OperatingLocation) => this.handleUpdate(item),
      canBeActivated: (item: OperatingLocation) => !item.is_constant,
    },
    {
      name: (_: OperatingLocation) => 'Supprimer',
      icon: 'delete',
      color: 'warn',
      callback: (item: OperatingLocation) => this._handleDelete(item),
      canBeActivated: (item: OperatingLocation) => !item.is_constant,
    },
  ];

  constructor(
    protected readonly _service: OperatingLocationService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_service, _dialog, _snackBar);
  }

  public handleUpdate(
    operatingLocation: OperatingLocation | null = null
  ): void {
    const dialogRef = this._dialog.open(
      OperatingLocationCreationDialogComponent,
      {
        data: operatingLocation,
      }
    );

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { name }: OperatingLocation = result as OperatingLocation;
          this._snackBar.open(
            `Le lieu d'exploitation ${name} a été ${
              !!operatingLocation ? 'modifié' : 'créé'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }

  private _handleDelete(operatingLocation: OperatingLocation): void {
    if (
      !!operatingLocation.operating_sites &&
      operatingLocation.operating_sites.length !== 0
    ) {
      this._showUsersAssignedAlert();
    } else {
      this.handleDelete(operatingLocation);
    }
  }

  private _showUsersAssignedAlert(): void {
    this._dialog.open(AlertEntityPresentDialogComponent, {
      data: {
        title: "Des sites d'exploitation vont se retrouver sans lieux !",
        message:
          "Veuillez associer tout les sites d'exploitation à un autre lieu d'exploitation avant de supprimer ce site.",
      },
    });
  }
}
