import {Pipe, PipeTransform} from '@angular/core';
import {IdentifiedModel, NamedModel} from '../model/base-model.model';
import {CustomSelect} from '../model/custom-select.model';

@Pipe({
  name: 'customItemOption'
})
export class CustomItemOptionPipe<T extends IdentifiedModel & NamedModel> implements PipeTransform {

  transform(values: T[]): CustomSelect[] {
    return values.map((data: T) => {
      return {
        name: data.getName(),
        value: data.id
      };
    });
  }

}
