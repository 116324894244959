<div class="legend-box"
     cdkDrag
     [cdkDragBoundary]="boundaryLimit">
  <ng-container *ngFor="let legend of legends; let i = index">
    <div fxLayout="column"
         fxLayoutAlign="start center"
         class="list-container">
      <h4 *ngIf="!!titles[i]">{{ titles[i] }}</h4>
      <mat-list role="list">
        <mat-list-item role="listitem"
                       *ngFor="let l of legend"
                       [style.background-color]="l.color">
          {{ l.name }}
        </mat-list-item>
      </mat-list>
    </div>
  </ng-container>

  <button color="warn"
          mat-button
          class="close-legends-button"
          (click)="handleClose()">
    <mat-icon>highlight_off</mat-icon>
  </button>
</div>
