import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, of, ReplaySubject,} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {PAGE_WANTED_DEFAULT, TAKE_DEFAULT,} from 'src/constant/number.constants';
import {catchErrorOnArray, catchErrorOnPaginated} from '../common/helper';
import {IdentifiedModel} from '../model/base-model.model';
import {PaginationResult} from '../model/pagination-result';
import {FetchingService} from './admin/fetching.service';

export abstract class AbstractService<T extends IdentifiedModel> {
  protected _globalData$: ReplaySubject<T[]> = new ReplaySubject<T[]>(1);
  protected _paginatedResult$: ReplaySubject<PaginationResult<T>> =
    new ReplaySubject<PaginationResult<T>>(1);

  constructor(
    private readonly _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient,
    private readonly _apiUrl: string
  ) {
  }

  protected abstract _convertData(data: T): T;

  public getPaginatedResult$(): Observable<PaginationResult<T>> {
    return this._paginatedResult$;
  }

  public setFetching(isFetching: boolean): void {
    this._fetchingService.fetching = isFetching;
  }

  public getFetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  public get globalData$(): Observable<T[]> {
    return this._globalData$;
  }

  public post$(entity: Partial<T>): Promise<T> {
    return this._doActionOnEntity$(entity, 'post');
  }

  public postGlobal$(entity: Partial<T>): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const data = await this.post$(entity);
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next([...d, data]);
          resolve();
        });
      } catch (err) {
        return reject(err);
      }
    });
  }

  public put$(entity: Partial<T>): Promise<T> {
    return this._doActionOnEntity$(entity, 'put');
  }

  public putGlobal$(entity: Partial<T>): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const data = await this.put$(entity);
        this._globalData$.pipe(take(1)).subscribe((da) => {
          this._globalData$.next(
            da.map((d) => {
              if (d.id == data.id) {
                return data;
              }

              return d;
            })
          );
          resolve();
        });
      } catch (err) {
        return reject(err);
      }
    });
  }

  public addDataGlobal(entities: T[]): void {
    this._globalData$
      .pipe(take(1))
      .subscribe((d: T[]) => this._globalData$.next([...d, ...entities]));
  }

  public updateDataGlobal(entity: T): void {
    this._globalData$.pipe(take(1)).subscribe((da: T[]) =>
      this._globalData$.next(
        da.map((d) => {
          if (d.id == entity.id) {
            return entity;
          }

          return d;
        })
      )
    );
  }

  public filteredData$(property: string, value: any): Observable<T[]> {
    return this._globalData$.pipe(
      map((datas) => datas.filter((data) => data[property] == value))
    );
  }

  public findPaginated(
    search: string = '',
    take: number = TAKE_DEFAULT,
    page: number = PAGE_WANTED_DEFAULT
  ): void {
    this._fetchingService.fetching = true;

    this._httpClient
      .get<PaginationResult<T>>(
        `${this._apiUrl}?search=${search}&take=${take}&page=${page}`
      )
      .pipe(
        map((paginationResult: PaginationResult<T>) =>
          this.convertPaginatedResponse(paginationResult)
        ),
        catchError(catchErrorOnPaginated())
      )
      .subscribe((data: PaginationResult<T>) => this._handleSubscribe(data));
  }

  public getAllWithRelations$(relations: string[]): Observable<T[]> {
    this.setFetching(true);

    const queries: string = relations.reduce((prev, next, index) => {
      return `${prev}${index === 0 ? '?' : '&'}relations[]=${next}`;
    }, '');

    return this._httpClient.get<T[]>(`${this._apiUrl}${queries}`).pipe(
      catchError(catchErrorOnArray()),
      take(1),
      map((data: T[]) => data.map(this._convertData)),
      finalize(() => this.setFetching(false))
    );
  }

  public getAllWithRelationsGlobal$(relations: string[]): void {
    this.getAllWithRelations$(relations).subscribe((data) =>
      this._globalData$.next(data)
    );
  }

  public delete$(entity: T): Promise<void> {
    this.setFetching(true);

    const result = this._httpClient.delete(`${this._apiUrl}/${entity.id}`).pipe(
      take(1),
      finalize(() => this.setFetching(false))
    );

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next(_) {
          return resolve();
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public deleteGlobal$(entity: T): Promise<void> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        await this.delete$(entity);
        this._globalData$.pipe(take(1)).subscribe((d) => {
          this._globalData$.next(d.filter(({id}) => entity.id != id));
          resolve();
        });
      } catch (err) {
        reject(err);
      }
    });
  }

  public wake$(entity: T): Promise<void> {
    this.setFetching(true);

    const result = this._httpClient
      .patch(`${this._apiUrl}/${entity.id}/wake`, {})
      .pipe(
        take(1),
        finalize(() => this.setFetching(false))
      );

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next(_) {
          return resolve();
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  protected _onError(item: T) {
    return (err: any, _: Observable<T>) => {
      console.log(err);

      return of(item);
    };
  }

  protected _handleSubscribe(data: PaginationResult<T>): void {
    this._fetchingService.fetching = false;
    this._paginatedResult$.next(data);
  }

  protected convertPaginatedResponse(
    data: PaginationResult<T>
  ): PaginationResult<T> {
    data.results = data.results.map(this._convertData);

    return data;
  }

  private _doActionOnEntity$(
    entity: Partial<T>,
    method: 'post' | 'put'
  ): Promise<T> {
    this.setFetching(true);

    const result = this._httpClient[method]<T>(
      `${this._apiUrl}${!!entity.id ? '/' + entity.id : ''}`,
      entity
    ).pipe(
      take(1),
      map(this._convertData),
      finalize(() => this.setFetching(false))
    );

    return new Promise<T>((resolve, reject) => {
      return result.subscribe({
        next(entity: T) {
          return resolve(entity);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }
}
