import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AgentCreationDialogComponent} from '../agent-creation-dialog/agent-creation-dialog.component';

@Component({
  selector: 'app-ressource-deletion-dialog',
  templateUrl: './ressource-deletion-dialog.component.html',
  styleUrls: ['./ressource-deletion-dialog.component.scss'],
})
export class RessourceDeletionDialogComponent implements OnInit {
  fetching = false;

  constructor(
    public readonly dialogRef: MatDialogRef<AgentCreationDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      description: string;
      validButton: string;
      cancelButton: string;
    }
  ) {}

  ngOnInit(): void {}

  public onClose(): void {
    this.dialogRef.close(false);
  }

  public onSave(): void {
    this.dialogRef.close(true);
  }
}
