import {Component, Input} from '@angular/core';
import {IdentifiedModel, NamedModel} from 'src/app/model/base-model.model';
import {CustomInputComponent} from '../custom-input/custom-input.component';

@Component({
  selector: 'app-custom-select',
  templateUrl: './custom-select.component.html',
  styleUrls: ['./custom-select.component.scss'],
})
export class CustomSelectComponent<
  T extends IdentifiedModel & NamedModel
> extends CustomInputComponent {
  @Input()
  items: T[];
  @Input()
  multiple: boolean = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
