import {Observable, of} from 'rxjs';
import {NamedModel} from '../model/base-model.model';
import {createDefault, PaginationResult} from '../model/pagination-result';

export function catchErrorOnArray<T>() {
  return (err: any, _: Observable<T[]>): Observable<T[]> => {
    console.log(err);

    return of([]);
  };
}

export function catchErrorOnPaginated<T>() {
  return (
    err: any,
    _: Observable<PaginationResult<T>>
  ): Observable<PaginationResult<T>> => {
    console.log(err);

    return of(createDefault<T>());
  };
}

export function catchErrorOnObject<T>() {
  return (err: any, _: Observable<T>): Observable<T> => {
    console.log(err);

    return of({} as T);
  };
}

export function stringifyNamedModels(data: NamedModel[]): string {
  return data.reduce((prev: string, next: NamedModel, index: number) => {
    let data: string = prev + next.getName();

    if (index !== data.length - 1) {
      data += '\r\n';
    }

    return data;
  }, '');
}
