<mat-card>
  <mat-card-title>Modèles d'email</mat-card-title>
  <mat-card-content>
    <!-- Zone de recherche -->
    <mat-form-field appearance="fill">
      <mat-label>Rechercher</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Rechercher par email ou description">
    </mat-form-field>

    <!-- Tableau d'emails -->
    <table mat-table [dataSource]="filteredEmails" class="mat-elevation-z8">
      <!-- Colonne pour l'adresse email -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef> Email </th>
        <td mat-cell *matCellDef="let email"> {{ email.email }} </td>
      </ng-container>

      <!-- Colonne pour la description -->
      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef> Description </th>
        <td mat-cell *matCellDef="let email"> {{ email.description }} </td>
      </ng-container>

      <!-- Colonne pour le sujet -->
      <ng-container matColumnDef="subject">
        <th mat-header-cell *matHeaderCellDef> Sujet </th>
        <td mat-cell *matCellDef="let email"> {{ email.subject }} </td>
      </ng-container>

      <!-- Colonne pour l'adresse destinataire -->
      <ng-container matColumnDef="to">
        <th mat-header-cell *matHeaderCellDef> Destinataire </th>
        <td mat-cell *matCellDef="let email"> {{ email.to }} </td>
      </ng-container>

      <!-- Colonne pour le statut -->
      

      <!-- Colonne pour envoyer au superadmin -->
      <ng-container matColumnDef="send_to_superadmin">
        <th mat-header-cell *matHeaderCellDef> Envoyer au Superadmin? </th>
        <td mat-cell *matCellDef="let email"> {{ email.send_to_superadmin ? 'Oui' : 'Non' }} </td>
      </ng-container>

      <!-- Colonne pour vérification téléphonique -->
      <ng-container matColumnDef="phone_verification">
        <th mat-header-cell *matHeaderCellDef> Vérification Téléphonique? </th>
        <td mat-cell *matCellDef="let email"> {{ email.phone_verification ? 'Oui' : 'Non' }} </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> Statut </th>
        <td mat-cell *matCellDef="let email">
          <span [ngClass]="{'status-active': email.status, 'status-inactive': !email.status}">
            {{ email.status ? 'Actif' : 'Inactif' }}
          </span>
        </td>
      </ng-container>
      <!-- Colonne Actions -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let email">
        
          <button mat-icon-button [routerLink]="['/admin/edit-email', email.id]" color="primary">
            <mat-icon>edit</mat-icon>
          </button>
        <!-- <button mat-icon-button color="warn" (click)="handleDelete(email)">
            <mat-icon>delete</mat-icon>
          </button> -->
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <!-- Pagination -->
    <mat-paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 50]" showFirstLastButtons></mat-paginator>
  </mat-card-content>
</mat-card>
