import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {take} from 'rxjs/operators';
import {ContextMenuAndAction, HeaderAndValue,} from 'src/app/common/table/custom-table/custom-table.component';
import {
  OperatingSiteCreationDialogComponent
} from 'src/app/dialog/operating-site-creation-dialog/operating-site-creation-dialog.component';
import {OperatingLocation} from 'src/app/model/operating-location.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {OperatingLocationService} from 'src/app/service/admin/operating-location.service';
import {OperatingSiteService} from 'src/app/service/admin/operating-site.service';
import {SNACKBAR_DEFAULT_DURATION} from 'src/constant/number.constants';
import {AbstractAdministrationComponent} from '../../abstract-administration.component';

@Component({
  selector: 'app-operating-site',
  templateUrl: './operating-site.component.html',
  styleUrls: ['./operating-site.component.scss'],
})
export class OperatingSiteComponent
  extends AbstractAdministrationComponent<OperatingSite>
  implements OnInit
{
  headersAndValues: HeaderAndValue<OperatingSite>[] = [
    {
      displayName: '#',
      propertyName: 'id',
      value: (operatingSite: OperatingSite) => operatingSite.id,
    },
    {
      displayName: 'Nom',
      propertyName: 'name',
      value: (operatingSite: OperatingSite) => operatingSite.name,
    },
  ];

  contextMenusAndActions: ContextMenuAndAction<OperatingSite>[] = [
    {
      name: (_: OperatingSite) => 'Mettre à jour',
      icon: 'mode_edit',
      color: 'primary',
      callback: (item: OperatingSite) => this.handleUpdate(item),
      canBeActivated: (item: OperatingSite) => !item.is_constant,
    },
    {
      name: (_: OperatingSite) => 'Supprimer',
      icon: 'delete',
      color: 'warn',
      callback: (item: OperatingSite) => this.handleDelete(item),
      canBeActivated: (item: OperatingSite) => !item.is_constant,
    },
  ];

  private _operatingLocations: OperatingLocation[] = [];

  constructor(
    private readonly _operatingLocationService: OperatingLocationService,
    protected readonly _service: OperatingSiteService,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {
    super(_service, _dialog, _snackBar);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._operatingLocationService
      .findAll$()
      .subscribe(
        (data: OperatingLocation[]) => (this._operatingLocations = data)
      );
  }

  public handleUpdate(operatingSite: OperatingSite | null = null): void {
    const dialogRef = this._dialog.open(OperatingSiteCreationDialogComponent, {
      data: {
        site: operatingSite,
        operatingLocations: this._operatingLocations,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe((result: any) => {
        if (!!result) {
          const { name }: OperatingSite = result as OperatingSite;
          this._snackBar.open(
            `Le site d'exploitation ${name} a été ${
              !!operatingSite ? 'modifié' : 'créé'
            } avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  public handleCreate(): void {
    this.handleUpdate();
  }
}
