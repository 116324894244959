import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Skill} from 'src/app/model/skill.model';
import {SkillService} from 'src/app/service/admin/skill.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-skill-creation-dialog',
  templateUrl: './skill-creation-dialog.component.html',
  styleUrls: ['./skill-creation-dialog.component.scss'],
})
export class SkillCreationDialogComponent
  extends AbstractDialogComponent<Skill>
  implements OnInit
{
  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Skill>>,
    @Inject(MAT_DIALOG_DATA) data: Skill | null,
    @Inject(SkillService) _skillService: SkillService
  ) {
    super(dialogRef, _skillService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<Skill> {
    const skill: Partial<Skill> = {
      name: this.inputValues.name,
    };

    if (!!this.data?.id) {
      skill.id = this.data!.id;
    }

    return skill;
  }

  protected _initializeData({ name }: Skill): void {
    this.inputValues['name'] = name;
  }
}
