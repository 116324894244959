import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {BankHolidayPlanning} from 'src/app/model/bank-holiday-planning.model';
import {BankHoliday} from 'src/app/model/bank-holiday.model';
import {Day} from 'src/app/model/day.model';
import {User} from 'src/app/model/user.model';
import {currentYear} from 'src/app/util/date.util';
import {OPERATING_LOCATION_ID} from 'src/constant/number.constants';
import {DayService} from '../admin/day.service';
import {FetchingService} from '../admin/fetching.service';
import {DateService} from '../date.service';
import {BankHolidayPlanningService} from '../view/bank-holiday-planning.service';
import {BankHolidayService} from '../view/bank-holiday.service';
import {PlanningService} from '../view/planning.service';

@Injectable({
  providedIn: 'root',
})
export class BankHolidayFacadeService implements OnDestroy {
  private _subscription: Subscription | null = null;
  private readonly _yearSelected$: BehaviorSubject<number> =
    new BehaviorSubject<number>(currentYear());

  constructor(
    private readonly _bankHolidayService: BankHolidayService,
    private readonly _bankHolidayPlanningService: BankHolidayPlanningService,
    private readonly _fetchingService: FetchingService,
    private readonly _dayService: DayService,
    private readonly _dateService: DateService,
    private readonly _planningService: PlanningService
  ) {
    this._bankHolidayService.getAllWithRelationsGlobal$(['*']);
    this._dayService.getAllWithRelationsGlobal$(['*']);
    this._subscription = this._yearSelected$.subscribe((year: number) =>
      this._bankHolidayPlanningService.getOnYearGlobal$(year)
    );
  }

  public ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public get bankHolidays$(): Observable<BankHoliday[]> {
    return this._bankHolidayService.globalData$;
  }

  public get bankHolidayPlannings$(): Observable<BankHolidayPlanning[]> {
    return this._bankHolidayPlanningService.globalData$;
  }

  public get yearSelected$(): Observable<number> {
    return this._yearSelected$;
  }

  public get fetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  public get days$(): Observable<Day[]> {
    return this._dayService.globalData$;
  }

  public get daysById$(): Observable<Map<number, Day>> {
    return this.days$.pipe(
      map((data: Day[]) =>
        data.reduce((acc, next: Day) => {
          acc.set(next.id, next);
          return acc;
        }, new Map())
      )
    );
  }

  public set year(newYear: number) {
    this._yearSelected$.next(newYear);
  }

  public set fetching(value: boolean) {
    this._fetchingService.fetching = value;
  }

  public handleYearChanged(quantity: number) {
    this._yearSelected$.next(this._yearSelected$.getValue() + quantity);
  }

  public getDayForGivenDate$(date: string): Observable<Day> {
    return this._dayService.getDayForGivenDate$(date);
  }

  public saveOrUpdateBankHolidayPlanning(
    data: Partial<BankHolidayPlanning>
  ): Promise<User[]> {
    return new Promise<User[]>(async (resolve, reject) => {
      try {
        const {planning, users_with_event_same_day} =
          await this._bankHolidayPlanningService.postOrUpdatePlanning$(data);
        if (!!data.id) {
          this._bankHolidayPlanningService.updateDataGlobal(planning);
        } else {
          this._bankHolidayPlanningService.addDataGlobal([planning]);
        }
        this._planningService.getOnWeekGlobal$(
          OPERATING_LOCATION_ID,
          this._dateService.weekSelected$.getValue(),
          this._dateService.yearSelected$.getValue()
        );

        return resolve(users_with_event_same_day);
      } catch (err) {
        return reject(err);
      }
    });
  }

  public saveMultiple(data: Partial<BankHolidayPlanning>[]): Promise<User[]> {
    return new Promise<User[]>(async (resolve, reject) => {
      try {
        const result = await this._bankHolidayPlanningService.saveMultiple$(
          data
        );

        this._planningService
          .getOnWeekGlobal$(
            OPERATING_LOCATION_ID,
            this._dateService.weekSelected$.getValue(),
            this._dateService.yearSelected$.getValue()
          );

        return resolve(
          result.reduce(
            (acc, next) => [
              ...acc,
              ...next.users_with_event_same_day.filter(
                ({id}) => !acc.some((user) => user.id == id)
              ),
            ],
            [] as User[]
          )
        );
      } catch (err) {
        return reject(err);
      }
    });
  }

  public deleteBankHolidayPlanning(data: BankHolidayPlanning): Promise<void> {
    return this._bankHolidayPlanningService.deleteGlobal$(data);
  }
}
