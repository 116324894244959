<table mat-table [dataSource]="dataSource" matSort>
  <ng-container
    *ngFor="let header of headersAndValues"
    matColumnDef="{{ header.propertyName }}"
  >
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ header.displayName }}
    </th>
    <td
      mat-cell
      *matCellDef="let item"
      (contextmenu)="onContextMenu($event, { item: item })"
      [ngClass]="{ 'is-constant': item.is_constant }"
    >
      {{ header.value(item) }}
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="headers"></tr>
  <tr class="custom-row" mat-row *matRowDef="let row; columns: headers"></tr>
</table>

<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition?.x"
  [style.top]="contextMenuPosition?.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu">
  <ng-template matMenuContent let-item="item">
    <button
      fxLayout="row"
      fxLayoutAlign="space-between"
      [color]="itemMenu.color"
      mat-button
      *ngFor="let itemMenu of contextMenusAndActions"
      (click)="handleContextMenuClick(itemMenu, item)"
      [disabled]="!itemMenu.canBeActivated(item)"
    >
      <mat-icon *ngIf="!!itemMenu.icon">{{ itemMenu.icon }}</mat-icon>
      <span>{{ itemMenu.name(item) }}</span>
    </button>
  </ng-template>
</mat-menu>
