<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">celebration</mat-icon>
    <span>Jours fériés</span>
  </mat-card-title>
  <div class="horizontal-delimiter"></div>
  <mat-card-content>
    <div
      *ngIf="!!yearSelected$"
      fxlayout="row"
      fxLayoutAlign="space-between center"
    >
      <div fxLayout="row" fxLayoutAlign="center center">
        <button mat-button (click)="handleChangeYear(-1)">
          <mat-icon>navigate_before</mat-icon>
        </button>
        <span
          *ngIf="!doubleClicked"
          (click)="doubleClicked = true"
          class="input-editable"
          matTooltipShowDelay="500"
          matTooltipClass="line-broken-tooltip"
          matTooltip="Cliquez pour changer l'année"
        >
          {{ yearSelected$ | async }}
        </span>
        <mat-form-field *ngIf="doubleClicked">
          <mat-label>Année</mat-label>
          <input
            matInput
            type="number"
            min="1900"
            max="2900"
            step="1"
            [value]="yearSelected$ | async"
            (keyup.enter)="handleNewYear($event)"
            (change)="handleUpOrDown($event)"
          />
        </mat-form-field>
        <button mat-button (click)="handleChangeYear(1)">
          <mat-icon>navigate_next</mat-icon>
        </button>
      </div>
      <div
        class="action-buttons-container"
        fxLayoutAlign="center center"
        fxLayout="row"
      >
        <button
          mat-raised-button
          color="primary"
          (click)="handleDuplicate()"
          matTooltipShowDelay="500"
          matTooltipClass="line-broken-tooltip"
          matTooltip="Dupliquer vers une nouvelle année"
        >
          <mat-icon>copy_all</mat-icon>
        </button>
        <button mat-raised-button color="accent" (click)="handleCreate()">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
    </div>

    <app-custom-table
      [headersAndValues]="headers"
      [contextMenusAndActions]="contextMenu"
      [dataSource]="dataSource"
    ></app-custom-table>
  </mat-card-content>
</mat-card>
