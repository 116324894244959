import {Directive, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Subscription} from 'rxjs';
import {IdentifiedModel, NamedModel} from '../model/base-model.model';
import {AbstractService} from '../service/abstractservice.service';

@Directive()
export abstract class AbstractDialogComponent<
  T extends IdentifiedModel & NamedModel
> implements OnInit, OnDestroy
{
  private _fetchingSubscription: Subscription | null = null;

  fetching = false;
  errors: { [any: string]: string } = {};

  inputValues: { [any: string]: any } = {};

  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<T>>,
    private readonly _service: AbstractService<T>,
    public data: T | null
  ) {}

  public abstract getDataToSend(): Partial<T>;
  protected abstract _initializeData(data: T): void;

  public handleChange(attribute: string, value: any): void {
    this.inputValues[attribute] = value;
  }

  ngOnInit(): void {
    this._fetchingSubscription = this._service
      .getFetching$()
      .subscribe((isFetching: boolean) => (this.fetching = isFetching));
  }

  ngOnDestroy(): void {
    this._fetchingSubscription?.unsubscribe();
  }

  public onSave(): void {
    const data: Partial<T> = this.getDataToSend();

    const promise: Promise<T> = !!this.data
      ? this._service.put$(data)
      : this._service.post$(data);

    promise
      .then((data: T) => {
        this.dialogRef.close(data);
      })
      .catch((err) => {
        if (!!err.errors) {
          Object.keys(err.errors).forEach(
            (attribute: string) =>
              (this.errors[attribute] = err.errors[attribute][0])
          );
        }
      });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  protected _initializeDataWhenNeeded(): void {
    if (!!this.data) {
      this._initializeData(this.data);
    }
  }
}
