export enum PlanningMode {
  BY_JOB = '0',
  BY_SERVICE = '1',
}

export enum ExcelExport {
  INCREASED_HOURS_ACCUMULATION = '0',
  POSTED_HOURS = '1',
  TEMP_WORKER_EQUITY = '2',
}
