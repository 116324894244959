import { Component, OnInit } from '@angular/core';
import { SettingsService } from '../../../service/admin/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';



@Component({
  selector: 'app-email-management',
  templateUrl: './email-management.component.html',
  styleUrls: ['./email-management.component.scss']
})
export class EmailManagementComponent implements OnInit {
  emailSettings = {
    smtp_host: '',
    smtp_username: '',
    smtp_password: '',
    smtp_port: '',
    smtp_from_address: '',
    smtp_from_name: '',
    smtp_encryption: 'none'
  };

  constructor(private emailService: SettingsService,private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.loadEmailSettings();
  }





  loadEmailSettings() {
    this.emailService.getEmailSettings().subscribe({
      next: (settings) => {
        this.emailSettings = settings;
      },
      error: (error) => {
        console.error('Erreur lors de la récupération des paramètres d\'email :', error);
      }
    });
  }

  onSave() {
    this.emailService.saveEmailSettings(this.emailSettings).subscribe({
      next: (response) => {
        console.log('Paramètres d\'email enregistrés avec succès :', response);
        this.snackBar.open('Paramètres d\'email enregistrés avec succès !', 'Fermer', {
          duration: 3000, 
        });
      },
      error: (error) => {
        console.error('Erreur lors de l\'enregistrement des paramètres d\'email :', error);
        this.snackBar.open('Erreur lors de l\'enregistrement des paramètres d\'email !', 'Fermer', {
          duration: 3000,
        });
      }
    });
  }



}
