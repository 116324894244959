import {ContentTable, TableCell, TDocumentDefinitions,} from 'pdfmake/interfaces';
import {Observable} from 'rxjs';
import {Day} from 'src/app/model/day.model';
import {ServicePlanningPrintFacadeService} from '../facade/service-planning-print-facade.service';
import {Injectable} from '@angular/core';
import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import {Event} from 'src/app/model/event.model';
import {EventVersion} from 'src/app/model/event-version.model';
import {createTextFromEvent, eventByDayByAgentID} from 'src/app/util/planning.util';
import {Agent} from 'src/app/model/agent.model';
import {PlannificationUtil} from 'src/app/util/plannification.util';
import {take} from 'rxjs/operators';
import {COLSPAN_BY_DAY_PERIOD} from 'src/constant/number.constants';
import {WorkSchedule} from 'src/app/model/work-schedule.model';
import {ActivityType} from 'src/app/model/activity-type.model';
import {Legend} from 'src/app/common/legend/draggable-legend/draggable-legend.component';
import {DateService} from "../date.service";
import {format} from "date-fns";
import {generateLegends} from "../../util/legends.util";
import { UserService } from 'src/app/service/user.service';
import { User } from 'src/app/model/user.model';

(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

const EMPTY_CELLS: TableCell[] = [
  {text: '', colSpan: COLSPAN_BY_DAY_PERIOD},
  ...new Array(COLSPAN_BY_DAY_PERIOD - 1).fill({text: ''}),
];

@Injectable({
  providedIn: 'root',
})
export class ServicePlanningPrint {
  private _state$: Observable<any>;
  public _is_planificateur: boolean;
  public _is_admin: boolean;
  public is_agent: boolean;
  public is_consultant: boolean;
  public _email: string;
  constructor(private readonly _servicePlanningPrintFacade: ServicePlanningPrintFacadeService,
              private readonly _plannificator: PlannificationUtil,
              private _dateService: DateService,
              private readonly _userService: UserService) {
    this._state$ = this._servicePlanningPrintFacade.getStateForAgent$();
    this._userService.getUser$().pipe(take(1)).subscribe((user: User) => {
      this._is_planificateur = user.is_planificateur;
      this._is_admin = user.is_admin;
      this.is_agent = user.is_agent;
      this.is_consultant = user.is_consultant;
      this._email = user.email;
    });

  }

  public createPdf() {
    this._state$
      .pipe(take(1))
      .subscribe(
        ({
           events,
           eventVersions,
           days,
           dates,
           agents,
           weekNumbers,
           years,
           workSchedules,
           activityTypes,
         }: {
          events: Event[][];
          eventVersions: (EventVersion | null)[];
          days: Day[];
          dates: Date[][];
          agents: Agent[];
          weekNumbers: number[];
          years: number[];
          workSchedules: WorkSchedule[];
          activityTypes: ActivityType[];
        }) => {
          const totalLength: number = dates.reduce(
            (acc, next) => acc + next.length,
            0
          );
          const legends: Legend[][] = generateLegends(
            workSchedules,
            activityTypes
          );
          const pdf: TDocumentDefinitions = {
            pageSize: 'A3',
            content: [
              {
                layout: {
                  hLineWidth: (_: number, __: ContentTable, ___: number) => 1,
                  vLineWidth: (_: number, __: ContentTable, ___: number) => 1,
                },
                table: {
                  headerRows: 3,
                  widths: [
                    '*',
                    ...new Array(COLSPAN_BY_DAY_PERIOD * totalLength * 2).fill(
                      'auto'
                    ),
                  ],
                  body: [
                    this._createFirstHeader(
                      weekNumbers,
                      eventVersions,
                      totalLength
                    ),
                    this._createSecondHeader(years, weekNumbers, days),
                    this._createThirdHeader(dates),
                    ...this._createAgentBody(agents, events, dates),
                  ],
                },
              },
              {
                text: 'Légendes',
                bold: true,
                alignment: 'left',
                margin: [0, 10, 0, 10],
              },
              {
                table: {
                  widths: new Array(legends[0].length).fill('auto'),
                  body: [...this._createLegendTableCells(legends[0])],
                },
                alignment: 'left',
                margin: [0, 0, 0, 10],
              },
              {
                table: {
                  widths: new Array(legends[1].length).fill('auto'),
                  body: [
                    ...this._createActivityTypeLegendTableCells(legends[1]),
                  ],
                },
                alignment: 'left',
              },
            ],
            defaultStyle: {
              fontSize: 10,
              alignment: 'center',
            },
            pageOrientation: 'landscape',
          };

          const weekFileName: string = weekNumbers.reduce(
            (acc, next, index: number) => {
              if (index == 0) {
                return 'Semaines ';
              }

              return `${acc} ${next}`;
            },
            ''
          );

          const yearsFileName: string = years.reduce(
            (acc, next, index: number) => {
              if (index == 0) {
                return `${next}`;
              }

              if (acc.includes(`${next}`)) {
                return acc;
              }

              return `${acc} - ${next}`;
            },
            ''
          );

          pdfMake
            .createPdf(pdf)
            .download(
              `Planning ${yearsFileName} - ${weekFileName} - ${new Date().getTime()}`
            );
        }
      );
  }

  private _createFirstHeader(
    weekNumbers: number[],
    versions: (EventVersion | null)[],
    totalLength: number
  ): TableCell[] {
    //this._service.getName()
    const lengthByWeek: number = totalLength / weekNumbers.length;
    return [
      {text: 'EPCU'},
      ...weekNumbers
        .map((week: number, index: number) => {
          return [
            {
              text: `Semaine ${week} (${
                !!versions[index]
                  ? `V${versions[index]?.version}`
                  : 'non validé'
              })`,
              colSpan: 2 * COLSPAN_BY_DAY_PERIOD * lengthByWeek,
              fontSize: 5,
            },
            ...new Array(2 * COLSPAN_BY_DAY_PERIOD * lengthByWeek - 1).fill({
              text: '',
            }),
          ];
        })
        .reduce((acc, next) => [...acc, ...next]),
    ];
  }

  private _createSecondHeader(
    years: number[],
    weekNumbers: number[],
    days: Day[]
  ): TableCell[] {
    return [
      {
        text: years.reduce((acc, next, index: number) => {
          if (index == 0) {
            return `${next}`;
          }

          if (acc.includes(`${next}`)) {
            return acc;
          }

          return `${acc} - ${next}`;
        }, ''),
        fontSize: 5,
        bold: true,
      },
      ...weekNumbers
        .map((_) => days.map((day: Day) => day.getName()))
        .reduce((map, next) => [...map, ...next], [])
        .map((day: string) => [
          {text: day, fontSize: 5, colSpan: 2 * COLSPAN_BY_DAY_PERIOD},
          ...new Array(2 * COLSPAN_BY_DAY_PERIOD - 1).fill({text: ''}),
        ])
        .reduce((acc, next) => [...acc, ...next]),
    ];
  }

  private _createThirdHeader(dates: Date[][]): TableCell[] {
    return [
      {text: ''},
      ...dates
        .map((ds: Date[]) =>
          ds
            .map((d: Date) => {
              return [
                {
                  text: `${format(d, 'dd.MM')}`,
                  fontSize: 5,
                  colSpan: 2 * COLSPAN_BY_DAY_PERIOD,
                },
                ...new Array(2 * COLSPAN_BY_DAY_PERIOD - 1).fill({text: ''}),
              ];
            })
            .reduce((acc, next) => [...acc, ...next])
        )
        .reduce((acc, next) => [...acc, ...next]),
    ];
  }

  private _createAgentBody(
    agents: Agent[],
    eventsByWeek: Event[][],
    dates: Date[][]
  ): TableCell[][] {
    const allEvents: Map<number, Map<string, Event[]>> = eventsByWeek
      .map((events: Event[]) => {
        const map: Map<number, Map<string, Event[]>> = eventByDayByAgentID(
          this._is_planificateur || this._is_admin,
          events
        );
        return map;
      })
      .reduce((acc, next) => {
        next.forEach((value: Map<string, Event[]>, agentId: number) => {
          if (!acc.has(agentId)) {
            acc.set(agentId, value);
          } else {
            value.forEach((events: Event[], date: string) => {
              acc.get(agentId)!.set(date, events);
            });
          }
        });
        return acc;
      }, new Map());

    const datesFormatted: string[] = dates
      .map((ds: Date[]) => ds.map((d: Date) => this._dateService.formatDate(d)))
      .reduce((acc, next) => [...acc, ...next]);

    return agents.map((agent: Agent) => {
      return [
        {text: agent.getName(), bold: true, alignment: 'right'},
        ...datesFormatted
          .map((date: string) => {
            const {morning, day, afternoon} =
              this._plannificator.getEventByPeriod(
                allEvents.get(agent.id)?.get(date) ?? []
              );

            const cells: TableCell[] = [];

            if (morning.length == 0) {
              if (day.length == 0) {
                cells.push(...EMPTY_CELLS);
              }
            } else {
              const colSpan =
                COLSPAN_BY_DAY_PERIOD / (morning.length + day.length);
              morning.forEach((morningEvent: Event) => {
                cells.push(this._createTableCell(morningEvent, colSpan));
                if (colSpan - 1 > 0) {
                  cells.push(
                    ...new Array(Math.round(colSpan - 1)).fill({text: ''})
                  );
                }
              });
            }

            if (day.length > 0) {
              const colSpan =
                COLSPAN_BY_DAY_PERIOD / (afternoon.length + day.length) +
                COLSPAN_BY_DAY_PERIOD / (morning.length + day.length);
              day.forEach((dayEvent: Event) => {
                cells.push(this._createTableCell(dayEvent, colSpan));
                if (colSpan - 1 > 0) {
                  cells.push(
                    ...new Array(Math.round(colSpan - 1)).fill({text: ''})
                  );
                }
              });
            }

            if (afternoon.length == 0) {
              if (day.length == 0) {
                cells.push(...EMPTY_CELLS);
              }
            } else {
              const colSpan =
                COLSPAN_BY_DAY_PERIOD / (afternoon.length + day.length);
              afternoon.forEach((afternoonEvent: Event) => {
                cells.push(this._createTableCell(afternoonEvent, colSpan));
                if (colSpan - 1 > 0) {
                  cells.push(
                    ...new Array(Math.round(colSpan - 1)).fill({text: ''})
                  );
                }
              });
            }
            return cells;
          })
          .reduce((acc, next) => [...acc, ...next]),
      ];
    });
  }

  private _createTableCell(event: Event, colSpan: number): TableCell {
    return {
      text: createTextFromEvent(event),
      fillColor: event.activity_type.isIndisponibility()
        ? '#8b8b8b'
        : event.work_schedule.legend_color,
      colSpan,
      fontSize: 5,
    };
  }

  private _createLegendTableCells(legends: Legend[]): TableCell[][] {
    return [
      legends.map(({name}: Legend) => ({text: name})),
      legends.map(({color}: Legend) => ({text: '', fillColor: color})),
    ];
  }

  private _createActivityTypeLegendTableCells(
    legends: Legend[]
  ): TableCell[][] {
    return [
      legends.map(({name}: Legend) => ({text: name.split(':')[0]})),
      legends.map(({name}: Legend) => ({text: name.split(':')[1]})),
    ];
  }
}
