import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree,} from '@angular/router';
import {Observable} from 'rxjs';
import {User} from "../model/user.model";
import {UserService} from "../service/user.service";
import {map} from "rxjs/operators";
import {PlannificationUtil} from "../util/plannification.util";

@Injectable({
  providedIn: 'root',
})
export class PlanificatorGuard implements CanActivate {
  constructor(private readonly _userService: UserService,
              private readonly _router: Router) {
  }

  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._userService.getUser$()
      .pipe(
        map((user: User) => {
          if (PlannificationUtil.canAccess(user)) {
            return true;
          }
          return this._router.parseUrl('/view/planning-consult');
        })
      );

  }
}
