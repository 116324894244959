import {Component, Input} from '@angular/core';
import {CustomInputComponent} from '../custom-input/custom-input.component';

@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
})
export class CustomTextareaComponent extends CustomInputComponent {
  @Input()
  public cols: number = 30;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
