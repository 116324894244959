import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Skill} from 'src/app/model/skill.model';

import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const SKILL_URL: string = '/api/skill';

@Injectable({
  providedIn: 'root',
})
export class SkillService extends AbstractService<Skill> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, SKILL_URL);
  }

  public findSkills$(): Observable<Skill[]> {
    this.setFetching(true);

    return this._httpClient.get<Skill[]>(`${SKILL_URL}?relations[]=`).pipe(
      catchError(catchErrorOnArray()),
      map((services: Skill[]) => {
        return services.map(this._convertData);
      }),
      take(1),
      finalize(() => this.setFetching(false))
    );
  }

  protected override _convertData({ id, name, is_constant }: Skill): Skill {
    return new Skill(id, name, is_constant);
  }
}
