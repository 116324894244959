import {Directive, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {PageEvent} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {BehaviorSubject, Subscription} from 'rxjs';
import {take} from 'rxjs/operators';
import {CustomTableComponent} from 'src/app/common/table/custom-table/custom-table.component';
import {
  RessourceDeletionDialogComponent
} from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import {IdentifiedModel, NamedModel} from 'src/app/model/base-model.model';
import {PaginationResult} from 'src/app/model/pagination-result';
import {AbstractService} from 'src/app/service/abstractservice.service';
import {PAGE_WANTED_DEFAULT, SNACKBAR_DEFAULT_DURATION, TAKE_DEFAULT,} from 'src/constant/number.constants';

@Directive()
export abstract class AbstractAdministrationComponent<
  T extends IdentifiedModel & NamedModel
> implements OnInit, OnDestroy
{
  search$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  isFetching: boolean = false;
  dataSource = new MatTableDataSource<T>([]);
  total: number = 0;
  previous: boolean = false;
  next: boolean = false;

  pageIndex: number = PAGE_WANTED_DEFAULT;
  pageSize: number = TAKE_DEFAULT;

  @ViewChild(CustomTableComponent) set content(
    content: CustomTableComponent<T>
  ) {
    if (!!content) {
      this.dataSource.sort = content.sort;
    }
  }

  protected _fetchingSubscription$: Subscription | null = null;
  private _searchSubscription$: Subscription | null = null;
  private _abstractServiceSubscription$: Subscription | null = null;

  constructor(
    protected readonly _service: AbstractService<T>,
    protected readonly _dialog: MatDialog,
    protected readonly _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this._fetchingSubscription$ = this._service
      .getFetching$()
      .subscribe((fetching: boolean) => (this.isFetching = fetching));

    this._abstractServiceSubscription$ = this._service
      .getPaginatedResult$()
      .subscribe(({ next, previous, results, total }: PaginationResult<T>) => {
        this.dataSource.data = results;
        this.next = next;
        this.previous = previous;
        this.total = total;
      });

    this._searchSubscription$ = this.search$.subscribe((value) =>
      this._handleFindPaginated(value)
    );
  }

  ngOnDestroy(): void {
    this._fetchingSubscription$?.unsubscribe();
    this._abstractServiceSubscription$?.unsubscribe();
    this._searchSubscription$?.unsubscribe();
  }

  public handlePageEvent(pageEvent: PageEvent): void {
    this.pageIndex = pageEvent.pageIndex;
    this.pageSize = pageEvent.pageSize;

    this._handleFindPaginated(this.search$.value);
  }

  public handleDelete(item: T): void {
    const dialogRef = this._dialog.open(RessourceDeletionDialogComponent);

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (doDeletion: boolean) => {
        if (doDeletion) {
          await this._service.delete$(item);
          this._snackBar.open(
            `${item.getName()} a supprimée avec succès !`,
            'Fermer',
            {
              duration: SNACKBAR_DEFAULT_DURATION,
            }
          );
          this._handleFindPaginated(this.search$.value);
        }
      });
  }

  protected _handleFindPaginated(search: string): void {
    this._service.findPaginated(search, this.pageSize, this.pageIndex + 1);
  }
}
