import {WeekAndMonth} from '../service/facade/astreinte-facade.service';

export function getWeeksByMonth(
  weekAndMonth: WeekAndMonth[]
): Map<string, number> {
  const map: Map<string, number> = new Map<string, number>();

  weekAndMonth
    .filter(({ month }: WeekAndMonth) => month.length == 1)
    .forEach(({ month }: WeekAndMonth) => {
      const monthString: string = month[0];
      if (!map.has(monthString)) {
        map.set(monthString, 0);
      }
      map.set(monthString, map.get(monthString)! + 1);
    });

  weekAndMonth
    .filter(({ month }: WeekAndMonth) => month.length == 2)
    .forEach(({ month, week }: WeekAndMonth) => {
      const firstMonth: string = month[0];
      // Special case : week 52-53 or week 1 déc./janv.
      if (firstMonth.startsWith('déc')) {
        if (week == 1) {
          map.set(month[1], map.get(month[1])! + 1);
        }

        if (week > 1) {
          if (!map.has(month[0])) {
            map.set(month[0], 1);
          } else {
            map.set(month[0], map.get(month[0])! + 1);
          }
        }
      } else {
        map.set(month[0], map.get(month[0])! + 0.5);
        map.set(month[1], map.get(month[1])! + 0.5);
      }
    });
  return map;
}
