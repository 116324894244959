import {Component, Input} from '@angular/core';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-custom-search-input',
  templateUrl: './custom-search-input.component.html',
  styleUrls: ['./custom-search-input.component.scss']
})
export class CustomSearchInputComponent {

  @Input()
  search$: Subject<string>;

  constructor() { }

  public inputChange(event: string): void {
    this.search$.next(event);
  }

  public resetInput(): void {
    this.search$.next('');
  }

}
