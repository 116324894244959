import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Company} from 'src/app/model/company.model';
import {CompanyService} from 'src/app/service/admin/company.service';
import {AbstractDialogComponent} from '../abstract-dialog.directive';

@Component({
  selector: 'app-company-creation-dialog',
  templateUrl: './company-creation-dialog.component.html',
  styleUrls: ['./company-creation-dialog.component.scss'],
})
export class CompanyCreationDialogComponent
  extends AbstractDialogComponent<Company>
  implements OnInit
{
  constructor(
    public readonly dialogRef: MatDialogRef<AbstractDialogComponent<Company>>,
    @Inject(MAT_DIALOG_DATA) data: Company | null,
    protected readonly _companyService: CompanyService
  ) {
    super(dialogRef, _companyService, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._initializeDataWhenNeeded();
  }

  public getDataToSend(): Partial<Company> {
    const company: Partial<Company> = {
      name: this.inputValues.name,
    };

    if (!!this.data?.id) {
      company.id = this.data!.id;
    }

    return company;
  }

  protected override _initializeData(data: Company): void {
    this.inputValues['name'] = data.name;
  }
}
