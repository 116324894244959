import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DAYS_QUANTITY_FOR_PLANIFICATOR} from 'src/constant/number.constants';
import {currentWeek, currentYear, getStartOfWeekDate,} from '../util/date.util';
import {DatePipe} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class DateService {
  private readonly _datesSelected: BehaviorSubject<Date[]> = new BehaviorSubject<Date[]>([]);
  private readonly _yearSelected: BehaviorSubject<number> = new BehaviorSubject<number>(currentYear());
  private readonly _weekSelected: BehaviorSubject<number> = new BehaviorSubject<number>(currentWeek());
  private readonly _numberOfDaysWanted$: BehaviorSubject<number> = new BehaviorSubject<number>(DAYS_QUANTITY_FOR_PLANIFICATOR);

  constructor(private datePipe: DatePipe) {
  }


  public get dates$(): BehaviorSubject<Date[]> {
    return this._datesSelected;
  }

  public get weekSelected$(): BehaviorSubject<number> {
    return this._weekSelected;
  }

  public get yearSelected$(): BehaviorSubject<number> {
    return this._yearSelected;
  }

  public set year(year: number) {
    this._yearSelected.next(year);
  }

  public set week(week: number) {
    this._weekSelected.next(week);
  }

  public set daysWanted(quantity: number) {
    if (this._numberOfDaysWanted$.getValue() != quantity) {
      this._numberOfDaysWanted$.next(quantity);
    }
  }

  public get datesString$(): Observable<string[]> {
    return this._datesSelected.pipe(
      map((datas) => datas.map((date) => this.formatDate(date)))
    );
  }

  public getWeekDatesOnGivenWeekNumber(week: number,
                                       year: number): Date[] {
    const startOfWeekDate: Date = getStartOfWeekDate(week, year);
    return new Array(this._numberOfDaysWanted$.getValue())
      .fill('')
      .map((_, index) => {
        const date: Date = new Date(startOfWeekDate);
        date.setDate(date.getDate()+ index);
        return date;
      });
  }

  public getWeekDatesOnGivenWeekNumberGlobal$(): void {
    this._datesSelected.next(
      this.getWeekDatesOnGivenWeekNumber(
        this._weekSelected.getValue(),
        this._yearSelected.getValue()
      )
    );
  }

  public formatDate(date: Date): string {
    return <string>this.datePipe.transform(date, 'yyyy-MM-dd');
  }
}
