import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {OperatingSite} from './operating-site.model';

export class OperatingLocation
  implements IdentifiedModel, NamedModel, ConstantModel
{
  constructor(
    public id: number,
    public name: string,
    public operating_sites: OperatingSite[],
    public is_constant: boolean
  ) {
    this.operating_sites = operating_sites.map(
      ({
        id,
        name,
        is_constant,
        operating_location,
        operating_location_id,
      }: OperatingSite) =>
        new OperatingSite(
          id,
          name,
          is_constant,
          operating_location_id,
          operating_location
        )
    );
  }

  public getName(): string {
    return this.name;
  }
}
