import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {take} from 'rxjs/operators';
import {HeaderService} from 'src/app/service/header.service';
import {TOKEN_STORAGE_KEY, USER_STORAGE_KEY,} from 'src/constant/string.constant';
import {ExportExcelDialogComponent} from '../dialog/export-excel-dialog/export-excel-dialog.component';
import {User} from '../model/user.model';
import {AuthService} from '../service/auth.service';
import {LocalStorageService} from '../service/local-storage.service';
import {UserService} from '../service/user.service';
import {PlannificationUtil} from "../util/plannification.util";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  title: string = '';
  user: User;

  constructor(
    private readonly _headerService: HeaderService,
    private readonly _dialog: MatDialog,
    private readonly _localStorageService: LocalStorageService,
    private readonly _router: Router,
    private readonly _userService: UserService,
    private readonly _authService: AuthService
  ) {}

  ngOnInit(): void {
    this._headerService
      .getTitle$()
      .subscribe((newValue: string) => (this.title = newValue));
    this._userService.getUser$().subscribe((user: User) => (this.user = user));
  }

  public logout(): void {
    this._authService
      .logout()
      .then(() => {
        this._localStorageService.remove(USER_STORAGE_KEY);
        this._localStorageService.remove(TOKEN_STORAGE_KEY);
        this._userService.disconnect();
        this._router.navigate(['/auth/login']);
      })
      .catch((err) => console.log(err));
  }

  public handleExportSelected(value: string): void {
    const dialogRef = this._dialog.open(ExportExcelDialogComponent, {
      data: {
        type: value,
      },
    });

    dialogRef.afterClosed().pipe(take(1)).subscribe();
  }

  get canAccessPlanificator(): boolean {
    return PlannificationUtil.canAccess(this.user);
  }



}
