import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Observable} from 'rxjs';
import {take} from 'rxjs/operators';
import {Event} from 'src/app/model/event.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {PlanningFacadeService} from 'src/app/service/facade/planning-facade.service';
import {PlannificationUtil} from 'src/app/util/plannification.util';
import {AbstractRowDirective} from '../../abstract-row.directive';
import {ActivityContextMenuComponent} from '../../activity-context-menu/activity-context-menu.component';
import { NotificationService } from '../../../../../service/admin/notification.service';
import { Directive, Input } from '@angular/core';

@Component({
  selector: '[app-agent-row]',
  templateUrl: './agent-row.component.html',
  styleUrls: ['./agent-row.component.scss'],
})
export class AgentRowComponent extends AbstractRowDirective implements OnInit, OnDestroy {
  @ViewChild(ActivityContextMenuComponent)
   activityContextMenuComponent: ActivityContextMenuComponent;

  isLatestEventVersionSelected$: Observable<boolean>;
  @Input() currentWeek: any;
  constructor(protected readonly _dialog: MatDialog,
              protected readonly _snackBar: MatSnackBar,
              protected readonly _planningFacade: PlanningFacadeService,
              private notificationService: NotificationService,
              protected readonly _plannificator: PlannificationUtil) {
    super(_dialog, _snackBar, _planningFacade,_plannificator);
    this.isLatestEventVersionSelected$ = this._planningFacade.isLatestEventVersionSelected$;
  }


  

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  
  sendNotification(agent: any) {
    if(agent.tel_perso != null) {
        this.sendSms(agent.tel_perso , this._planningFacade.week);
    }

    if(agent.mail_perso != null) {
      setTimeout(() => {
        this.sendEmail(agent.mail_perso , this._planningFacade.week);
      }, 3000); 
    }

  }
  
  

  sendEmail(email:string , week:number): void {
    this.notificationService.sendEmail(email , week).subscribe(
      response => {
        console.log('Email sent successfully:', response);
        this._snackBar.open('Notification E-mail avec succès !', 'Fermer', {
          duration: 3000, 
        });

      },
      error => {
        console.error('Error sending email:', error);
      }
    );
  }


  sendSms(tel:string, week:number): void {
    this.notificationService.sendSms(tel , week).subscribe(
      response => {
        console.log('SMS sent successfully:', response);
        this._snackBar.open('Notification SMS avec succès !', 'Fermer', {
          duration: 3000, 
        });
      },
      error => {
        console.error('Error sending SMS:', error);
      }
    );
  }

   onContextMenu(event: MouseEvent,
                       item:
                         | { site: OperatingSite; date: string; day_period: number }
                         | { event: Event }) {
    this.isLatestEventVersionSelected$.pipe(take(1)).subscribe((isLatestEventVersionSelected: boolean) => {
      if (isLatestEventVersionSelected) {
        this._setMenuPosition(event);
        this.activityContextMenuComponent.contextMenu.menuData = item;
        this.activityContextMenuComponent.contextMenu.menu.focusFirstItem(
          'mouse'
        );
        this.activityContextMenuComponent.contextMenu.openMenu();
      } else {
        event.preventDefault();
      }
    });
  }
}
