import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, map, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {Agent} from 'src/app/model/agent.model';
import {Company} from 'src/app/model/company.model';

import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const COMPANY_URL: string = '/api/company';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends AbstractService<Company> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, COMPANY_URL);
  }

  public updateUsers$(company: Company, agent: Agent[]): Promise<Company> {
    const userIds: number[] = agent.map(({ id }: Agent) => id);
    this.setFetching(true);

    const result = this._httpClient
      .post<Company>(`${COMPANY_URL}/${company.id}/users`, {
        users: userIds,
      })
      .pipe(
        catchError(this._onError(company)),
        take(1),
        finalize(() => this.setFetching(false))
      );

    return new Promise<Company>((resolve, reject) => {
      return result.subscribe({
        next(data: Company) {
          return resolve(data);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public findAllWithRelations$(): Observable<Company[]> {
    this.setFetching(true);

    return this._httpClient
      .get<Company[]>(`${COMPANY_URL}?relations[]=services&relations[]=users`)
      .pipe(
        catchError(catchErrorOnArray()),
        map((companies: Company[]) => companies.map(this._convertData)),
        take(1),
        finalize(() => this.setFetching(false))
      );
  }

  protected override _convertData({
    id,
    name,
    is_constant,
    services,
    users,
  }: Company): Company {
    return new Company(id, name, is_constant, services, users);
  }
}
