<div cdkDropListGroup fxLayout="row" fxLayoutAlign="space-between flex-start">
  <div class="dialog-table-container">
    <h2>Disponible(s)</h2>

    <div
      cdkDropList
      #availableList="cdkDropList"
      [cdkDropListConnectedTo]="assignedList"
      [cdkDropListData]="availableItems"
      class="dialog-table-list"
      (cdkDropListDropped)="drop($event)">
      <div class="dialog-table-box" *ngFor="let item of availableItems" cdkDrag>{{item.getName()}}</div>
    </div>
  </div>
  
  <div class="dialog-table-container">
    <h2>Déjà assigné(s)</h2>

    <div
      cdkDropList
      #assignedList="cdkDropList"
      [cdkDropListConnectedTo]="availableList"
      [cdkDropListData]="assignedItems"
      class="dialog-table-list"
      (cdkDropListDropped)="drop($event)">
      <div class="dialog-table-box" *ngFor="let item of assignedItems" [cdkDragDisabled]="disabledDeassigned" cdkDrag>{{item.getName()}}</div>
    </div>
  </div>
</div>
