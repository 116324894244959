import {Agent} from "../model/agent.model";


export const sortAlphabeticallyByName = (agents: Agent[]): Agent[] => agents
  .sort((agentA, agentB) => {
    if (agentA.getName() < agentB.getName()) {
      return -1;
    }
    if (agentA.getName() > agentB.getName()) {
      return 1;
    }
    return 0;
  })

