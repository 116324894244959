import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Token} from '../model/token.model';
import {User} from '../model/user.model';

const AUTH_URL = '/auth';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly _httpClient: HttpClient) {}

  public login(
    unique_identifier: string,
    password: string
  ): Promise<{ token: Token; user: User }> {
    const result = this._httpClient.post<{ token: Token; user: User }>(
      `${AUTH_URL}/login`,
      {
        unique_identifier: unique_identifier,
        password: password,
      }
    );

    return new Promise<{ token: Token; user: User }>((resolve, reject) => {
      return result.subscribe({
        next(token) {
          return resolve(token);
        },

        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public logout(): Promise<void> {
    const result = this._httpClient.post<void>(`${AUTH_URL}/logout`, {});

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next() {
          return resolve();
        },
        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public resetPassword(
    unique_identifier: string,
    password: string,
    password_confirmation: string,
    token: string
  ): Promise<void> {
    const result = this._httpClient.post<void>(`${AUTH_URL}/password/reset`, {
      unique_identifier: unique_identifier,
      password: password,
      password_confirmation: password_confirmation,
      token: token,
    });

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next(_) {
          return resolve();
        },
        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public changePassword(
    password: string,
    password_confirmation: string
  ): Promise<void> {
    const result = this._httpClient.post<void>(`${AUTH_URL}/password/change`, {
      password: password,
      password_confirmation: password_confirmation,
    });

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next(_) {
          return resolve();
        },
        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public sendMailForResettingPassword(
    unique_identifier: string
  ): Promise<void> {
    const result = this._httpClient.post<void>(`${AUTH_URL}/forgot-password`, {
      unique_identifier: unique_identifier,
    });

    return new Promise<void>((resolve, reject) => {
      return result.subscribe({
        next(_) {
          return resolve();
        },
        error(error: HttpErrorResponse) {
          return reject(error.error);
        },
      });
    });
  }

  public testToken(): Promise<boolean> {
    const result = this._httpClient.get<void>(`${AUTH_URL}/test`);

    return new Promise<boolean>((resolve, _) => {
      return result.subscribe({
        next(_) {
          return resolve(true);
        },
        error(_: HttpErrorResponse) {
          return resolve(false);
        },
      });
    });
  }
}
