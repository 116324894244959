import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subscription} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Agent} from 'src/app/model/agent.model';
import {Astreinte} from 'src/app/model/astreinte.model';
import {getWeeksByMonth} from 'src/app/util/astreinte.util';
import {currentYear, getMonthNameFromWeekNumber, getWeeksInYear,} from 'src/app/util/date.util';
import {ASTREINTE_SKILL_ID} from 'src/constant/number.constants';
import {AgentService} from '../admin/agent.service';
import {FetchingService} from '../admin/fetching.service';
import {AstreintePlanningPrintCreator} from '../creator/astreinte-planning-print.creator';
import {AstreinteResponse, AstreinteService} from '../view/astreinte.service';
import {PlanningService} from '../view/planning.service';

export interface WeekAndMonth {
  week: number;
  month: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AstreinteFacadeService implements OnDestroy {
  private _subscription: Subscription | null = null;
  private readonly _yearSelected$: BehaviorSubject<number> =
    new BehaviorSubject<number>(currentYear());
  private readonly _weeksSelectedByAgent: Map<
    number,
    BehaviorSubject<number[]>
  > = new Map();

  constructor(private readonly _agentService: AgentService,
              private readonly _planningService: PlanningService,
              private readonly _fetchingService: FetchingService,
              private readonly _astreinteService: AstreinteService) {
    this._agentService.getAllWithRelationsGlobal$(['skills']);
    this._planningService.getAllWithRelationsGlobal$(['*']);

    this._yearSelected$.subscribe((year: number) =>
      this._astreinteService.getOnYearGlobal$(year)
    );
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  public get fetching$(): Observable<boolean> {
    return this._fetchingService.fetching$;
  }

  public get agents$(): Observable<Agent[]> {
    return this._agentService.globalData$.pipe(
      map((agents: Agent[]) =>
        agents.filter(({skills}) =>
          skills?.some(({id}) => id == ASTREINTE_SKILL_ID)
        )
      )
    );
  }

  public get weekNumbers$(): Observable<number[]> {
    return this._yearSelected$.pipe(
      map((year: number) =>
        new Array(getWeeksInYear(year)).fill(1).map((d, i) => d + i)
      )
    );
  }

  public get weekAndMonth$(): Observable<WeekAndMonth[]> {
    return this._yearSelected$.pipe(
      map((year: number) =>
        new Array(getWeeksInYear(year))
          .fill(1)
          .map((d, i) => d + i)
          .map((weekNumber: number) => {
            return {
              week: weekNumber,
              month: getMonthNameFromWeekNumber(weekNumber, year),
            };
          })
      )
    );
  }

  public get astreintesByAgent$(): Observable<Map<number, Astreinte[]>> {
    return this._astreinteService.globalData$.pipe(
      map((astreintes: Astreinte[]) =>
        astreintes.reduce((map, next: Astreinte) => {
          if (!map.has(next.agent_id)) {
            map.set(next.agent_id, []);
          }
          map.get(next.agent_id)!.push(next);
          return map;
        }, new Map<number, Astreinte[]>())
      )
    );
  }

  public get year$(): Observable<number> {
    return this._yearSelected$;
  }

  public set year(value: number) {
    this._yearSelected$.next(value);
  }

  public getSelectionForGivenAgent$({id}: Agent): Observable<number[]> {
    if (!this._weeksSelectedByAgent.has(id)) {
      this._weeksSelectedByAgent.set(id, new BehaviorSubject<number[]>([]));
    }
    return this._weeksSelectedByAgent.get(id)!;
  }

  public setSelectionForGivenAgent({id}: Agent, selected: number[]): void {
    this._weeksSelectedByAgent.get(id)!.next(selected);
  }

  public resetSelection(): void {
    [...this._weeksSelectedByAgent.keys()].forEach((k: number) =>
      this._weeksSelectedByAgent.get(k)!.next([])
    );
  }

  public saveSelection(): Promise<{ [weekNumber: string]: Agent[] }> {
    this._fetchingService.fetching = true;

    return new Promise<{ [weekNumber: string]: Agent[] }>(
      async (resolve, reject) => {
        this.astreintesByAgent$
          .pipe(take(1))
          .subscribe(async (map: Map<number, Astreinte[]>) => {
            this._weeksSelectedByAgent.forEach((observable, agentId) => {
              observable.next(
                observable
                  .getValue()
                  .filter(
                    (weekNumber: number) =>
                      !map.get(agentId)?.some(({week}) => weekNumber == week)
                  )
              );
            });
            const data: Partial<Astreinte>[] = [
              ...this._weeksSelectedByAgent.entries(),
            ]
              .filter(([_, observable]) => observable.getValue().length > 0)
              .reduce((acc, [userId, observable]) => {
                return [
                  ...acc,
                  ...observable.getValue().map((week: number) => ({
                    agent_id: userId,
                    year: this._yearSelected$.getValue(),
                    week: week,
                  })),
                ];
              }, [] as { agent_id: number; year: number; week: number }[]);

            try {
              const result: AstreinteResponse[] =
                await this._astreinteService.saveMultiple$(data);

              this._astreinteService.getOnYearGlobal$(
                this._yearSelected$.getValue()
              );

              const uniqueUserPerWeek = result.reduce(
                (acc, next: AstreinteResponse) => {
                  const keys: string[] = Object.keys(
                    next.user_with_event_during_astreinte
                  );
                  keys.forEach((key: string) => {
                    if (!!acc[key]) {
                      acc[key] = [
                        ...acc[key],
                        ...next.user_with_event_during_astreinte[key].filter(
                          ({id}: Agent) =>
                            !acc[key].some((agent: Agent) => agent.id == id)
                        ),
                      ];
                    } else {
                      acc[key] = next.user_with_event_during_astreinte[key];
                    }
                  });

                  return acc;
                },
                {} as { [weekNumber: string]: Agent[] }
              );

              return resolve(uniqueUserPerWeek);
            } catch (err) {
              return reject(err);
            } finally {
              this.resetSelection();
              this._fetchingService.fetching = false;
            }
          });
      }
    );
  }

  public deleteSelection(): void {
    this.astreintesByAgent$
      .pipe(take(1))
      .subscribe((map: Map<number, Astreinte[]>) => {
        const astreinteToDelete: Astreinte[] = [];
        this._weeksSelectedByAgent.forEach((weeks, agentId) => {
          map
            .get(agentId)
            ?.filter(({week}) =>
              weeks.getValue().some((w: number) => w == week)
            )
            .forEach((astreinte: Astreinte) =>
              astreinteToDelete.push(astreinte)
            );
        });
        this._astreinteService.deleteMultipleGlobal$(astreinteToDelete);
        this.resetSelection();
      });
  }

  public nextYear(): void {
    this.year = this._yearSelected$.getValue() + 1;
  }

  public previousYear(): void {
    this.year = this._yearSelected$.getValue() - 1;
  }

  public printPDF(): void {
    this._fetchingService.fetching = true;

    combineLatest([
      this.year$,
      this.weekAndMonth$.pipe(
        map((weekAndMonth: WeekAndMonth[]) => getWeeksByMonth(weekAndMonth))
      ),
      this.agents$,
      this.astreintesByAgent$,
      this.weekNumbers$,
    ])
      .pipe(take(1))
      .subscribe(
        ([year, weeksByMonth, agents, astreintesbyAgent, weekNumbers]) => {
          const creator: AstreintePlanningPrintCreator =
            new AstreintePlanningPrintCreator(
              year,
              weeksByMonth,
              agents,
              astreintesbyAgent,
              weekNumbers
            );
          creator.printPDF();
          this._fetchingService.fetching = false;
        }
      );
  }
}
