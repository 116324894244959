import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {finalize, map, take} from 'rxjs/operators';
import {Agent} from 'src/app/model/agent.model';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const AGENT_URL = '/api/user';

@Injectable({
  providedIn: 'root',
})
export class AgentService extends AbstractService<Agent> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly httpClient: HttpClient
  ) {
    super(_fetchingService, httpClient, AGENT_URL);
  }

  public getById$(id: number): Observable<Agent | null> {
    this.setFetching(true);
    return this._httpClient.get<Agent | null>(`${AGENT_URL}/${id}`).pipe(
      take(1),
      map((data: Agent | null) => (!!data ? this._convertData(data) : data)),
      finalize(() => this.setFetching(false))
    );
  }

  protected override _convertData({
    id,
    firstName,
    lastName,
    email,
    mail_perso,
    tel_perso,
    unique_identifier,
    is_admin,
    is_planificateur,
    is_agent,
    is_consultant,
    is_constant,
    sommeil,
    company,
    jobs,
    services,
    skills,
  }: Agent): Agent {
    return new Agent(
      id,
      firstName,
      lastName,
      email,
      mail_perso,
      tel_perso,
      unique_identifier,
      is_admin,
      is_planificateur,
      is_agent,
      is_consultant,
      is_constant,
      sommeil,
      company,
      skills,
      jobs,
      services
    );
  }
}
