import {
  addDays,
  addWeeks,
  getDay,
  getISOWeek,
  getISOWeeksInYear,
  getMonth,
  getYear,
  isThursday,
  startOfWeek,
  startOfYear
} from "date-fns";

export function getStartOfWeek(date: Date): Date {
  return startOfWeek(date, {weekStartsOn: 1});
}

/**
 * la première semaine de l’année ISO est celle du 4 janvier (et non celle du 1er janvier),
 * et aussi celle du premier jeudi de janvier.
 * @param year
 */
export function getFirstWeekDate(year: number): Date {
  const debutAnnee = new Date(year, 0, 1);
  let premierJourSemaine = startOfYear(debutAnnee);
  while (!isThursday(premierJourSemaine)) {
    premierJourSemaine = addDays(premierJourSemaine, 1);
  }
  return getStartOfWeek(premierJourSemaine);
}

export function getStartOfWeekDate(week: number,
                                   year: number): Date {
  const debutAnnee = getFirstWeekDate(year)
  const premierJourSemaine = getStartOfWeek(debutAnnee);
  return addWeeks(premierJourSemaine, week - 1);
}

export function getEndOfWeekDate(week: number,
                                 year: number): Date {
  const startOfWeekDate = getStartOfWeekDate(week, year)

  // Ajoute le nombre de semaines à la date de début de l'année
  return addWeeks(startOfWeekDate, 1);
}

export function getWeekNumber(date: Date): number {
  // Assurez-vous que la semaine commence le lundi
  const startOfTheWeek = getStartOfWeek(date);
  // Obtenez le numéro de la semaine
  return getISOWeek(startOfTheWeek);
}

export function formatDateToDisplay(date: string,
                                    delimiter: string = '-'): string {
  const dateSplitted: string[] = date.split(delimiter);
  return dateSplitted
    .reverse()
    .reduce(
      (prev, next, index) =>
        index == 0 ? prev + next : prev + delimiter + next,
      ''
    );
}

export function getDayNumberFromDate(date: Date): number {
  const mondayStart = startOfWeek(date, { weekStartsOn: 1 }); // Set week start to Monday
  const dayNumber = getDay(date);
  return dayNumber === 0 ? 6 : dayNumber - 1;
}

export function formatHoursToDisplay(hours: string,
                                     delimiter: string = ':'): string {
  const hoursSplitted: string[] = hours.split(delimiter);
  if (hoursSplitted.length > 2) {
    return `${hoursSplitted[0]}${delimiter}${hoursSplitted[1]}`;
  }

  return hoursSplitted.reduce(
    (prev, next, index) => (index == 0 ? prev + next : prev + delimiter + next),
    ''
  );
}

export function getWeeksInYear(year: number): number {
  const startingYearDate = new Date(year, 1, 1);
  //Get the number of weeks in an ISO week-numbering year of the given date
  return getISOWeeksInYear(startingYearDate);
}

export function getMonthNameFromWeekNumber(weekNumber: number,
                                           year: number): string[] {
  const startingDate: Date = getStartOfWeekDate(weekNumber, year);
  const endingDate: Date = getEndOfWeekDate(weekNumber, year);

  const startingName: string = startingDate.toLocaleDateString('fr-Fr', {
    month: 'short',
    timeZone: 'America/Cayenne',
  });
  const endingName: string = endingDate.toLocaleDateString('fr-Fr', {
    month: 'short',
    timeZone: 'America/Cayenne',
  });

  if (startingName != endingName) {
    return [startingName, endingName];
  }

  return [startingName];
}

export function currentYear(): number {
  return getYear(new Date())
}

export function currentWeek(): number {
  return getWeekNumber(new Date());
}

export function currentMonth(): number {
  return getMonth(new Date()) + 1;
}

export function verifyAndGetNextWeekWanted(nextWeekNumberWanted: number,
                                           currentYear: number): number {
  if (nextWeekNumberWanted < 1) {
    return getWeeksInYear(currentYear - 1)
  }

  const lastWeek: number = getWeeksInYear(currentYear);

  if (nextWeekNumberWanted > lastWeek) {
    return 1;
  }

  return nextWeekNumberWanted;
}


export function verifyAndGetNextYearWanted(currentWeek: number,
                                           currentYear: number): number {
  if (currentWeek < 1) {
    return currentYear - 1;
  }
  const lastWeek: number = getWeeksInYear(currentYear);
  if (currentWeek > lastWeek) {
    return currentYear + 1;
  }
  return currentYear;

}
