<td class="mat-cell" colspan="100">{{ agent.getName() }}</td>

<ng-container *ngFor="let week of weekNumbers">
  <td
    class="mat-cell"
    [class.selected]="selected.includes(week)"
    [class.is-astreinte]="astreinteByWeek.has(week)"
    colspan="10"
    (click)="handleTdClicked(week)"
  >
    {{ astreinteByWeek.has(week) ? "==" : "" }}
  </td>
</ng-container>
