import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, finalize, take} from 'rxjs/operators';
import {catchErrorOnArray} from 'src/app/common/helper';
import {EventVersion} from 'src/app/model/event-version.model';
import {currentWeek, currentYear} from 'src/app/util/date.util';
import {httpObservableToPromise} from 'src/app/util/promise.util';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from '../admin/fetching.service';

const EVENT_VERSION_URL = '/api/event-version';

@Injectable({
  providedIn: 'root',
})
export class PlanningVersionService extends AbstractService<EventVersion> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, EVENT_VERSION_URL);
  }

  public getAllOnYearAndWeek$(
    year: number = currentYear(),
    week: number = currentWeek()
  ): Observable<EventVersion[]> {
    this.setFetching(true);

    return this._httpClient
      .get<EventVersion[]>(`${EVENT_VERSION_URL}?year=${year}&week=${week}`)
      .pipe(
        catchError(catchErrorOnArray()),
        take(1),
        finalize(() => this.setFetching(false))
      );
  }

  public getAllOnYearAndWeekGlobal$(
    year: number = currentYear(),
    week: number = currentWeek()
  ): void {
    this.getAllOnYearAndWeek$(year, week)
      .pipe(take(1))
      .subscribe((versions: EventVersion[]) =>
        this._globalData$.next(versions)
      );
  }

  public versionate$(year: number,
                     week: number,
                     version: number | null): Promise<EventVersion> {
    this.setFetching(true);

    const result = this._httpClient
      .post<EventVersion>(`${EVENT_VERSION_URL}`, {year, week, version})
      .pipe(
        take(1),
        finalize(() => this.setFetching(false))
      );

    return httpObservableToPromise<EventVersion>(result);
  }

  public versionateGlobal$(year: number,
                           week: number,
                           versionNumber: number | null): Promise<EventVersion> {
    return new Promise<EventVersion>(async (resolve, reject) => {
      try {
        const version: EventVersion = await this.versionate$(year, week, versionNumber);
        this._globalData$
          .pipe(take(1))
          .subscribe((versions: EventVersion[]) => {
            this._globalData$.next([...versions, version]);
            return resolve(version);
          });
      } catch (err: any) {
        return reject(err);
      }
    });
  }

  public publishVersion$(year: number,
                         week: number,
                         versionNumber: number | null): Promise<any> {
    return new Promise<EventVersion>(async (resolve, reject) => {
      try {
        this._httpClient.put<EventVersion>(`${EVENT_VERSION_URL}/${versionNumber}/publish`, {
          year, week
        })
          .pipe(take(1))
          .subscribe((eventVersion: EventVersion) => {
            return resolve(eventVersion);
          });
      } catch (err: any) {
        return reject(err);
      }
    });
  }

  protected _convertData(data: EventVersion): EventVersion {
    return data;
  }
}
