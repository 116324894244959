import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {OperatingLocation} from './operating-location.model';

export const S5_SITE_ID = 3;

export class OperatingSite
  implements NamedModel, IdentifiedModel, ConstantModel
{
  constructor(
    public id: number,
    public name: string,
    public is_constant: boolean,
    public operating_location_id?: number,
    public operating_location?: OperatingLocation
  ) {}

  public getName(): string {
    return this.name;
  }
}
