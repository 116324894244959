import {Injectable} from '@angular/core';
import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Agent} from 'src/app/model/agent.model';
import {currentYear, getMonthNameFromWeekNumber, getWeeksInYear,} from 'src/app/util/date.util';
import {AgentService} from '../admin/agent.service';
import {IndisponibilityService} from "../view/indisponibility.service";
import {ActivityTypeService} from "../admin/activity-type.service";
import {ActivityType, INDISPONIBILITY_ID} from "../../model/activity-type.model";
import {sortAlphabeticallyByName} from "../../util/agent.util";
import {Indisponibility} from "../../model/abscence.model";

export interface WeekAndMonth {
  week: number;
  month: string[];
}

@Injectable({
  providedIn: 'root',
})
export class IndisponibilityFacadeService {
  private readonly _yearSelected$: BehaviorSubject<number> = new BehaviorSubject<number>(currentYear());
  private readonly _selectedRowData$: Subject<{ agent: Agent; weeks: Set<number>, year: number }> = new Subject<{
    agent: Agent;
    weeks: Set<number>,
    year: number
  }>();
  private _isIndisponibilitiesByAgentToBeRefeshed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


  get isIndisponibilitiesByAgentToBeRefeshed$(): Observable<boolean> {
    return this._isIndisponibilitiesByAgentToBeRefeshed$;
  }

  set isIndisponibilitiesByAgentToBeRefeshed(refresh: boolean) {
    this._isIndisponibilitiesByAgentToBeRefeshed$.next(refresh);
  }

  constructor(private readonly _agentService: AgentService,
              private readonly _indisponibilityService: IndisponibilityService,
              private readonly _activityTypeService: ActivityTypeService) {
    this._agentService.getAllWithRelationsGlobal$(['skills']);
    this._activityTypeService.getAllWithRelationsGlobal$(['*']);
    combineLatest([
      this._yearSelected$,
      this.isIndisponibilitiesByAgentToBeRefeshed$
    ]).subscribe(([year]) =>
      this._indisponibilityService.getOnYearGlobal$(year)
    );
  }

  get fetching$(): Observable<boolean> {
    return this._indisponibilityService.getFetching$();
  }


  get agents$(): Observable<Agent[]> {
    return this._agentService.globalData$.pipe(map((agents: Agent[]) => sortAlphabeticallyByName(agents)));
  }

  get weekNumbers$(): Observable<number[]> {
    return this._yearSelected$.pipe(
      map((year: number) =>
        new Array(getWeeksInYear(year)).fill(1).map((d, i) => d + i)
      )
    );
  }

  get weekAndMonth$(): Observable<WeekAndMonth[]> {
    return this._yearSelected$.pipe(
      map((year: number) =>
        new Array(getWeeksInYear(year))
          .fill(1)
          .map((d, i) => d + i)
          .map((weekNumber: number) => {
            return {
              week: weekNumber,
              month: getMonthNameFromWeekNumber(weekNumber, year),
            };
          })
      )
    );
  }

  get year$(): Observable<number> {
    return this._yearSelected$;
  }

  set year(value: number) {
    this._yearSelected$.next(value);
  }


  nextYear(): void {
    this.year = this._yearSelected$.getValue() + 1;
  }

  previousYear(): void {
    this.year = this._yearSelected$.getValue() - 1;
  }

  get activityTypes$(): Observable<ActivityType[]> {
    return this._activityTypeService.globalData$.pipe(
      map(activityTypes => activityTypes.filter(_ => INDISPONIBILITY_ID.has(_.id))),
    );
  }

  set selectedRowData(data: { agent: Agent; weeks: Set<number>, year: number }) {
    this._selectedRowData$.next(data);
  }

  get selectedRowData$(): Observable<{ agent: Agent; weeks: Set<number>, year: number }> {
    return this._selectedRowData$;
  }

  get indisponibilitiesByAgent$(): Observable<Map<number, Indisponibility[]>> {
    return this._indisponibilityService.globalData$
      .pipe(
        map((indisponibilities: Indisponibility[]) =>
          indisponibilities.reduce((map, next: Indisponibility) => {
            if (!map.has(next.agent_id)) {
              map.set(next.agent_id, []);
            }
            map.get(next.agent_id)!.push(next);
            return map;
          }, new Map<number, Indisponibility[]>())
        )
      );
  }

  deleteMultiple(indisponibilities: Partial<Indisponibility>[]): Observable<any> {
    return this._indisponibilityService.deleteMultiple$(indisponibilities);
  }
}
