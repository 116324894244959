import {ConstantModel, IdentifiedModel, NamedModel} from './base-model.model';
import {Company} from './company.model';
import {Job} from './job.model';
import {Service} from './service.model';
import {Skill} from './skill.model';

export class Agent implements IdentifiedModel, NamedModel, ConstantModel {
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public email: string,
    public mail_perso: string,
    public tel_perso: string,
    public unique_identifier: string,
    public is_admin: boolean,
    public is_planificateur: boolean,
    public is_agent: boolean,
    public is_consultant: boolean,
    public is_constant: boolean,
    public sommeil: boolean,
    public company?: Company,
    public skills?: Skill[],
    public jobs?: Job[],
    public services?: Service[]
  ) {
    if (!!company) {
      this.company = new Company(
        company.id,
        company.name,
        company.is_constant,
        company.services,
        company.users
      );
    }

    if (!!skills) {
      this.skills = skills!.map(
        ({ id, name, is_constant }) => new Skill(id, name, is_constant)
      );
    }

    if (!!jobs) {
      this.jobs = jobs!.map(
        ({
          id,
          name,
          code,
          activity_type_id,
          is_constant,
          activity_type,
          column_order,
        }) =>
          new Job(
            id,
            name,
            code,
            activity_type_id,
            column_order,
            is_constant,
            activity_type
          )
      );
    }

    if (!!services) {
      this.services = services!.map(
        ({ id, name, is_constant, company_id, company, users }) =>
          new Service(id, name, is_constant, company_id, company, users)
      );
    }
  }

  getName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  public isEqual({ id }: Agent): boolean {
    return this.id == id;
  }
}
