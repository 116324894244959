import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {Day} from 'src/app/model/day.model';
import {getDayNumberFromDate} from 'src/app/util/date.util';
import {AbstractService} from '../abstractservice.service';
import {FetchingService} from './fetching.service';

const DAY_URL: string = '/api/day';

@Injectable({
  providedIn: 'root',
})
export class DayService extends AbstractService<Day> {
  constructor(
    @Inject(FetchingService) _fetchingService: FetchingService,
    protected readonly _httpClient: HttpClient
  ) {
    super(_fetchingService, _httpClient, DAY_URL);
  }

  public getDayForGivenDate$(dateString: string): Observable<Day> {
    const date = new Date(`${dateString}T00:00:00`);
    const weekDay: number = getDayNumberFromDate(date);

    return this.globalData$.pipe(
      take(1),
      map((days: Day[]) => days.filter(({day}) => day == weekDay)[0])
    );
  }

  protected _convertData({id, day, code, is_constant}: Day): Day {
    return new Day(id, day, code, is_constant);
  }
}
