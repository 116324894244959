<div
  style="visibility: hidden; position: fixed"
  [style.left]="contextMenuPosition?.x"
  [style.top]="contextMenuPosition?.y"
  [matMenuTriggerFor]="contextMenu"
></div>

<mat-menu #contextMenu="matMenu">
  <ng-template
    matMenuContent
    let-date="date"
    let-site="site"
    let-day_period="day_period"
  >
    <div class="menu-title">Fonctions</div>
    <ng-container *ngFor="let job of agent.jobs!">
      <button
        mat-menu-item
        (click)="handleClickOnJob(job, date, site, day_period)"
      >
        {{ job.getName() }}
      </button>
    </ng-container>
  </ng-template>
</mat-menu>

<app-activity-context-menu [contextMenuPosition]="contextMenuPosition"
                           [workSchedules]="workSchedules"
                           [sitesStatusPlanningByOperatingSite]="sitesStatusPlanningByOperatingSite"
                           [sitesStatus]="sitesStatus"
                           [activityTypes]="activityTypes"
                           [length]="length"
                           [job]="job"
                           (singleTDClicked$)="handleSingleTDClicked($event)"
                           (modifyEvent$)="handleActionOnEvent($event)"
                           (addLine$)="handleAddLine($event)">

</app-activity-context-menu>
