import { Component, OnInit, ViewChild } from '@angular/core';
import { EmailService } from '../../../service/admin/email.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Email } from 'src/app/model/email.model';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
// import { EmailCreationDialogComponent } from 'src/app/dialog/email-creation-dialog/email-creation-dialog.component';
import { RessourceDeletionDialogComponent } from 'src/app/dialog/ressource-deletion-dialog/ressource-deletion-dialog.component';
import { SNACKBAR_DEFAULT_DURATION } from 'src/constant/number.constants';

@Component({
  selector: 'app-emailtemplate',
  templateUrl: './emailtemplate.component.html',
  styleUrls: ['./emailtemplate.component.scss']
})
export class EmailtemplateComponent implements OnInit {

  emails: Email[] = [];
  filteredEmails: MatTableDataSource<Email> = new MatTableDataSource();
  displayedColumns: string[] = ['email', 'description', 'subject', 'to', 'send_to_superadmin', 'phone_verification','status', 'actions'];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private emailService: EmailService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.emailService.getEmails().subscribe(
      (data) => {
        this.emails = data;
        this.filteredEmails = new MatTableDataSource(this.emails);
        this.filteredEmails.paginator = this.paginator;
      },
      (error) => {
        console.error('Erreur lors de la récupération des emails', error);
      }
    );
  }

  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value.toLowerCase();
    this.filteredEmails.filter = filterValue.trim().toLowerCase();
  }



  handleDelete(email: Email): void {
    const dialogRef = this.dialog.open(RessourceDeletionDialogComponent, {
      data: {
        title: 'Voulez-vous supprimer cet email ?',
        description: 'Cette action est irréversible.',
        validButton: 'Supprimer',
      },
    });
  
    dialogRef.afterClosed().subscribe(async (doDeletion: boolean) => {
      if (doDeletion) {
        try {
          await this.emailService.deleteEmail(email).toPromise();
          this.snackBar.open(`L'email ${email.email} a été supprimé avec succès !`, 'Fermer', {
            duration: SNACKBAR_DEFAULT_DURATION,
          });
          this.ngOnInit(); // Recharger les emails pour refléter les modifications
        } catch (error) {
          console.error('Erreur lors de la suppression de l\'email', error);
          this.snackBar.open('Erreur lors de la suppression de l\'email.', 'Fermer', {
            duration: SNACKBAR_DEFAULT_DURATION,
          });
        }
      }
    });
  }
  
}
