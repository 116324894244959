<div fxFlex="100%" fxLayout="column" fxLayoutAlign="center center">
  <mat-card>
    <mat-card-title>Changer votre mot de passe</mat-card-title>
    <mat-card-content>
      <form
        [formGroup]="passwordForm"
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <mat-form-field>
          <mat-label>Nouveau mot de passe</mat-label>
          <input
            matInput
            [type]="hidePassword ? 'password' : 'text'"
            formControlName="password"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hidePassword = !hidePassword"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePassword"
          >
            <mat-icon>{{
              hidePassword ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="passwordForm.get('password')?.hasError('password')"
            >{{ passwordForm.get("password")?.getError("password") }}</mat-error
          >
        </mat-form-field>

        <mat-form-field>
          <mat-label>Confirmez le mot de passe</mat-label>
          <input
            matInput
            [type]="hidePasswordConfirmation ? 'password' : 'text'"
            formControlName="password_confirmation"
          />
          <button
            mat-icon-button
            matSuffix
            (click)="hidePasswordConfirmation = !hidePasswordConfirmation"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="hidePasswordConfirmation"
          >
            <mat-icon>{{
              hidePasswordConfirmation ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
          <mat-error
            *ngIf="
              passwordForm
                .get('password_confirmation')
                ?.hasError('password_confirmation')
            "
            >{{
              passwordForm
                .get("password_confirmation")
                ?.getError("password_confirmation")
            }}</mat-error
          >
        </mat-form-field>
      </form>
    </mat-card-content>
    <mat-card-actions fxLayout="row" fxLayoutAlign="center center">
      <button
        mat-stroked-button
        color="primary"
        [disabled]="!passwordForm.valid || fetching"
        (click)="changePassword()"
      >
        Changer
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar
        mode="indeterminate"
        *ngIf="fetching"
      ></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
