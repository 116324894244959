<mat-card>
  <mat-card-title class="administration-title">
    <mat-icon class="header-icon">settings_input_composite</mat-icon>
    <span>États sites</span>
  </mat-card-title>
  <div class="horizontal-delimiter"></div>
  <mat-card-content>
    <table *ngIf="!working && (valid$ | async)"
           class="mat-elevation-z8 mat-table">
      <thead>
      <tr class="site-tr mat-header-row">
        <th colspan="80"
            class="mat-header-cell">{{ year }}
        </th>
        <th colspan="140"
            *ngFor="let month of monthByWeekNumber | keyvalue:asIsOrder"
            class="mat-header-cell">
          {{ month.value ?? "" }}
        </th>
      </tr>

      <tr class="site-tr mat-header-row">
        <th colspan="80"
            class="mat-header-cell">
          <button mat-button (click)="handlePreviousWeek()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button mat-button (click)="handleNextWeek()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </th>
        <th colspan="140"
            *ngFor="let date of monthByWeekNumber | keyvalue:asIsOrder"
            class="mat-header-cell">
          Semaine {{ date.key }}
        </th>
      </tr>

      <tr class="site-tr mat-header-row">
        <th colspan="80"
            class="mat-header-cell"></th>
        <ng-container *ngFor="let dateByWeek of datesByWeekNumber | keyvalue:asIsOrder">
          <th colspan="20"
              *ngFor="let day of dateByWeek.value"
              class="mat-header-cell">
            {{ day.day?.getName() }}
          </th>
        </ng-container>
      </tr>

      <tr class="site-tr mat-header-row">
        <th colspan="80"
            class="mat-header-cell"></th>
        <ng-container *ngFor="let dateByWeek of datesByWeekNumber | keyvalue: asIsOrder">
          <th colspan="20"
              *ngFor="let day of dateByWeek.value"
              class="mat-header-cell">
            {{ day.dateFormated | planningDateDisplay }}
          </th>
        </ng-container>
      </tr>
      </thead>

      <ng-container *ngFor="let site of operatingSites$ | async; let i = index">
        <tbody app-tbody-site
               [ngClass]="{ 'first-tbody': i == 0 }"
               [operatingSite]="site"
               [siteStatuses]="(statuses$ | async)!"
               [days]="customDates"
        ></tbody>
      </ng-container>
    </table>
  </mat-card-content>
</mat-card>
