import {Component, EventEmitter, Input, OnDestroy, OnInit, Output,} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {ErrorStateMatcher} from '@angular/material/core';
import {Subscription} from 'rxjs';
import {CustomErrorStateMatcher} from 'src/app/custom/other/custom-error-state-matcher.matcher';
import {INPUT_ERROR_KEY} from 'src/constant/string.constant';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
})
export class CustomInputComponent implements OnInit, OnDestroy {
  private _subscription: Subscription | null = null;

  control: FormControl = new FormControl();

  @Input()
  public set required(isRequired: boolean) {
    if (isRequired) {
      this.control.addValidators(Validators.required);
    }
  }

  @Input()
  public set disabled(isDisabled: boolean) {
    if (isDisabled) {
      this.control.disable();
    }
  }

  @Input()
  public set error(value: string | null) {
    if (!!value) {
      this.control.setErrors({ [INPUT_ERROR_KEY]: value });
    }
  }

  @Input()
  public set fetching(isFetching: boolean) {
    if (isFetching) {
      this.control.disable();
    } else {
      this.control.enable();
    }
  }

  @Input()
  public set initialValue(data: any) {
    if (this.control.value != data) {
      this.control.setValue(data);
    }
  }

  public get isError(): boolean {
    return this.control.hasError(INPUT_ERROR_KEY);
  }

  public get error(): string | null {
    return this.control.getError(INPUT_ERROR_KEY);
  }

  @Input()
  label: string = '';
  @Input()
  type: string = 'text';

  @Output()
  value$: EventEmitter<string> = new EventEmitter<string>();

  matcher: ErrorStateMatcher = new CustomErrorStateMatcher();

  constructor() {}

  ngOnInit(): void {
    this._subscription = this.control.valueChanges.subscribe((value) => {
      this.value$.emit(value);
    });
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }
}
