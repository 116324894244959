import {Pipe, PipeTransform} from '@angular/core';
import {Event} from 'src/app/model/event.model';
import {Job} from 'src/app/model/job.model';
import {OperatingSite} from 'src/app/model/operating-site.model';
import {INDISPONIBILITY_ID} from "../../model/activity-type.model";

@Pipe({
  name: 'eventValid',
})
export class EventValidPipe implements PipeTransform {
  transform(event: Event, site: OperatingSite, job: Job | null): boolean {
    if (INDISPONIBILITY_ID.has(event.activity_type.id)) {
      return true;
    } else if (!job) {
      return event.operating_site.id == site.id;
    }
    return event.job.id == job!.id && event.operating_site.id == site.id;
  }
}
